import React from 'react'

import { P } from '../../Text'
import { SortIcon } from '../../Icons'
import { SortEnum } from '../../../types/common'
import { ColumnItem, SortStatus } from '../types'
import { sortIconStyle, TableHeaderItem } from './styles'

type TableHeaderCellProps = {
  column: ColumnItem
  defaultWidth: string
  handleSortData: (name: string) => void
  sortStatus: SortStatus | null
}

const TableHeaderCell = ({column, defaultWidth, handleSortData, sortStatus}: TableHeaderCellProps) => {
  const isCurrentSortColumn = sortStatus?.name === column.name
  
  return (
    <TableHeaderItem
    width={column.width ?? defaultWidth}
    align={column.align}
  >
    <P
      bold
      onClick={column.isSortable ? () => {
        handleSortData(column.name)
      }: undefined}
    >
      {column.label}
    </P>
    {column.isSortable &&
      (isCurrentSortColumn &&
      sortStatus.order === SortEnum.ASC ? (
        <SortIcon
          style={sortIconStyle}
          handleClick={() => {
            handleSortData(column.name)
          }}
        />
      ) : isCurrentSortColumn &&
        sortStatus.order === SortEnum.DESC ? (
        <SortIcon
          style={{ ...sortIconStyle, transform: 'rotateX(180deg)' }}
          handleClick={() => {
            handleSortData(column.name)
          }}
        />
      ) : null)}
  </TableHeaderItem>
  )
}

export default TableHeaderCell