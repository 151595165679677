type PlatformOptions = {
  [key: string]: {
    icon: React.JSX.Element
    label: string
  }
}

type FormatOptionProps = {
  platformOptionsWithIcon: PlatformOptions
  options: string[]
}

export const formatOptionsToObject = ({
  platformOptionsWithIcon,
  options,
}: FormatOptionProps): { icon: React.JSX.Element | null; label: string }[] => {
  return options.map((option) => {
    return platformOptionsWithIcon[option] || { icon: null, label: option }
  })
}
