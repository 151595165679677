import styled from "@emotion/styled"

import { Colors, Margin, Padding } from "../../../../../constants"

export const StyledWrapper = styled.div<{ isOpen: boolean }>`
  display: ${(props) => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  border: solid 1px ${Colors.darkGrey};
  border-radius: 4px;
  width: 240px;
  gap: ${Margin.m2};
  padding: ${Padding.p3};
  transform: translateX(calc(100% + ${Margin.m3}));
  background-color: ${Colors.white};
`
