import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { CampaignsReportingOutputType, ReportingApiSectionEnum } from "../../types"
import { checkIfCampaignsReporting } from "../../types/checkers"

type GetBalanceSheetCampaignReportingInput = {
  balanceSheetId: string
}

export const getBalanceSheetCampaignsReporting = async ({
  balanceSheetId
}: GetBalanceSheetCampaignReportingInput): Promise<CampaignsReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/${ReportingApiSectionEnum.campaigns}`
    })

    if (
      checkIsAxiosGetOutputSuccess(response) &&
      checkIfCampaignsReporting(response.data)
    ) {
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}
