import styled from '@emotion/styled'

import Colors from '../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../constants/fontFamily'
import FontSize from '../../constants/fontSize'
import Margin from '../../constants/margin'
import Padding from '../../constants/padding'
import { HTMLInputTypeAttribute } from 'react'

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  ${(props: { width?: string }) =>
    props.width ? `width: ${props.width}` : ''};
`

export const LabelText = styled.span`
  font-weight: 500;
  color: ${(props: { colorLabel?: string }) =>
    props.colorLabel ? props.colorLabel : Colors.darkBlue};
`

export const StyledInput = styled.input<{
  width?: string
  error?: string
  type?: HTMLInputTypeAttribute
}>`
  width: ${(props: { width?: string }) => (props.width ? props.width : '100%')};
  height: 38.5px;
  padding: 12px 16px;
  border: 2px solid ${Colors.greyBorder};
  border-radius: 4px;
  color: ${Colors.darkBlue};
  font-family: ${YETIC_FONT_FAMILY};
  font-size: ${FontSize.base};
  background-color: ${Colors.lightGrey};
  font-weight: 500;

  ::placeholder {
    color: ${Colors.darkBlue};
    opacity: 0.3;
  }

  ${(props) =>
    props.error
      ? 'border: 1px solid red;'
      : `
          border: 2px solid ${Colors.greyBorder};
          transition: border-color 0.3s;
          :hover {
            border-color: ${Colors.greyHoverBorder};
          }
          :focus {
            border: 2px solid ${Colors.greyHoverBorder};
          }
        `
    }

  ${(props: { type?: HTMLInputTypeAttribute }) =>
    props.type === 'date'
      ? 'padding-right: 8px;'
      : ''}
`

export const DivLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Margin.m3};
`

export const Textarea = styled.textarea<{
  withBorder?: boolean
  width?: string
  error?: string
}>`
  width: ${(props: { width?: string }) => (props.width ? props.width : '100%')};
  padding: ${Padding.p5};

  height: 100px;

  border: none;
  border-radius: 5px;

  color: ${Colors.darkBlue};
  font-family: ${YETIC_FONT_FAMILY};
  font-size: ${FontSize.sm};
  background-color: ${Colors.white};
  font-weight: 500;

  ::placeholder {
    color: ${Colors.darkBlue};
    opacity: 0.3;
  }
  ::focus-visible {
    border: 1px ${Colors.grey};
  }
  resize: none;

  ${(props: { withBorder?: boolean; error?: string }) =>
    props.error
      ? 'border: 1px solid red;'
      : props.withBorder
        ? 'border: 1px solid #C4C9D2;'
        : ''})
`

export const InputError = styled.div`
  color: ${Colors.red};
  font-size: ${FontSize.sm};
  margin: ${Margin.m1} 0;
  margin-left: ${Margin.m3};
`

export const PasswordWrapper = styled.div`
  position: relative;
`

export const PasswordEyeWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 9px;
  cursor: pointer;
`