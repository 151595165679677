import { CSSProperties } from "react"
import { TagVariant } from "../../../../components/Tag"
import { CampaignStatus } from "../../../../types/status"

export const getTagVariantFromStatus = (status: CampaignStatus): TagVariant => {
  switch (status) {
    case CampaignStatus.DRAFT:
      return 'orange'
    case CampaignStatus.READY:
      return 'green'
  }
}

export const tableNameStyle: CSSProperties = {
  maxWidth: '90%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export const tableEditIconStyle: CSSProperties = {
  cursor: 'pointer',
  overflow: 'visible'
}

export const trashIconStyle: CSSProperties = {
  cursor: 'pointer'
}