import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { checkIfErrorType } from '../../types/error'
import { getCampaignsApi } from '../../services/api/campaigns/get/get'
import { mapGetBalanceSheetsApiOutputToReportingBalanceSheets, mapGetCampaignsApiOutputToReportingCampaigns } from './helpers/apiMapping'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import PageTemplate from '../../templates/Page'
import { ReportingBalanceSheetType, ReportingCampaignType } from './types'
import { getBalanceSheetsApi } from '../../services/api/balanceSheets/get/getList'
import PageTabs from './PageTabs'
import { ReportingPageCampaignWithSearchParam } from './hoc/ReportingPageWithSearchParam/campaign'
import { ReportingPageBalanceSheetWithSearchParam } from './hoc/ReportingPageWithSearchParam/balanceSheet'
import { BalanceSheetApiStatusEnum } from '../../constants/api'
import { CampaignStatus } from '../../types/status'

const Reporting = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const [balanceSheets, setBalanceSheets] = useState<ReportingBalanceSheetType[] | null>(null)
  const [campaigns, setCampaigns] = useState<ReportingCampaignType[] | null>(null)
  const [fetchError, setFetchError] = useState<string | null>(null)

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const campaignIdInParams = searchParams.get('campaign-id')
    const balanceSheetIdInParams = searchParams.get('balance-sheet-id')

    if (!campaignIdInParams && !balanceSheetIdInParams) {
      setSearchParams({ 'balance-sheet-id': 'null' })
    } else if (campaignIdInParams) {
      setTabIndex(1)
    } else {
      setTabIndex(0)
    } 
  }, [searchParams])

  useEffect(() => {
    if (tabIndex === 0 && balanceSheets === null) {
      getBalanceSheets()
    } else if (tabIndex === 1 && campaigns === null) {
      getCampaigns()
    }
  }, [tabIndex])

  const getBalanceSheets = async () => {
    const retrievedBalanceSheets = await getBalanceSheetsApi({})

    if (checkIfErrorType(retrievedBalanceSheets)) {
      setFetchError('Une erreur est survenue lors du chargement des bilans')
      return
    }

    const formattedBalanceSheets = mapGetBalanceSheetsApiOutputToReportingBalanceSheets(retrievedBalanceSheets)
    const filteredBalanceSheets = formattedBalanceSheets.filter((balanceSheet) => balanceSheet.status === BalanceSheetApiStatusEnum.READY)

    setBalanceSheets(filteredBalanceSheets)
  }

  const getCampaigns = async () => {
    const retrievedCampaigns = await getCampaignsApi({ startDate: null, endDate: null })

    if (checkIfErrorType(retrievedCampaigns)) {
      setFetchError('Une erreur est survenue lors du chargement des campagnes')
      return
    }

    const formattedCampaigns = mapGetCampaignsApiOutputToReportingCampaigns(retrievedCampaigns)
    const filteredCampaigns = formattedCampaigns.filter((campaign) => campaign.status === CampaignStatus.READY)

    setCampaigns(filteredCampaigns)
  }
  
  const balanceSheetIdInParams = searchParams.get('balance-sheet-id')
  let selectedBalanceSheetId: string | undefined = undefined
  
  if (balanceSheetIdInParams && balanceSheets) {
    const selectedBalanceSheet = balanceSheets?.find((balanceSheet) => balanceSheet.id === balanceSheetIdInParams) || null
    selectedBalanceSheetId = selectedBalanceSheet?.id
  }

  const handleTabChange = (index: number) => {
    if (index === 0) {
      setSearchParams({ 'balance-sheet-id': 'null' })
    } else {
      setSearchParams({ 'campaign-id': 'null' })
    }
  }

  if (fetchError) {
    return <ErrorPage message={fetchError} />
  }

  if ((tabIndex === 0 && balanceSheets == null) || (tabIndex === 1 && campaigns == null)) {
    return <LoadingPage />
  }

  return (
    <PageTemplate title='Reporting'>
      <PageTabs
        items={["Par bilan", "Par campagne"]}
        activeTabIndex={tabIndex}
        handleTabClick={handleTabChange}
      />
      {tabIndex === 0 && <ReportingPageBalanceSheetWithSearchParam balanceSheets={balanceSheets || []} setFetchError={setFetchError} />}
      {tabIndex === 1 && <ReportingPageCampaignWithSearchParam campaigns={campaigns || []} setFetchError={setFetchError} />}
    </PageTemplate>
  )
}

export default Reporting