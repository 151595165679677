import React, { CSSProperties, ReactNode } from 'react'

import { StyledH2 } from './style'

type H2Props = {
  children: ReactNode
  style?: CSSProperties
}

const H2 = ({ children, style }: H2Props) => {
  return <StyledH2 style={style}>{children}</StyledH2>
}

export default H2
