enum FontSize {
  '2xs' = '10px',
  xs = '12px',
  sm = '14px',
  base = '16px',
  lg = '18px',
  xl = '24px',
  '2xl' = '30px'
}

export default FontSize
