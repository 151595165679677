import { createBalanceSheetApi } from "../../../services/api/balanceSheets/create"
import { checkIfErrorType } from "../../../types/error"
import { BalanceSheetCreationFormData, convertPlatformsToApiFormat } from "../types"
import { getFormErrors } from "./creationForm"

type HandleSubmissionInput = {
  setCreationApiError: (error: string) => void
  creationForm: BalanceSheetCreationFormData
  setHasCreationBeenSubmitted: (hasBeenSubmitted: boolean) => void
  setIsCreationModalOpen: (isOpen: boolean) => void
  apiRetrieveBalanceSheetsCount: number
  setApiRetrieveBalanceSheetsCount: (count: number) => void
  clearCreationForm: () => void
}

export const handleSubmit = async ({
  setCreationApiError,
  creationForm,
  setHasCreationBeenSubmitted,
  setIsCreationModalOpen,
  apiRetrieveBalanceSheetsCount,
  setApiRetrieveBalanceSheetsCount,
  clearCreationForm
}: HandleSubmissionInput) => {
  const creationFormErrors = getFormErrors(creationForm)

  setCreationApiError('')
  if (Object.values(creationFormErrors).some((error) => error)) {
    setHasCreationBeenSubmitted(true)
    return
  }

  const formattedFormData = {
    ...creationForm,
    platforms: convertPlatformsToApiFormat(creationForm.platforms)
  }

  const createBalanceSheetResult = await createBalanceSheetApi(formattedFormData)

  if (checkIfErrorType(createBalanceSheetResult)) {
    setCreationApiError(createBalanceSheetResult.error)
    return
  }

  clearCreationForm()
  setIsCreationModalOpen(false)
  setApiRetrieveBalanceSheetsCount(apiRetrieveBalanceSheetsCount + 1)
}