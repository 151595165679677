import styled from "@emotion/styled"
import { CSSProperties } from "react"

import Colors from "../../constants/colors"
import { FontSize, Margin } from "../../constants"

export const selectedDropdownColor = '#0070f3'

export const StyledDropdown = styled.div<{ marginBottom: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 360px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

export const StyledLabel = styled.label`
  margin-bottom: 8px;
  color: ${Colors.darkBlue};
`

export const StyledDropdownSelect = styled.div<{ error?: boolean }>`
  padding: 8px 16px;
  padding-right: 44px;
  border-radius: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  position: relative;

  ${(props) => props.error ? `
    border: 1px solid ${Colors.red};
    ` : `
    border: 2px solid ${Colors.greyBorder};
    transition: border-color 0.3s;

    :hover {
      border-color: ${Colors.greyHoverBorder};
    }
  `}

  background-color: ${Colors.lightGrey};
  color: ${Colors.darkBlue};
  width: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 70px;
`

export const arrowSvgStyle: CSSProperties = {
  position: 'absolute',
  right: '12px',
  top: '5px'
}

export const StyledDropdownOptions = styled.div<{ position?: 'top' | 'bottom' }>`
  padding: 8px 0;
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  top: ${({ position }) => position !== 'top' && '100%'};
  bottom: ${({ position }) => position === 'top' && 'calc(100% - 24px)'};
  left: 0;
  z-index: 1;
  min-width: 232px;
  max-height: 224px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${Colors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: #d6d6d6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.darkGrey};
  }
`

export const StyledDropdownOptionItem = styled.div<{ selected: boolean, disabled?: boolean }>`
  padding: 8px 16px;
  padding-right: 56px;
  transition: background-color 0.2s;
  display: flex;
  justify-content: space-between;
  position: relative;
  color: ${Colors.darkBlue};

  ${({ selected, disabled }) => (
    disabled ? 
      `
        color: ${Colors.darkBlueDisabled};
        pointer-events: none;
      ` : selected ?
      `
        color: ${selectedDropdownColor};
      ` :
      `
        cursor: pointer;
  
        :hover {
          background-color: ${Colors.lightGrey};
        }
      `
  )}
`

export const StyledMultiDropdownOptionItem = styled(StyledDropdownOptionItem)`
  cursor: pointer;

  :hover {
    background-color: ${Colors.lightGrey};
  }
`

export const checkSvgStyle: CSSProperties = {
  right: '16px',
  top: '5px',
  position: 'absolute'
}

export const DropdownError = styled.div`
  color: ${Colors.red};
  font-size: ${FontSize.sm};
  margin: ${Margin.m1} 0;
  margin-left: ${Margin.m3};
  position: absolute;
  bottom: -16px;
`