import React, { CSSProperties, ReactNode } from 'react'

import { StyledP } from './style'

type PProps = {
  children: ReactNode
  style?: CSSProperties
  big?: boolean
  small?: boolean
  bold?: boolean
  title?: string
  onClick?: () => void
}

const P = ({ children, style, big, small, bold, title, onClick }: PProps) => {
  return (
    <StyledP style={style} big={big} small={small} bold={bold} title={title} onClick={onClick}>
      {children}
    </StyledP>
  )
}

export default P
