import styled from '@emotion/styled'

import Colors from '../../../constants/colors'

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
`

export const CheckboxInput = styled.input`
  appearance: none;
  min-width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-right: 8px;
  border: 1px solid ${Colors.darkBlue};
  outline: none;
  cursor: pointer;
  transition: border-color 0.2s;

  &:checked {
    background-color: ${Colors.darkBlue};
  }
`