import React, { useEffect, useState } from 'react'

import Table from '../../../../components/Table'
import MultiSelectDropdown from '../../../../components/Dropdown/MultiSelect'
import { ColumnItem, ElementItem } from '../../../../components/Table/types'
import { TrashIcon } from '../../../../components/Icons'
import { P } from '../../../../components/Text'
import Button from '../../../../components/Button'
import { CampaignsPerBalanceSheet, GetCampaignsApiOutput } from '../../../../services/api/campaigns/get/types'
import { getCampaignsByCompanyIdApi } from '../../../../services/api/campaigns/get/get'
import { getOneBalanceSheetApi } from '../../../../services/api/balanceSheets/get/getOne'
import { checkIfDatesInRange } from '../../../../helpers/date'
import { checkIfErrorType } from '../../../../types/error'
import { AlignEnum } from '../../../../types/common'
import { Margin } from '../../../../constants'
import { ButtonContainer, multiSelectStyle, paragraphStyle, trashIconStyle } from './styles'

type CampaignLinkProps = {
  companyId: string | null
  balanceSheetCampaigns: CampaignsPerBalanceSheet | null
  balanceSheetId: string | null
  handleLinkCampaignToBalanceSheet: (campaignsToLink: GetCampaignsApiOutput) => void
  handleCancel: () => void
}

const tableColumns: ColumnItem[] = [
  {
    name: 'name',
    label: 'Nom',
    align: AlignEnum.LEFT,
    width: '80%',
    isSortable: true
  },
  {
    name: 'delete',
    label: '',
    align: AlignEnum.RIGHT
  }
]

const CampaignLink = ({companyId, balanceSheetCampaigns, handleLinkCampaignToBalanceSheet, balanceSheetId, handleCancel}: CampaignLinkProps) => {
  const [campaigns, setCampaigns] = useState<GetCampaignsApiOutput>([])
  const [campaignsToLink, setCampaignsToLink] = useState<GetCampaignsApiOutput>([])
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([])
  const [disabledOptions, setDisabledOptions] = useState<string[]>([])

  useEffect(() => {
    ;(async () => {
      if (balanceSheetId && companyId) {
        const balanceSheet = await getOneBalanceSheetApi({ balanceSheetId })
        if (checkIfErrorType(balanceSheet)) return
  
        const balanceSheetDates = {
          baseStartDate: balanceSheet.startDate,
          baseEndDate: balanceSheet.endDate,
        }
  
        const campaignsFromApi = await getCampaignsByCompanyIdApi(companyId)
        if (checkIfErrorType(campaignsFromApi)) return
  
        const balanceSheetCampaignNames = new Set(
          balanceSheetCampaigns?.map(campaign => campaign.name)
        )
  
        const filteredCampaigns = campaignsFromApi.filter(
          campaign => !balanceSheetCampaignNames.has(campaign.name)
        )
  
        const disabledCampaigns = filteredCampaigns
          .filter(campaign => {
            const campaignDates = {
              startDateToCheck: campaign.startDate,
              endDateToCheck: campaign.endDate,
            }

            return !checkIfDatesInRange({
              rangeToCheck: campaignDates,
              baseRange: balanceSheetDates
            })
          })
          .map(campaign => campaign.name)
  
        setCampaigns(filteredCampaigns)
        setDisabledOptions(disabledCampaigns)
      }
    })()
  }, [balanceSheetId, companyId, balanceSheetCampaigns])
  

  const handleCampaignChange = (selectedValues: string[]) => {
    let campaignsToAdd: GetCampaignsApiOutput = []

    selectedValues.forEach(value => {
      const campaign = campaigns?.find(campaign => campaign.name === value)
      const alreadyAdded = campaignsToAdd.find(addedCampaign => addedCampaign.name === campaign?.name)

      if(campaign && !alreadyAdded) {
        campaignsToAdd.push(campaign)
      }
    })

    const newSelectedCampaigns = selectedValues.filter(value => !selectedCampaigns.includes(value))

    setCampaignsToLink([...campaignsToAdd])
    setSelectedCampaigns([...newSelectedCampaigns, ...selectedValues])
  }

  const handleRemoveCampaign = (campaignToDeleteName) => {
    const updatedCampaignsToLink = campaignsToLink.filter(campaign => campaign.name !== campaignToDeleteName)
    const updatedSelectedCampaigns = selectedCampaigns.filter(selected => selected !== campaignToDeleteName)

    setCampaignsToLink([...updatedCampaignsToLink])
    setSelectedCampaigns([...updatedSelectedCampaigns])
  }

  const campaignsName = campaigns.map(campaign => campaign.name)

  const tableElements: ElementItem[] = campaignsToLink.map((campaign) => {
    return {
      name: <P>{campaign.name}</P>,
      delete: <TrashIcon handleClick={()=>handleRemoveCampaign(campaign.name)} style={trashIconStyle} />
    }
  })

  return (
    <>
      <MultiSelectDropdown
        label='Campagnes'
        options={campaignsName}
        selected={selectedCampaigns}
        disabledOptions={disabledOptions}
        onSelectionChange={(value)=>handleCampaignChange(value)}
        marginBottom='0'
        optionsPosition='bottom'
        displayAllSelected={false}
        style={multiSelectStyle}
      />
      <P style={paragraphStyle}>Liste des campagnes à ajouter :</P>
      <Table
        columns={tableColumns}
        elements={tableElements}
      />
      <ButtonContainer>
        {campaignsToLink.length > 0 && (
          <Button
            onClick={() => handleLinkCampaignToBalanceSheet(campaignsToLink)}
            small
            style={{ marginTop: Margin.m7 }}
          >
            Ajouter au bilan
          </Button>
        )}
          <Button
            onClick={handleCancel}
            small
            style={{ marginTop: Margin.m7 }}
            type='secondary'
          >
            Annuler
          </Button>
      </ButtonContainer>
    </>
  )
}

export default CampaignLink