import styled from '@emotion/styled'

import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'

export const CaptionsContainer = styled.div`
  box-sizing: border-box;
  padding: 0 ${Padding.p10};
  margin-top: ${Margin.m8};
`

export const CaptionItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
`

export const CaptionRectangle = styled.div<{ color: string }>`
  width: 20px;
  height: 4px;
  background-color: ${(props) => props.color};
`
