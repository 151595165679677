import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { HighlightsReportingOutputType } from "../../types"
import { checkIfHighlightsReporting } from "../../types/checkers"

type GetBalanceSheetHighlightsReportingInputType = {
  balanceSheetId: string
}

export const getBalanceSheetHighlightsReporting = async ({
  balanceSheetId
}: GetBalanceSheetHighlightsReportingInputType): Promise<HighlightsReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/highlights`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfHighlightsReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}


