import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { ImpactReportingOutputType } from "../../types"
import { checkIfImpactReporting } from "../../types/checkers"

type GetCompanyImpactReportingInputType = {
  startDate?: string
  endDate?: string
}

export const getCompanyImpactReporting = async ({
  startDate,
  endDate
}: GetCompanyImpactReportingInputType): Promise<ImpactReportingOutputType | null> => {
  try {
      const response = await axiosGet({
        url: `${backendURL}/reports/campaigns/all/impact${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfImpactReporting(response.data)) {
        return response.data
      } else {
        return null
      }
    }

    return null
  } catch (_) {
    return null
  }
}
