import React, { useState } from 'react'

import { SortEnum } from '../../types/common'
import { Container, TableContainer, TableHeader, TableRowsContainer, NoResult } from './style'
import Pagination from '../Pagination'
import { ColumnItem, ElementItem, SortStatus } from './types'
import { checkIfFrenchShortDate, compareFrenchShortDates } from '../../helpers/date'
import TableTitle from './Title'
import TableHeaderCell from './HeaderCell'
import TableRow from './Row'

type TableProps = {
  title?: string
  columns: ColumnItem[]
  elements: ElementItem[]
  csvElements?: ElementItem[]
  sort?: SortStatus
  download?: boolean
  pagination?: {
    totalItems: number
    itemsByPage: number
    currentPage: number
    handleChangePage: (page: number) => void
  }
  maxHeight?: number | undefined
}

const Table = ({
  title,
  columns,
  elements,
  csvElements,
  sort,
  pagination,
  download,
  maxHeight
}: TableProps) => {
  const [sortStatus, setSortStatus] = useState<SortStatus | null>(sort ?? null)

  let sortedElements = elements

  if (sortStatus) {
    sortedElements = sortedElements.sort((a, b) => {
      const valueA = a[sortStatus.name]
      const valueB = b[sortStatus.name]

      if (valueA === undefined || valueB === undefined) {
        return 0
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        if (checkIfFrenchShortDate(valueA) && checkIfFrenchShortDate(valueB)) {
          return sortStatus.order === SortEnum.ASC 
            ? compareFrenchShortDates(valueA, valueB)
            : compareFrenchShortDates(valueB, valueA)
        }

        return sortStatus.order === SortEnum.ASC
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA)
      }

      if (valueA < valueB) {
        return sortStatus.order === SortEnum.ASC ? -1 : 1
      }

      if (valueA > valueB) {
        return sortStatus.order === SortEnum.ASC ? 1 : -1
      }

      return 0
    })
  }

  const paginatedSortedData = pagination
    ? sortedElements.slice(
        (pagination.currentPage - 1) * pagination.itemsByPage,
        pagination.currentPage * pagination.itemsByPage
      )
    : sortedElements

  const defaultWidth = `${100 / columns.length}%`

  const handleSortData = (name: string) => {
    const order = sortStatus?.name === name ? sortStatus.order : undefined

    const updatedSortStatus = {
      name,
      order: order === SortEnum.ASC ? SortEnum.DESC : SortEnum.ASC
    }

    setSortStatus(updatedSortStatus)
  }

  return (
    <Container>
      {title && <TableTitle title={title} download={download} elements={csvElements ?? elements} columns={columns}/>}
      <TableContainer>
        <TableHeader>
          {columns.map((column) => {
            return (
              <TableHeaderCell key={column.name} column={column} defaultWidth={defaultWidth} handleSortData={handleSortData} sortStatus={sortStatus}/>
            )
          })}
        </TableHeader>
        <TableRowsContainer maxHeight={maxHeight}>
          {paginatedSortedData.length === 0 ? (
            <NoResult>Aucun résultat</NoResult>
          ) : (
            paginatedSortedData.map((row, rowIndex) => {
              return (
                <TableRow key={rowIndex} rowIndex={rowIndex} row={row} columns={columns} defaultWidth={defaultWidth}/>
              )
            })
          )}
        </TableRowsContainer>
      </TableContainer>
      {pagination && (
        <Pagination
          totalItems={pagination.totalItems}
          itemsPerPage={pagination.itemsByPage}
          onChangePage={pagination.handleChangePage}
        />
      )}
    </Container>
  )
}

export default Table
