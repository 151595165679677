import { CSSProperties } from "react"
import styled from "@emotion/styled"

import { Margin } from "../../../constants"

export const FormHeader = styled.div`
  margin-bottom: ${Margin.m3};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Margin.m5};
`

export const StyledFormRow = styled.div`
  width: 100%;
  display: flex;
  gap: ${Margin.m7};
`

export const LoginRedirectWrapper = styled.div`
  display: flex;
  gap: ${Margin.m3};
  margin-top: ${Margin.m4};
`

export const pErrorStyle: CSSProperties = {
  color: 'red',
  textAlign: 'center',
  marginTop: Margin.m4,
  marginBottom: 0
}