import React, { useState } from 'react'

import Button from '../../../../../components/Button'
import {
  CampaignPlatform,
  GoogleCampaignFormat,
} from '../../../../../types/formatAndPlatform'
import { Margin } from '../../../../../constants'
import { H3 } from '../../../../../components/Text'
import InputFileSection from '../InputFileSection'
import { SubmitFileErrorEnum, submitFileErrorWording } from '../constants/error'
import { updateLogsCampaignApi } from '../../../../../services/api/campaigns/update/googleDisplay/logs'
import { updateAssetsCampaignApi } from '../../../../../services/api/campaigns/update/googleDisplay/assets'
import { checkIfErrorType } from '../../../../../types/error'
import { checkGoogleDisplayLog } from '../../../../../helpers/files/csv/checkGoogleDisplayLog'
import { checkGoogleDisplayAssets } from '../../../../../helpers/files/csv/checkGoogleDisplayAsset'

export interface GoogleDisplayDetailsProps {
  name: string
  campaignId: string
  handleEndProvisioning: () => void
}

const campaignPlatform = CampaignPlatform.GOOGLE
const campaignFormat = GoogleCampaignFormat.DISPLAY

const GoogleDisplayDetails = ({ 
  name,
  campaignId,
  handleEndProvisioning 
}: GoogleDisplayDetailsProps) => {
  const [csvLogFile, setCsvLogFile] = useState<File | null>(null)
  const [csvAssetFile, setCsvAssetFile] = useState<File | null>(null)
  const [fetchLogFileError, setFetchLogFileError] = useState<string | null>(null)
  const [fetchAssetFileError, setFetchAssetFileError] = useState<string | null>(null)

  const areCsvFilesValid = csvLogFile != null && csvAssetFile != null

  const submitLogFile = async (value?: File) => {
    if (value === undefined) {
      setFetchLogFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_NOT_FOUND])
    } else {
      const isFormatValid = await checkGoogleDisplayLog({ csvFile: value })
      if (isFormatValid) {
        setCsvLogFile(value)
        setFetchLogFileError(null)
      } else {
        setFetchLogFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_INVALID])
      }
    }
  }

  const submitAssetFile = async (value?: File) => {
    if (value === undefined) {
      setFetchAssetFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_NOT_FOUND])
    } else {
      const isFormatValid = await checkGoogleDisplayAssets({ csvFile: value })
      if (isFormatValid) {
        setCsvAssetFile(value)
        setFetchAssetFileError(null)
      } else {
        setFetchAssetFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_INVALID])
      }
    }
  }

  const deleteLogFile = () => {
    setCsvLogFile(null)
    setFetchLogFileError(null)
  }

  const deleteAssetFile = () => {
    setCsvAssetFile(null)
    setFetchAssetFileError(null)
  }

  const validateProvisioning = async () => {
    if (csvLogFile == null) {
      alert('Log file not found')
      return
    } else if (csvAssetFile == null) {
      alert('Asset file not found')
      return
    }
    
    const resultUpdateLogs = await updateLogsCampaignApi({ campaignId, platform: campaignPlatform, format: campaignFormat, csvFile: csvLogFile })

    if (checkIfErrorType(resultUpdateLogs)) {
      alert('L upload des logs de la campagne a échoué')
      return
    } 
    const resultUpdateAssets = await updateAssetsCampaignApi({ campaignId, platform: campaignPlatform, format: campaignFormat, csvFile: csvAssetFile })

    if (!checkIfErrorType(resultUpdateAssets)) {
      handleEndProvisioning()
    } else {
      alert('L upload du fichier des assets a échoué')
    }
  }

  return (
    <div>
      <H3>{`Campagne : "${name}"`}</H3>
      <InputFileSection 
        label="Fichier de logs :"
        uniqueKey='input-csv-campaign-logs'
        csvFile={csvLogFile} 
        submitFile={submitLogFile} 
        deleteFile={deleteLogFile} 
        error={fetchLogFileError} 
      />
      <InputFileSection 
        label="Fichier avec les assets :"
        uniqueKey='input-assets'
        csvFile={csvAssetFile} 
        submitFile={submitAssetFile} 
        deleteFile={deleteAssetFile} 
        error={fetchAssetFileError} 
      />
      {areCsvFilesValid && <Button onClick={validateProvisioning} small style={{marginTop: Margin.m7}}>Confirmer le provisioning</Button>}
    </div>
  )
}

export default GoogleDisplayDetails