import React from "react"
import { CSSProperties, useState } from "react"

import { deepCloneWithDate } from "../../../helpers/clone"
import {
  TooltipRenderer,
  TooltipComponentType,
  RangeSelectionIdEnum,
  checkIfRangeSelectionOption,
  LineChartAggregatorEnum,
  RangeSelectorData
} from "./types"
import DefaultLineChartTooltip from "./Tooltips"
import { renderDefaultTooltip } from "./Tooltips/Default/renderer"
import LineChartDrawer, { Point, LineChartData, DatasetsSelection, DropDownData } from "./lineChartDrawer"
import { processData } from "./helpers/processData"

type PropsType<PointType extends Point> = {
  title?: string
  rangeSelectorData: RangeSelectorData<PointType> | null
  data: LineChartData<PointType>
  renderTooltip?: TooltipRenderer<PointType>
  tooltipComponent?: TooltipComponentType<PointType>
  chartId: string
  cardWidth: string
  chartWidth: number
  maxTicks?: number
  style?: CSSProperties
  formatHeaderData?: (
    data: LineChartData<PointType>,
    initialSelectionNames?: string[],
    globalCTR?: number
  ) => DatasetsSelection
  initialSelectionNames?: string[]
  globalCTR?: number
}

const LineChart = <PointType extends Point>({
  title,
  rangeSelectorData,
  data,
  tooltipComponent,
  renderTooltip,
  cardWidth,
  chartWidth,
  style,
  maxTicks = 10,
  formatHeaderData,
  initialSelectionNames,
  globalCTR,
  chartId
}: PropsType<PointType>) => {
  const [selectedRangeId, setSelectedRangeId] =
    useState<RangeSelectionIdEnum | null>(
      rangeSelectorData ? rangeSelectorData.defaultSelectionId : null
    )

  const selectedRangeObject = rangeSelectorData?.selectionOptions.find(
    (el) => el.id === selectedRangeId
  )

  const dropDownValue: string | null = selectedRangeObject
    ? selectedRangeObject.label
    : null

  const dropDownOptions: string[] | null = rangeSelectorData
    ? rangeSelectorData.selectionOptions.map((option) => option.label)
    : null

  const handleDropDownChange = (selectedOption: string | null) => {
    if (checkIfRangeSelectionOption(selectedOption)) {
      setSelectedRangeId(selectedOption. id)
    }
  }

  const dropDownData: DropDownData | null =
    rangeSelectorData && dropDownOptions && dropDownValue
      ? {
          options: dropDownOptions,
          value: dropDownValue,
          onChange: handleDropDownChange
        }
      : null

  const defaultAggregator = LineChartAggregatorEnum.SUM

  let processedData = deepCloneWithDate(data)

  const buildAggregatedPoint = rangeSelectorData?.buildAggregatedPoint ?? null

  processedData = processData(
    processedData,
    selectedRangeId,
    defaultAggregator,
    buildAggregatedPoint
  )

  return (
    <LineChartDrawer
      title={title}
      dropDownData={dropDownData}
      data={processedData}
      cardWidth={cardWidth}
      chartWidth={chartWidth}
      formatHeaderData={formatHeaderData}
      maxTicks={maxTicks}
      style={style}
      initialSelectionNames={initialSelectionNames}
      renderTooltip={renderTooltip ?? renderDefaultTooltip}
      tooltipComponent={tooltipComponent ?? DefaultLineChartTooltip}
      chartId={chartId}
      globalCTR={globalCTR}
    />
  )
}

export default LineChart
