import React, { CSSProperties } from 'react'

import {
  TabText,
  TabsContainer,
  TabsItemWithSeparator,
  TabsSeparator
} from './style'

export type TabsProps = {
  items: string[]
  activeTabIndex: number
  handleTabClick: (index: number) => void
  containerStyle?: CSSProperties
  tabStyle?: CSSProperties
  color?: {
    tab?: string
    text?: string
    activeText?: string
    activeTab?: string
    hoverTab?: string
    hoverText?: string
  }
  hasSeparator?: boolean
}

const Tabs = ({
  items,
  activeTabIndex,
  handleTabClick,
  containerStyle,
  tabStyle,
  color,
  hasSeparator = true
}: TabsProps) => {
  return (
    <TabsContainer style={containerStyle}>
      {items.map((item, index) => (
        <TabsItemWithSeparator
          key={index}
          onClick={() => {
            handleTabClick(index)
          }}
          isActive={activeTabIndex === index}
          style={tabStyle}
          tabColor={color?.tab}
          activeColor={color?.activeTab}
          hoverColor={color?.hoverTab}
          textColor={color?.text}
          activeTextColor={color?.activeText}
          hoverTextColor={color?.hoverText}
        >
          <TabText>{item}</TabText>
          {index < items.length - 1 && hasSeparator && <TabsSeparator />}
        </TabsItemWithSeparator>
      ))}
    </TabsContainer>
  )
}

export default Tabs
