import React from 'react'

import { P } from '../../../components/Text'
import { FontWeight } from '../../../constants'
import { SmallCTA } from '../style'

const ReportingLinecardContent = () => {
  return (
    <P
      style={{
        fontWeight: FontWeight.YETIC_MEDIUM,
      }}
    >
      Rendez-vous sur la page <SmallCTA>Reporting</SmallCTA> pour voir les
      rapports que nous avons générés en fonction de vos bilans.
    </P>
  )
}

export default ReportingLinecardContent
