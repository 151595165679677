import styled from '@emotion/styled'

import { Colors, Margin, Padding } from '../../constants'

export const LogoWrapper = styled.div`
  position: absolute;
  padding: ${Padding.p8} 0 0 ${Padding.p8};
`

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: ${Colors.grey};
  display: flex;
`

export const StyledContainer = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  height: fit-content;
  min-height: 100vh;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`

export const FormWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  padding: ${Padding.p11};
  max-width: 520px;
`

export const FormHeader = styled.div`
  margin-bottom: ${Margin.m3};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Margin.m5};
`
