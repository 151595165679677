import React from 'react'

import Tip from '../Tip'
import Paragraph from '../../../../components/Text/Paragraph'
import { ArrowDropdown } from '../../../../components/Icons'
import { bottomChevronStyle, List, ListItem, StepContainer, StepCount, StepHeader, stepTitleStyle, topChevronStyle } from './style'

type StepProps = {
  count: number
  title: string
  instructions: string[] | (string | React.JSX.Element)[]
  tip: string | null
  handleDisplayStep:() => void
  isStepDisplay: boolean
}

const Step = ({ count, title, instructions, tip, handleDisplayStep, isStepDisplay }: StepProps) => {
  const chevronStyle = isStepDisplay ? topChevronStyle : bottomChevronStyle

  return (
    <StepContainer>
      <StepHeader onClick={handleDisplayStep}>
        <StepCount>{count}</StepCount>
        <Paragraph style={stepTitleStyle} bold>{title}</Paragraph>
        <ArrowDropdown style={chevronStyle}/>
      </StepHeader>
      {isStepDisplay && (
        <>
          <List>
            {instructions.map((instruction: string | React.JSX.Element) => (
              <ListItem key={`${title}-${count}`}>{instruction}</ListItem>
            ))}
          </List>
          {tip && <Tip>{tip}</Tip>}
        </>
      )}
    </StepContainer>
  )
}

export default Step