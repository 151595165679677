import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { checkIfErrorType } from '../../../types/error'
import { updateVerificationEmailStatus } from '../../../services/api/user/updateVerificationEmailStatus'
import RegisterSuccess from '../Success'
import ErrorPage from '../../ErrorPage'

const EmailVerification = () => {
  const [error, setError] = useState<string | null>(null)
  const [isEmailVerify, setIsEmailVerify] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search)
      const token = queryParams.get('token')
  
      if(!token) {
        setError('An error occured while verifying email')
        return
      }
  
      const response = await updateVerificationEmailStatus({token})

      if(checkIfErrorType(response)) {
        setError('Unverified email')
        return
      }
      
      setIsEmailVerify(true)
      setError(null)
    })()
  },[])

  if(isEmailVerify) {
    return <RegisterSuccess handleGoToLogin={() => navigate('/login')} title={'Votre email a été vérifié !'} message={'Vous pouvez maintenant accéder à votre espace.'}/>
  }

  if(error) {
    return <ErrorPage/>
  }
  
  return
}

export default EmailVerification