import { getCompanyReportingBySection } from "../../../services/api/reporting/allCampaigns"
import { getCampaignReportingBySection } from "../../../services/api/reporting/perCampaign"
import { getBalanceSheetReportingBySection } from "../../../services/api/reporting/perBalanceSheet"
import { ReportingApiSectionEnum } from "../../../services/api/reporting/types"
import { checkIfHighlightsReporting } from "../../../services/api/reporting/types/checkers"
import { checkIfErrorType } from "../../../types/error"
import { mapReportingApiTabSection } from "../types"

export interface GetReportingBySectionInput {
  id: string
  section: ReportingApiSectionEnum
  startDate?: string
  endDate?: string
  pageType: PageReportingTypeEnum
}

export interface GetReportingDataForSectionsInput {
  id: string
  sections: SectionsToFetch
  startDate?: string
  endDate?: string
  pageType: PageReportingTypeEnum
}

export enum PageReportingTypeEnum {
  BALANCE_SHEET = 'BALANCE_SHEET',
  CAMPAIGN = 'CAMPAIGN'
}

export type TabSection = ReturnType<typeof mapReportingApiTabSection>

export type SectionsToFetch = {
  highlights: boolean,
  sectionTabName: TabSection
}

export const checkIfSectionIsTabSection = (section: ReportingApiSectionEnum):
  section is ReturnType<typeof mapReportingApiTabSection> => {
  return [
    ReportingApiSectionEnum.impact,
    ReportingApiSectionEnum.global,
    ReportingApiSectionEnum.devices,
    ReportingApiSectionEnum.locations
  ].includes(section)
}

export const getReportingBySection = async ({ id, section, startDate, endDate, pageType }: GetReportingBySectionInput) => {
  if (pageType === PageReportingTypeEnum.BALANCE_SHEET) {
    return getBalanceSheetReportingBySection({
      balanceSheetId: id, section
    })
  }
  return id === "all" ? getCompanyReportingBySection({ section, startDate, endDate }) : getCampaignReportingBySection({
    campaignId: id, section, startDate, endDate
  })
}

export const getReportingDataForSections = async ({ id, sections, startDate, endDate, pageType }:
  GetReportingDataForSectionsInput) => {
  const sectionData =
    await getReportingBySection({
      id,
      section: sections.sectionTabName,
      startDate,
      endDate,
      pageType
    })

  let highlightReportingResult = sections.highlights ? await getReportingBySection({
    id,
    section: ReportingApiSectionEnum.highlights,
    startDate,
    endDate,
    pageType
  }) : null

  highlightReportingResult = checkIfHighlightsReporting(highlightReportingResult)
    ? highlightReportingResult
    : null

  return {
    sectionData: checkIfErrorType(sectionData) ? null : sectionData,
    highlightsData: checkIfErrorType(highlightReportingResult) ? null : highlightReportingResult,
    error: (checkIfErrorType(sectionData) && checkIfErrorType(highlightReportingResult))
      || (sections.highlights && highlightReportingResult == null)
  }
}
