import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { LocationsReportingOutputType } from "../../types"
import { checkIfLocationsReporting } from "../../types/checkers"
import { locationReportingMock } from "../mock/location"

type GetBalanceSheetLocationsReportingInput = {
  balanceSheetId: string
}

export const getBalanceSheetLocationsReporting = async ({
  balanceSheetId
}: GetBalanceSheetLocationsReportingInput): Promise<LocationsReportingOutputType | null> => {
  try {
    // const response = await axiosGet({
    //   url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/location`
    // })

    const response = {
      type: 'success',
      data: locationReportingMock
    }

    if (
      checkIsAxiosGetOutputSuccess(response) &&
      checkIfLocationsReporting(response.data)
    ) {
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}