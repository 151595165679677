import React from 'react'

import AnimatedLoader from '../../../../components/AnimatedLoader'
import { ReportingTabKeyEnum } from '../../types'
import { ReportingBySectionOutputType } from '../../../../services/api/reporting/types'
import { LoadingContainer } from './style'
import { 
  checkIfImpactReporting,
  checkIfLocationsReporting,
  checkIfGlobalReporting,
  checkIfDevicesReporting, 
  checkIfCampaignsReporting,
  checkIfPlatformsReporting
} from '../../../../services/api/reporting/types/checkers'
import ClickImpressionCostTab from './ClickImpressionCostTab'
import DevicesTab from './DevicesTab'
import ImpactTab from './ImpactTab'
import LocationsTab from './LocationsTab'
import PlatformsTab from './PlatformsTab'
import CampaignsTab from './CampaignsTab'

type SectionContentProps = {
  activeTab: ReportingTabKeyEnum
  isLoading: boolean
  sectionData: ReportingBySectionOutputType | null
}

const SectionContent = ({
  activeTab,
  isLoading,
  sectionData
}: SectionContentProps) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <AnimatedLoader size={20} />
      </LoadingContainer>
    )
  }

  switch (activeTab) {
    case ReportingTabKeyEnum.global:
      if (checkIfGlobalReporting(sectionData)) {
        return (
          <ClickImpressionCostTab
            reportingData={sectionData}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.devices:
      if (checkIfDevicesReporting(sectionData)) {
        return (
          <DevicesTab
            reportingData={sectionData}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.impact:
      if (checkIfImpactReporting(sectionData)) {
        return (
          <ImpactTab
            reportingData={sectionData}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.locations:
      if (checkIfLocationsReporting(sectionData)) {
        return (
          <LocationsTab
            reportingData={sectionData}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.platforms:
      if (checkIfPlatformsReporting(sectionData)) {
        return (
          <PlatformsTab
            reportingData={sectionData}
          />
        )
      }
      return null

    case ReportingTabKeyEnum.campaigns:
      if (checkIfCampaignsReporting(sectionData)) {
        return (
          <CampaignsTab
            reportingData={sectionData}
          />
        )
      }
      return null

    default:
      return null
  }
}

export default SectionContent