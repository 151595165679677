import styled from '@emotion/styled'

import Padding from '../../../constants/padding'
import Colors from '../../../constants/colors'
import FontSize from '../../../constants/fontSize'

export const InputFileContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledInputFile = styled.input`
  display: none;
`

export const InputFileLabel = styled.label`
  border: solid;
  width: fit-content;
  padding: ${Padding.p2} ${Padding.p3};
  border-radius: 6px;
  cursor: pointer;
  height: fit-content;
  border-color: ${Colors.darkBlue};
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  font-size: 14px;
  margin-right: 8px;

  &:hover {
    background-color: ${Colors.darkBlue};
    color: white;
  }
`

export const InputFileInfo = styled.span`
  display: flex;
  align-items: center;
  font-size: ${FontSize.xs};
  width: 90%;
`

export const textStyle = {
  marginBottom: '0px'
}

export const fileNameTextStyle = {
  marginBottom: '0px',
  maxWidth: '80%'
}

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const InputImagePreviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const InputImagePreview = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
`

export const DeleteImageButton = styled.button`
  display: flex;
  position: absolute;
  top: -5px;
  left: -5px;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 100%;
  border: solid ${Colors.darkBlue} 1px;
  cursor: pointer;
  background-color: white;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: ${Colors.green};
  }
`

export const Tooltip = styled.div`
  cursor: pointer;
  font-size: ${FontSize.xs};
  font-weight: bold;
  background-color: ##495A74;
  color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -15px;
  top: -4px;
`
