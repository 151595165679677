import { RegisterStepEnum } from "../types"

export const getRegisterStep = (checkUserResult: boolean, userExists: boolean, companyNameEntered: boolean): RegisterStepEnum => {
  if (!checkUserResult) {
    return RegisterStepEnum.CHECK_EMAIL
  }

  if (userExists) {
    if (companyNameEntered) {
      return RegisterStepEnum.ENTER_PASSWORD
    }

    return RegisterStepEnum.ENTER_COMPANY_NAME
  }

  return RegisterStepEnum.FILL_FORM
}