export const headerCsvHighlightsBalanceSheet = [
  'clicks',
  'impressions',
  'cost',
  'co2',
  'energy'
]

export const headerCsvHighlightsCampaign = [
  'co2',
  'impact',
  'co2PerEuro',
  'clicks',
  'impressions',
  'cost',
  'cpc',
  'ctr'
]
