import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { HighlightsReportingOutputType } from "../../types"
import { checkIfHighlightsReporting } from "../../types/checkers"

type GetCampaignHighlightsReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignHighlightsReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignHighlightsReportingInputType): Promise<HighlightsReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reports/campaigns/${campaignId}/highlights${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfHighlightsReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}


