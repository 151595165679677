import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BalanceSheetView from './View'
import { getBalanceSheetsApi } from '../../services/api/balanceSheets/get/getList'
import { checkIfErrorType } from '../../types/error'
import { GetBalanceSheetApiOutput, GetBalanceSheetsApiOutput, GetBalanceSheetsSort } from '../../services/api/balanceSheets/get/types'
import BalanceSheetCreationModal from './CreationModal'
import { BalanceSheetCreationFormData } from './types'
import { defaultCreationForm, getFormErrors } from './helpers/creationForm'
import { ListOrder } from '../../types/common'
import { handleSubmit } from './helpers/submission'

const BalanceSheet = () => {
  const [fetchError, setFetchError] = useState('')
  const [sort, setSort] = useState<GetBalanceSheetsSort>('createdAt')
  const [order, setOrder] = useState<ListOrder>('desc')
  const [balanceSheets, setBalanceSheets] = useState<GetBalanceSheetsApiOutput | null>(null)
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false)
  const [creationForm, setCreationForm] = useState<BalanceSheetCreationFormData>(defaultCreationForm)
  const [hasCreationBeenSubmitted, setHasCreationBeenSubmitted] = useState(false)
  const [creationApiError, setCreationApiError] = useState('')
  const [apiRetrieveBalanceSheetsCount, setApiRetrieveBalanceSheetsCount] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      const retrievedBalanceSheets = await getBalanceSheetsApi({
        sort,
        order
      })

      if (checkIfErrorType(retrievedBalanceSheets)) {
        setFetchError(retrievedBalanceSheets.error)
        return
      }

      setBalanceSheets(retrievedBalanceSheets)
    })()
  }, [apiRetrieveBalanceSheetsCount])

  const creationFormErrors = getFormErrors(creationForm)

  const getFieldError = (field: keyof BalanceSheetCreationFormData) => {
    if (!hasCreationBeenSubmitted) {
      return ''
    }

    return creationFormErrors[field]
  }

  const clearCreationForm = () => {
    setCreationForm(defaultCreationForm)
  }

  const handleFormDataChange = (key: keyof BalanceSheetCreationFormData, value: string | null) => {
    setCreationForm({
      ...creationForm,
      [key]: value
    })
    setHasCreationBeenSubmitted(false)
    setCreationApiError('')
  }

  const onPlatformSelect = (platform: string) => {
    const newPlatforms = [...creationForm.platforms]
    
    if (newPlatforms.includes(platform)) {
      const platformIndex = newPlatforms.findIndex(item => item === platform)
      newPlatforms.splice(platformIndex, 1)
    } else {
      newPlatforms.push(platform)
    }

    setCreationForm({
      ...creationForm,
      platforms: newPlatforms
    })
    setHasCreationBeenSubmitted(false)
    setCreationApiError('')
  }

  const handleCancelCreation = () => {
    setHasCreationBeenSubmitted(false)
    setCreationApiError('')
    clearCreationForm()
    setIsCreationModalOpen(false)
  }

  const handleBalanceSheetCreation = async () => {
    await handleSubmit({
      setCreationApiError,
      creationForm,
      setHasCreationBeenSubmitted,
      setIsCreationModalOpen,
      apiRetrieveBalanceSheetsCount,
      setApiRetrieveBalanceSheetsCount,
      clearCreationForm
    })
  }

  const onReportingClick = (selectedBalanceSheet: GetBalanceSheetApiOutput) => {
    navigate(`/reporting?balance-sheet-id=${encodeURIComponent(selectedBalanceSheet.id)}`)
  }

  return (
    <BalanceSheetView
      fetchError={fetchError}
      balanceSheets={balanceSheets}
      onCtaClick={() => setIsCreationModalOpen(true)} 
      onReportingClick={onReportingClick}>
      {isCreationModalOpen && (
        <BalanceSheetCreationModal
          onClose={() => setIsCreationModalOpen(false)}
          onCancel={handleCancelCreation}
          onSubmit={handleBalanceSheetCreation}
          formData={creationForm}
          onFormDataChange={handleFormDataChange}
          onPlatformSelect={onPlatformSelect}
          getFieldError={getFieldError}
          apiError={creationApiError}
        />
      )}
    </BalanceSheetView>
  )
}

export default BalanceSheet