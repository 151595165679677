export type CompanyForProvisioningUser = {
  name: string,
  id: string
}

export type CompaniesForProvisioningUser = CompanyForProvisioningUser[]

export const checkIfCompaniesForProvisioningUserApi = (value: any): value is CompaniesForProvisioningUser => {
  return Array.isArray(value)
    && value.every((item) =>
      item && item.id && typeof item.id === 'string'
      && item.name && typeof item.name === 'string'
  )
}