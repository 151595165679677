import styled from "@emotion/styled"
import { CSSProperties } from "react"

import Margin from "../../../../../constants/margin"
import { Padding } from "../../../../../constants"

export const boxStyle: CSSProperties = {
  minWidth: '200px',
  paddingTop: Padding.p4
}

export const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Margin.m5};
`

export const StyledTooltipWrapper = styled.div`
  margin-top: -6px;
`