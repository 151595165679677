import React, { useState } from 'react'

import { StyledWrapper } from './style'
import LeftMenuItem from '../LeftMenuItem'
import { Colors } from '../../../../constants'
import { StyledItemText } from '../style'
import UserSubmenu from './UserSubmenu'
import { UserIcon } from '../../../../components/Icons'

type LeftMenuBottomProps = {
  companyName: string | null
  handleLogout: () => void
}

const LeftMenuBottom = ({ companyName, handleLogout }: LeftMenuBottomProps) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)

  return (
    <StyledWrapper>
      <LeftMenuItem
        onClick={() => setIsSubmenuOpen(true)}
        logo={
          <UserIcon style={{ flexShrink: 0 }} color={Colors.veryDarkGrey} />
        }
        selected={false}
        style={{ marginTop: 'auto', width: '100%' }}
      >
        <StyledItemText>{companyName ?? 'Yetic'}</StyledItemText>
      </LeftMenuItem>
      <UserSubmenu
        isOpen={isSubmenuOpen}
        setIsOpen={setIsSubmenuOpen}
        handleLogout={handleLogout}
        companyName={companyName}
      />
    </StyledWrapper>
  )
}

export default LeftMenuBottom
