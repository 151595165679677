import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import FontSize from '../../constants/fontSize'
import Colors from '../../constants/colors'
import Margin from '../../constants/margin'
import Padding from '../../constants/padding'
import { maxTableContentHeight } from '../../constants/height'
import { AlignEnum } from '../../types/common'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${Margin.m4};
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: ${Padding.p3};
  border-bottom: 1px solid ${Colors.darkBlue_015};
  margin-bottom: ${Margin.m6};
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${FontSize.base};
  border: 1px solid ${Colors.darkBlue_015};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${Colors.white};
  margin-bottom: ${Margin.m2};
`

export const TableHeader = styled.div`
  display: flex;
  padding: ${Padding.p5} 0;
  border-bottom: 1px solid ${Colors.darkBlue_015};
  background-color: ${Colors.grey};
`

export const TableHeaderItem = styled.div<{
  width: string
  align?: AlignEnum
}>`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  color: ${Colors.darkBlue};
  padding: 0 25px 0 20px;
  box-sizing: border-box;
  width: ${(props) => props.width};
  justify-content: ${({ align }) =>
    align === AlignEnum.LEFT
      ? 'flex-start'
      : align === AlignEnum.CENTER
      ? 'center'
      : 'flex-end'};
`

export const TableRowsContainer = styled.ul<{maxHeight: number | undefined}>`
  display: flex;
  flex-direction: row;
  tablelist-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: ${props => props.maxHeight ? `${props.maxHeight}px` : `${maxTableContentHeight}px`};
  flex-wrap: wrap;
  overflow-y: scroll;
  `

export const TableRow = styled.li<{ isClickable?: boolean, index: number }>`
  ${({ index }) => index !== 0 && `border-top: 1px solid ${Colors.darkBlue_015};`}
  position: relative;
  overflow: hidden;
  height: 54px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  background-color: ${Colors.white};

  :hover {
    background-color: ${Colors.veryLightGrey};
  }
`

export const RowContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const RowItem = styled.div<{
  bold?: boolean
  width: string
  align?: AlignEnum
}>`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  width: ${(props) => props.width};
  height: 100%;
  box-sizing: border-box;
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  justify-content: ${({ align }) =>
    align === AlignEnum.LEFT
      ? 'flex-start'
      : align === AlignEnum.CENTER
      ? 'center'
      : 'flex-end'};
`

export const NoResult = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconContainer = styled.span`
  display: inline-block;
  margin-left: auto;
`

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const actionButtonSize = 30

export const sortIconStyle: CSSProperties = {
  cursor: 'pointer'
}

export const downloadIcon: CSSProperties = {
  marginLeft: Margin.m4
}
