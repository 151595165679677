import { units } from "../pages/Reporting/helpers/units"

export const generateCsv = (
    data: any[] | null,
    headers: string[],
    filename: string
  ) => {
    if (data === null) {
      return
    }

    const headersWithUnits = headers.map((header) => units[header] ? `${header} (${units[header]})` : header)
  
    const csv = [headersWithUnits.join(',')]
  
    for (let i = 0; i < data.length; i++) {
      const row = data[i]
      const values = headers.map((header) => {
        const value = row[header]
  
        if (typeof value === 'string') {
          return `"${value.replace(/"/g, '""').replace(/\n/g, '\\n')}"`
        }
  
        return value
      })
  
      csv.push(values.join(','))
    }
  
    const csvContent = csv.join('\n')
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${filename}.csv`
    a.click()
    URL.revokeObjectURL(url)
  }
  