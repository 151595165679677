import styled from "@emotion/styled"

import { TagVariant } from "."
import { BalanceSheetStatusEnum } from "../../pages/BalanceSheet/types"

export const StyledTag = styled.div<{ backgroundColor: string }>`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const getFontColorFromVariant = (variant: TagVariant) => {
  switch (variant) {
    case 'grey':
      return '#666666'
    case 'red':
      return '#f22a2a'
    case 'orange':
      return '#fa5c00'
    case 'green':
      return '#10a610'
    case 'blue':
      return '#0045aa'
  }
}

export const getBackgroundColorFromVariant = (variant: TagVariant) => {
  switch (variant) {
    case 'grey':
      return '#f2f2f2'
    case 'red':
      return '#ffe3e3'
    case 'orange':
      return '#ffeee0'
    case 'green':
      return '#e8ffe8'
    case 'blue':
      return '#ebf5ff'
  }
}

export const getTagVariantFromStatus = (status: BalanceSheetStatusEnum): TagVariant => {
  switch (status) {
    case BalanceSheetStatusEnum.WAITING_FOR_PROVISIONING:
      return 'grey'
    case BalanceSheetStatusEnum.PROVISIONING_IN_PROGRESS:
      return 'blue'
    case BalanceSheetStatusEnum.READY:
      return 'green'
    case BalanceSheetStatusEnum.WAITING_FOR_PAYMENT:
      return 'orange'
    case BalanceSheetStatusEnum.DELETED:
      return 'red'
  }
}