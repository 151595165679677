import {
  checkIfGetOneBalanceSheetApiOutput, 
  GetOneBalanceSheetApiOutput
} from "./types"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../helpers/axios/get"
import { backendURL } from "../../../../constants"
import { ErrorType } from "../../../../types/error"

type GetOneBalanceSheetApiInput = {
  balanceSheetId: string
}

export const getOneBalanceSheetApi = async ({ balanceSheetId }: GetOneBalanceSheetApiInput): Promise<GetOneBalanceSheetApiOutput | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/balance-sheets/${balanceSheetId}`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfGetOneBalanceSheetApiOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors du chargement des données du bilan sélectionné'
  }
}