import React, { useEffect, useState } from 'react'

import AnimatedLoader from '../../components/AnimatedLoader'
import { LoadingPageContainer } from './style'
import PageTemplate from '../../templates/Page'

const UnauthenticatedLoadingPage = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaderVisible(true)
    }, 200)
  }, [])

  return (
    <LoadingPageContainer>
      {isLoaderVisible && <AnimatedLoader size={20} />}
    </LoadingPageContainer>
  )
}

export default UnauthenticatedLoadingPage
