import styled from '@emotion/styled'

import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'
import Colors from '../../../../constants/colors'

export const HeaderSelectionContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
`

export const SelectionItem = styled.div<{
  isSelected: boolean
  color: string
}>`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  gap: ${Margin.m3};
  border: 1px solid ${Colors.darkGrey};
  border-top: none;
  ${({ isSelected, color }) => isSelected && `border-color: ${color};`}
  background-color: ${({ isSelected, color }) =>
    isSelected ? color : Colors.white};
  max-width: 180px;
  min-height: 100px;
  padding: ${Padding.p6};
  box-sizing: border-box;
`

export const SelectionItemName = styled.div``

export const SelectionItemTotalValue = styled.div``
