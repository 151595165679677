import React from 'react'

import { P } from '../../../components/Text'
import { FontWeight } from '../../../constants'
import { SmallCTA } from '../style'

const BalanceSheetLinecardContent = () => {
  return (
    <P
      style={{
        fontWeight: FontWeight.YETIC_MEDIUM,
      }}
    >
      Cliquez sur <SmallCTA>Bilans</SmallCTA> pour accéder aux bilans que
      vous avez déjà ajoutés, ou pour en créer un nouveau.
    </P>
  )
}

export default BalanceSheetLinecardContent
