import { ColumnItem } from "../../../../../components/Table/types"
import { AlignEnum } from "../../../../../types/common"

export const campaignTabColumns: ColumnItem[] = [
  {
    name: 'campaignName',
    label: 'Nom',
    align: AlignEnum.LEFT,
    width: '400px'
  },
  {
    name: 'clicks',
    label: 'clic',
    isSortable: true,
    align: AlignEnum.LEFT,
    width: '160px'
  },
  {
    name: 'impressions',
    label: 'Impressions',
    isSortable: true,
    align: AlignEnum.LEFT,
    width: '160px'
  },
  {
    name: 'co2',
    label: 'CO2',
    isSortable: true,
    align: AlignEnum.LEFT,
    unit: 'g',
    width: '160px'
  },
  {
    name: 'cost',
    label: 'Coût',
    isSortable: true,
    align: AlignEnum.LEFT,
    unit: '€',
    width: '160px'
  }
]
