import React from 'react'

import { IconType } from './types'
import Colors from '../../constants/colors'

const ArrowLast = ({
  height = '15px',
  width = '15px',
  style,
  color = Colors.black
}: IconType) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 5.5 7"
    >
      <g
        id="Groupe_13283"
        data-name="Groupe 13283"
        transform="translate(-607.5 -689)"
      >
        <path
          data-name="Icon feather-chevron-down"
          d="M9,13.5l3,3,3-3Z"
          transform="translate(594.5 704.5) rotate(-90)"
          fill={color}
          stroke="#142a4c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Ligne_160"
          data-name="Ligne 160"
          y2="6"
          transform="translate(612.5 689.5)"
          fill="none"
          stroke="#142a4c"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export default ArrowLast
