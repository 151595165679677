import React from 'react'

import { LineChartData, Point, DatasetsSelection } from '../lineChartDrawer'
import { CaptionsContainer, CaptionRectangle, CaptionItemContainer } from './style'
import { P } from '../../../Text'

type PropsType = {
  data: LineChartData<Point>
  selectedDatasets: DatasetsSelection
}

export const LineChartCaptions = ({ data, selectedDatasets }: PropsType) => {
  return (
    <CaptionsContainer>
      {data.map(({ name, caption, color }, index) => {
        if (
          selectedDatasets.find((el) => el.name === name && el.isSelected) &&
          caption
        ) {
          return (
            <CaptionItemContainer key={`${name}-${index}`}>
              <CaptionRectangle color={color} />: <P small>{caption}</P>
            </CaptionItemContainer>
          )
        } else {
          return ''
        }
      })}
    </CaptionsContainer>
  )
}

export default LineChartCaptions
