import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import { formatNumber } from '../../../../../helpers/rounds'
import Co2Tooltip from '../Tooltips/co2Tooltip'
import KpiCard from '../KpiCard'

type PropsType = {
  co2?: number
  isLoading: boolean
}

export const CO2Card = ({
  co2,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.co2}
      title="CO₂"
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : co2 !== undefined ? formatNumber(co2) + ' g' : 'N/A'
      }
      tooltipContent={<Co2Tooltip />}
    />
  )
}
