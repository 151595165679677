import { checkUpdateGoogleVideoCampaignDetails, UpdateGoogleVideoCampaignDetails } from "./types"
import { axiosPut, checkIsAxiosPutOutputSuccess } from "../../../../../helpers/axios/put"
import { backendURL } from "../../../../../constants"
import { ErrorType } from "../../../../../types/error"

type UpdateCampaignApiInput = {
  campaignId: string
  duration: number
}

export const updateCampaignApi = async ({
  campaignId,
  duration
}: UpdateCampaignApiInput): Promise<UpdateGoogleVideoCampaignDetails | ErrorType> => {
  const response = await axiosPut({
    url: `${backendURL}/campaigns/${campaignId}`,
    payload: {
      duration
    }
  })

  if (checkIsAxiosPutOutputSuccess(response)) {
    if (checkUpdateGoogleVideoCampaignDetails(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors de la mise à jour des détails de la campagne.'
  }
}