import React from 'react'

import ChoiceItem from './ChoiceItem'
import { Container, ItemsContainer } from './style'
import { P } from '../Text'
import { Margin } from '../../constants'
import ErrorMessage from './ErrorMessage'
import ChoiceItemsTitle from './Title'

type ChoiceItemsProps = {
  title: string
  options: {
    icon: React.JSX.Element | null
    label: string
  }[]
  optionsGroupName: string
  selectedOptions: string[]
  handleSelect: (val: string) => void
  type: 'checkbox' | 'radio'
  error: string
}

const ChoiceItems = ({
  title,
  options,
  optionsGroupName,
  selectedOptions,
  handleSelect,
  type,
  error,
}: ChoiceItemsProps) => {
  const checkIfActive = ({
    selectedOptions,
    option,
  }: {
    selectedOptions: string[]
    option: { icon: React.JSX.Element | null; label: string }
  }) => !!selectedOptions.find((selOpt) => selOpt === option.label)

  return (
    <Container>
      <ChoiceItemsTitle title={title} />
      <ItemsContainer>
        {options.map((opt) => (
          <ChoiceItem
            label={opt.label}
            icon={opt.icon}
            handleSelect={handleSelect}
            isActive={checkIfActive({ selectedOptions, option: opt })}
            name={optionsGroupName}
            type={type}
            key={opt.label}
          />
        ))}
      </ItemsContainer>
      {error && <ErrorMessage message={error} />}
    </Container>
  )
}

export default ChoiceItems
