import React from 'react'

import {
  HighlightsContainer,
  HighlightsRow,
  DivTitle,
  DownloadHighlightButton
} from './style'
import Colors from '../../../../constants/colors'
import { DownloadIcon } from '../../../../components/Icons'
import { generateCsv } from '../../../../helpers/generateCsv'
import { H3 } from '../../../../components/Text'
import HorizontalSeparator from '../../../../components/HorizontalSeparator'
import { HighlightsReportingOutputType } from '../../../../services/api/reporting/types'
import { ReportingDownloadDataType } from '../../types'
import { headerCsv } from './constants'
import { 
  CO2Card,
  ImpactCard,
  CO2PerEuroCard,
  ClicksCard ,
  ImpressionsCard,
  CostCard,
  EnergyCard
} from './cards'

type PropsType = {
  data: HighlightsReportingOutputType | null
  downloadData: ReportingDownloadDataType | null
  isLoading: boolean
}

export const ReportingHighlightsBalanceSheet = ({
  data,
  downloadData,
  isLoading
}: PropsType) => {
  const { clicks, impressions, co2, energy, cost, cpc, ctr, co2PerEuro } = data || {}

  return (
    <HighlightsContainer>
      <HorizontalSeparator color={Colors.darkGrey} />
      <DivTitle>
        <H3>Chiffres clés</H3>
        {downloadData && <DownloadHighlightButton
          onClick={() => {
            generateCsv(downloadData, headerCsv, 'reporting')
          }}
        >
          <DownloadIcon />
        </DownloadHighlightButton>}
      </DivTitle>

      <HighlightsRow>
        <CO2Card co2={co2} isLoading={isLoading} />
        <EnergyCard energy={energy} isLoading={isLoading} />
      </HighlightsRow>

      <HighlightsRow>
        <ClicksCard clicks={clicks} isLoading={isLoading} />
        <ImpressionsCard impressions={impressions} isLoading={isLoading} />
        <CostCard cost={cost} isLoading={isLoading} />
      </HighlightsRow>
    </HighlightsContainer>
  )
}
