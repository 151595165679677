import { checkIfCampaignStatus } from "../../../../../types/status"

export type UpdateGoogleDisplayCampaign = {
  id: string,
  status: string
}

export const checkUpdateGoogleDisplayCampaign = (value: any): value is UpdateGoogleDisplayCampaign => {
  return value && value.id && typeof value.id === 'string'
      && value.status && typeof value.status === 'string' && checkIfCampaignStatus(value.status)
}