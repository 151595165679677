import React, { useEffect, useRef, useState } from 'react'

import formatImpactData from './helpers/formatData'
import { ImpactReportingOutputType } from '../../../../../services/api/reporting/types'
import { AlignEnum, SortEnum } from '../../../../../types/common'
import StatCard from '../StatCard'
import { LeafIcon } from '../../../../../components/Icons'
import { formatNumber } from '../../../../../helpers/rounds'
import { HighlightColors } from '../../../../../constants/colors'
import Table from '../../../../../components/Table'
import { TabContentWrapper } from '../style'
import { chartGap } from '../../../style'
import BarChart from '../../../../../components/Chart/BarChart'
import LineChart from '../../../../../components/Chart/LineChart'

type PropsType = {
  reportingData: ImpactReportingOutputType
}

const ImpactTab = ({ reportingData }: PropsType) => {
  const [chartWidth, setChartWidth] = useState(400)
  const [currentCO2ByDateTablePage, setCurrentCO2ByDateTablePage] = useState(1)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateChartWidth = () => {
      if (containerRef.current) {
        setChartWidth((containerRef.current.offsetWidth - chartGap) / 2)
      }
    }

    updateChartWidth()

    window.addEventListener('resize', updateChartWidth)
    return () => {
      window.removeEventListener('resize', updateChartWidth)
    }
  }, [containerRef.current])

  const {
    impactLineChartData,
    impactBarChartCO2PerDevice,
    impactTableDataCO2,
    impactTableDataCO2PerDevice,
    co2
  } = formatImpactData(reportingData)

  const tableColumnsCO2PerDevice = [
    {
      name: 'device',
      label: 'Appareil',
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    },
    {
      name: 'co2',
      label: 'CO₂',
      unit: 'g',
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    }
  ]

  const tableColumnsCO2 = [
    {
      name: 'date',
      label: `Date`,
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    },
    {
      name: 'co2',
      label: 'CO₂',
      unit: 'g',
      isSortable: true,
      width: '50%',
      align: AlignEnum.LEFT
    }
  ]

  const defaultTablePerDeviceSort = {
    name: 'co2',
    order: SortEnum.DESC
  }

  const defaultTableSort = {
    name: 'date',
    order: SortEnum.DESC
  }

  const cardWidth = `calc(50% - ${chartGap / 2}px)`

  return (
    <>
      <TabContentWrapper ref={containerRef}>
        <LineChart
          chartId='impactLineChart'
          title='Emissions de CO₂ par jour'
          rangeSelectorData={null}
          data={impactLineChartData}
          // title={'Emissions de CO₂ par jour'}
          chartWidth={chartWidth}
          cardWidth={cardWidth}
        />
        <StatCard
          icon={<LeafIcon height={'100px'} />}
          value={formatNumber(co2)}
          unit={'g'}
          width={cardWidth}
          color={HighlightColors.co2}
          title={'Emissions de CO₂ total'}
        />
        <BarChart
          data={impactBarChartCO2PerDevice}
          title={'Emissions de CO₂ par appareil'}
          chartWidth={chartWidth}
          cardWidth={cardWidth}
          horizontal
        />
        <Table
          title={`Emissions de CO₂ par jour`}
          columns={tableColumnsCO2}
          elements={impactTableDataCO2}
          sort={defaultTableSort}
          pagination={{
            totalItems: impactTableDataCO2.length,
            itemsByPage: 6,
            currentPage: currentCO2ByDateTablePage,
            handleChangePage: setCurrentCO2ByDateTablePage
          }}
          download
        />
        <Table
          title={'Emissions de CO₂ par appareil'}
          columns={tableColumnsCO2PerDevice}
          elements={impactTableDataCO2PerDevice}
          sort={defaultTablePerDeviceSort}
          download
        />
      </TabContentWrapper>
    </>
  )
}

export default ImpactTab
