import axios from 'axios'

import { QueryResponseType } from './types'

export type AxiosPutType = {
  url: string
  payload: any
}

type QueryPutOutputSuccessType = {
  type: QueryResponseType.SUCCESS
  data: any
}

type QueryPutOutputErrorType = {
  type: QueryResponseType.ERROR
  error: {
    data: {
      code: string
      message: string
    }
  }
}

export const checkIsAxiosPutOutputSuccess = (
  value: any
): value is QueryPutOutputSuccessType => {
  if (value && value.type && value.data) {
    if (value.type === QueryResponseType.SUCCESS) {
      return true
    }
  }
  return false
}

export const axiosPut = async ({
  url,
  payload,
}: AxiosPutType): Promise<
  QueryPutOutputSuccessType | QueryPutOutputErrorType
> => {
  try {
    const response = await axios.put(url,
      { ...payload },
      { withCredentials: true }
    )

    if (
      checkIsAxiosPutOutputSuccess({
        type: QueryResponseType.SUCCESS,
        data: response.data,
      })
    ) {
      return {
        type: QueryResponseType.SUCCESS,
        data: response.data,
      }
    }
    return {
      type: QueryResponseType.ERROR,
      error: response.data,
    }
  } catch (error: any) {
    return {
      type: QueryResponseType.ERROR,
      error: error.response ?? error.request ?? error,
    }
  }
}
