import { AlignEnum, SortEnum } from "../../../../../types/common";

export const tableColumnsCO2PerDevice = [
  {
    name: 'device',
    label: 'Appareil',
    isSortable: true,
    width: '50%',
    align: AlignEnum.LEFT
  },
  {
    name: 'co2',
    label: 'CO₂',
    unit: 'g',
    isSortable: true,
    width: '50%',
    align: AlignEnum.LEFT
  }
]

export const tableColumnsCO2 = [
  {
    name: 'date',
    label: `Date`,
    isSortable: true,
    width: '50%',
    align: AlignEnum.LEFT
  },
  {
    name: 'co2',
    label: 'CO₂',
    unit: 'g',
    isSortable: true,
    width: '50%',
    align: AlignEnum.LEFT
  }
]

export const tablesColumnsCO2PerSource = [
  {
    name: 'source',
    label: 'Source',
    width: '50%',
    align: AlignEnum.LEFT
  },
  {
    name: 'co2',
    label: 'CO₂',
    width: '50%',
    align: AlignEnum.LEFT,
    unit: 'g',
    isSortable: true
  }
]

export const defaultTablePerDeviceSort = {
  name: 'co2',
  order: SortEnum.DESC
}

export const defaultTableSort = {
  name: 'date',
  order: SortEnum.DESC
}

export const defaultTablePerSourceSort = {
  name: 'co2',
  order: SortEnum.DESC
}
