import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { GlobalReportingOutputType } from "../../types"
import { checkIfGlobalReportingAPI } from "../../types/checkers"
import { formatGlobalReportingAPIType } from "../../types/formatters"

type GetCampaignGlobalReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignGlobalReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignGlobalReportingInputType): Promise<GlobalReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reports/campaigns/${campaignId}/global${
        startDate ? `?startDate=${startDate}` : ''
      }${
        endDate
          ? startDate
            ? `&endDate=${endDate}`
            : `?endDate=${endDate}`
          : ''
      }`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGlobalReportingAPI(response.data)) {
        return formatGlobalReportingAPIType(response.data)
      }
    }

    return null
  } catch (_) {
    return null
  }
}
