import { BarChartData, Point as BarChartPoint } from '../../../../../../components/Chart/BarChart'
import { LineChartData, Point as LineChartPoint } from '../../../../../../components/Chart/LineChart/lineChartDrawer'
import { ElementItem } from '../../../../../../components/Table/types'
import { HighlightColors } from '../../../../../../constants/colors'
import { formatDateToString } from '../../../../../../helpers/date'
import { ImpactReportingOutputType } from '../../../../../../services/api/reporting/types'

type FormatImpactDataOutput = {
  impactLineChartData: LineChartData<LineChartPoint>
  impactBarChartCO2PerDevice: BarChartData<BarChartPoint>[]
  impactTableDataCO2: ElementItem[]
  impactTableDataCO2PerDevice: ElementItem[]
  co2: number
}

const formatImpactData = (
  data: ImpactReportingOutputType
): FormatImpactDataOutput => {
  const impactLineChartData: LineChartData<LineChartPoint> = [{
    name: 'CO₂',
    color: HighlightColors.co2,
    values: data.co2PerDay.map(({ date, co2 }) => ({
      time: new Date(date),
      value: co2
    })),
    unit: 'g',
    caption: 'CO₂ émis (en g)',
    isAugmentationGood: true
  }]

  const impactBarChartCO2PerDevice: BarChartData<BarChartPoint>[] = [
    {
      name: 'CO2',
      color: HighlightColors.co2,
      unit: 'g',
      values: [
        {
          category: 'Smartphone',
          value: data.co2PerDevice.smartphone
        },
        {
          category: 'Tablette',
          value: data.co2PerDevice.tablet
        },
        {
          category: 'Ordinateur',
          value: data.co2PerDevice.desktop
        },
        {
          category: 'TV',
          value: data.co2PerDevice.tv
        }
      ]
    }
  ]

  const impactTableDataCO2: ElementItem[] = data.co2PerDay.map((item) => ({
    id: item.date,
    date: formatDateToString(new Date(item.date)),
    co2: item.co2
  }))

  const impactTableDataCO2PerDevice: ElementItem[] = [
    {
      id: 'smartphone',
      device: 'Smartphone',
      co2: data.co2PerDevice.smartphone
    },
    {
      id: 'tablet',
      device: 'Tablette',
      co2: data.co2PerDevice.tablet
    },
    {
      id: 'desktop',
      device: 'Ordinateur',
      co2: data.co2PerDevice.desktop
    },
    {
      id: 'tv',
      device: 'TV',
      co2: data.co2PerDevice.tv
    }
  ]

  return {
    impactLineChartData,
    impactBarChartCO2PerDevice,
    impactTableDataCO2,
    impactTableDataCO2PerDevice,
    co2: data.co2
  }
}

export default formatImpactData
