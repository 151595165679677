import React, { CSSProperties, useEffect, useRef, useState } from 'react'

import { arrowSvgStyle, checkSvgStyle, DropdownError, selectedDropdownColor, StyledDropdown, StyledDropdownOptionItem, StyledDropdownOptions, StyledDropdownSelect, StyledLabel } from './style'
import ArrowDropdown from '../Icons/arrowDropdown'
import CheckIcon from '../Icons/check'
import { Margin } from '../../constants'

type DropdownProps = {
  label?: string
  options: string[] | null
  selected: string | null
  onValueChange: (value: string | null) => void
  style?: CSSProperties
  marginBottom?: string
  selectStyle?: CSSProperties
  optionsStyle?: CSSProperties
  optionsPosition?: 'top' | 'bottom'
  error?: string
}

const Dropdown = ({ label, options, selected, onValueChange, style, marginBottom = Margin.m7, selectStyle, optionsStyle, optionsPosition, error }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleUpdateSelected = (option: string) => {
    onValueChange(option)
    setIsOpen(false)
  }

  return (
    <StyledDropdown ref={containerRef} style={style} marginBottom={error ? `calc(${marginBottom + '24px'})` : marginBottom}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledDropdownSelect onClick={handleDropdownToggle} style={selectStyle} error={!!error}>
        {selected ?? 'Sélectionner une option'}
        <ArrowDropdown style={{ ...arrowSvgStyle, ...(isOpen ? { transform: 'rotate(180deg) translateY(-8%)' } : {}) }} width='20px' />
      </StyledDropdownSelect>
      {isOpen && (
        <StyledDropdownOptions style={optionsStyle} position={optionsPosition}>
          {options && options.length > 0 ? options.map((option) => (
            <StyledDropdownOptionItem
              key={option}
              selected={selected === option}
              onClick={selected === option ? undefined : () => handleUpdateSelected(option)}
            >
              {option}
              {selected === option && <CheckIcon color={selectedDropdownColor} style={checkSvgStyle} width='18px' />}
            </StyledDropdownOptionItem>
          )) : <StyledDropdownOptionItem selected={false} disabled>Aucune option disponible</StyledDropdownOptionItem>}
        </StyledDropdownOptions>
      )}
      {error ? (
        <DropdownError>{error}</DropdownError>
      ) : null}
    </StyledDropdown>
  )
}

export default Dropdown