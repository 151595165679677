import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from './types'

const CheckIcon = ({
  height = '24px',
  width = '24px',
  style,
  color = Colors.black
}: IconType) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill='none'
    >
      <path
        d="M4 12.6111L8.92308 17.5L20 6.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckIcon
