import styled from "@emotion/styled"

import { Colors } from "../../../constants"

export const StyledLink = styled.div`
  color: ${Colors.blue};
  cursor: pointer;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`
