import styled from "@emotion/styled"

import { Colors, Margin } from "../../../constants"

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`

export const StyledApiError = styled.div`
  color: ${Colors.red};
  text-align: center;
`