import React from 'react'

import { RegisterFieldsEnum } from '../../types'
import Input from '../../../../components/Input'

type CheckEmailRegisterFormInputProps = {
  email: string
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    field: RegisterFieldsEnum
  ) => void
  formError: string
}

const CheckEmailRegisterFormInput = ({
  email,
  handleFieldChange,
  formError,
}: CheckEmailRegisterFormInputProps) => {
  return (
    <>
      <Input
        value={email}
        onChange={(e) => handleFieldChange(e, RegisterFieldsEnum.EMAIL)}
        containerWidth="100%"
        type="email"
        label={'E-mail'}
        error={formError}
      />
    </>
  )
}

export default CheckEmailRegisterFormInput
