export enum SubmitFileErrorEnum {
  FILE_NOT_FOUND = "FILE_NOT_FOUND",
  FILE_INVALID = "FILE_INVALID",
  UNKNOWN = "UNKNOWN"
}

export const submitFileErrorWording = {
  [SubmitFileErrorEnum.FILE_NOT_FOUND]: "Fichier introuvable",
  [SubmitFileErrorEnum.FILE_INVALID]: "Le fichier n'est pas valide. Peut-être y a-t-il un soucis avec le nom des colonnes ?",
  [SubmitFileErrorEnum.UNKNOWN]: "Une erreur est survenue"
}