import axios from 'axios'

import { QueryResponseType } from './types'

export type AxiosGetType = {
  url: string
}

type QueryGetOutputSuccessType = {
  type: QueryResponseType.SUCCESS
  data: any
}

type QueryGetOutputErrorType = {
  type: QueryResponseType.ERROR
  error: {
    data: string
    status: number
  }
}

export const checkIsAxiosGetOutputSuccess = (
  value: any
): value is QueryGetOutputSuccessType => {
  if (value && value.type && value.data) {
    if (value.type === QueryResponseType.SUCCESS) {
      return true
    }
  }
  return false
}

export type QueryGetOutputType =
  | QueryGetOutputSuccessType
  | QueryGetOutputErrorType

export const axiosGet = async ({
  url
}: AxiosGetType): Promise<QueryGetOutputType> => {
  try {
    const response = await axios.get(url, {
      withCredentials: true
    })

    if (
      checkIsAxiosGetOutputSuccess({
        type: QueryResponseType.SUCCESS,
        data: response.data
      })
    ) {
      return {
        type: QueryResponseType.SUCCESS,
        data: response.data
      }
    }
    return {
      type: QueryResponseType.ERROR,
      error: response
    }
  } catch (error: any) {
    return {
      type: QueryResponseType.ERROR,
      error: error.response ?? error.request ?? error
    }
  }
}
