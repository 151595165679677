import React from 'react'

import { 
  CampaignFormat,
  CampaignPlatform,
  GoogleCampaignFormat,
  MetaCampaignFormat,
} from '../../../../types/formatAndPlatform'
import Button from '../../../../components/Button'
import { Margin } from '../../../../constants'
import GoogleSearchDetails from './GoogleSearchDetails'
import GoogleDisplayDetails from './GoogleDisplayDetails'
import GoogleVideoDetails from './GoogleVideoDetails'
import GooglePerfMaxDetails from './GooglePerfMaxDetails'
import FacebookDetails from './MetaFacebookDetails'
import InstagramDetails from './MetaInstagramDetails'
import MessengerDetails from './MetaMessengerDetails'

export interface CampaignDetailsProps {
  name: string
  campaignPlatform: CampaignPlatform
  campaignFormat: CampaignFormat
  campaignId: string
  handleEndProvisioning: () => void
  handleCancel: () => void
}

const CampaignDetails = ({ 
  name,
  campaignPlatform,
  campaignFormat,
  campaignId,
  handleEndProvisioning,
  handleCancel
}: CampaignDetailsProps) => {
  return (
    <>
      {campaignFormat === GoogleCampaignFormat.SEARCH ? (
        <GoogleSearchDetails
          name={name}
          campaignId={campaignId}
          handleEndProvisioning={handleEndProvisioning}
        />
      ) : campaignFormat === GoogleCampaignFormat.DISPLAY ? (
        <GoogleDisplayDetails
          name={name}
          campaignId={campaignId}
          handleEndProvisioning={handleEndProvisioning}
        />
      ) : campaignFormat === GoogleCampaignFormat.VIDEO ? (
        <GoogleVideoDetails
          name={name}
          campaignId={campaignId}
          handleEndProvisioning={handleEndProvisioning}
        />
      ) : campaignFormat === GoogleCampaignFormat.PERF_MAX ? (
        <GooglePerfMaxDetails
          name={name}
          campaignId={campaignId}
          handleEndProvisioning={handleEndProvisioning} 
        />
      ) : campaignFormat === MetaCampaignFormat.TEXT ? (
        <FacebookDetails
          name={name}
          campaignId={campaignId}
          handleEndProvisioning={handleEndProvisioning} 
        />
      ) : campaignFormat === MetaCampaignFormat.INSTA ? (
        <InstagramDetails
          name={name}
          campaignId={campaignId}
          handleEndProvisioning={handleEndProvisioning} 
        />
      ) : campaignFormat === MetaCampaignFormat.MESSENGER ? (
        <MessengerDetails
          name={name}
          campaignId={campaignId}
          handleEndProvisioning={handleEndProvisioning} 
        />
      ) : (
        <div>Coming soon</div>
      )}

      <Button
        onClick={handleCancel}
        small
        style={{ marginTop: Margin.m7 }}
        type='secondary'
      >
        Annuler
      </Button>
    </>
  )
}

export default CampaignDetails