export enum CampaignStatus {
  DRAFT = "draft",
  READY = "ready"
}

export enum BalanceSheetStatus {
  WAITING_FOR_PROVISIONING = "WAITING_FOR_PROVISIONING",
  PROVISIONING_IN_PROGRESS = "PROVISIONING_IN_PROGRESS",
  READY = "ready"
}

export const checkIfCampaignStatus = (value: string): value is CampaignStatus => {
  return Object.values(CampaignStatus).includes(value as CampaignStatus)
}

export const checkIfBalanceSheetStatus = (value: string): value is BalanceSheetStatus => {
  return Object.values(BalanceSheetStatus).includes(value as BalanceSheetStatus)
}