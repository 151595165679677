import React from 'react'

import { TipIcon } from '../../../../components/Icons'
import { TipContainer, tipIconStyle } from './style'

const Tip = ({children}) => {
  return (
    <TipContainer>
      <TipIcon style={tipIconStyle}/>
      {children}
    </TipContainer>
  )
}

export default Tip