import { CSSProperties } from "react"
import styled from "@emotion/styled"

import { Margin, Colors } from "../../../../constants"
import { AlignEnum } from "../../../../types/common"

export const TableHeaderItem = styled.div<{
  width: string
  align?: AlignEnum
}>`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  color: ${Colors.darkBlue};
  padding: 0 25px 0 20px;
  box-sizing: border-box;
  width: ${(props) => props.width};
  justify-content: ${({ align }) =>
    align === AlignEnum.LEFT
      ? 'flex-start'
      : align === AlignEnum.CENTER
      ? 'center'
      : 'flex-end'};
`

export const sortIconStyle: CSSProperties = {
  cursor: 'pointer'
}