import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { GlobalReportingOutputType } from "../../types"
import { checkIfGlobalReportingAPI } from "../../types/checkers"
import { formatGlobalReportingAPIType } from "../../types/formatters"

type GetBalanceSheetGlobalReportingInputType = {
  balanceSheetId: string
}

export const getBalanceSheetGlobalReporting = async ({
  balanceSheetId
}: GetBalanceSheetGlobalReportingInputType): Promise<GlobalReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/global`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfGlobalReportingAPI(response.data)) {
        return formatGlobalReportingAPIType(response.data)
      }
    }

    return null
  } catch (_) {
    return null
  }
}
