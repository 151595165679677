import { GlobalReportingAPIType, GlobalReportingOutputType } from "."

export const formatGlobalReportingAPIType = (dataArray: GlobalReportingAPIType): 
  GlobalReportingOutputType => {
  const clicksArr: { value: number, day: string}[] = []
  const impressionsArr: { value: number, day: string}[] = []
  const co2Arr: { value: number, day: string}[] = []
  const costArr: { value: number, day: string}[] = []

  for (let i = 0; i < dataArray.length; i++) {
    const data = dataArray[i]
    clicksArr.push({ value: data.clicks, day: data.date})
    impressionsArr.push({ value: data.impressions, day: data.date})
    co2Arr.push({ value: data.co2, day: data.date})
    costArr.push({ value: data.cost, day: data.date})
  }

  return {
    clicks: clicksArr,
    impressions: impressionsArr,
    co2: co2Arr,
    cost: costArr
  }
}