import styled from "@emotion/styled"

import { Colors, Margin } from "../../constants"

export const StyledWrapper = styled.div`
  border: solid ${Colors.greyBorder} 2px;
  border-radius: 4px;
  background-color: ${Colors.grey};
  padding: ${Margin.m9} ${Margin.m9};
  margin-top: ${Margin.m9};
  display: flex;
  align-items: center;
  gap: ${Margin.m8};
`
