import React from 'react'

import RequiredAuthRoute from './hoc/RequiredAuthRoute'

import {
  Home,
  Login,
  PageNotFound,
  Register,
  Reporting
} from './pages'

import { endpointByPage } from './types/page'
import BalanceSheet from './pages/BalanceSheet'
import ProvisioningPage from './pages/ProvisioningPage'
import { UserPermissionsEnum } from './types/permissions'
import InvoicesPage from './pages/Invoices'
import EmailVerification from './pages/Register/EmailVerification'

const routes = [
  {
    path: endpointByPage.HOME,
    component: (
      <RequiredAuthRoute>
        <Home />
      </RequiredAuthRoute>
    )
  },
  {
    path: endpointByPage.REPORTING,
    component: (
      <RequiredAuthRoute allowedUserType={[UserPermissionsEnum.DEFAULT, UserPermissionsEnum.SELF_PROVISIONING]}>
        <Reporting />
      </RequiredAuthRoute>
    )
  },
  {
    path: endpointByPage.BALANCE_SHEET,
    component: (
      <RequiredAuthRoute allowedUserType={[UserPermissionsEnum.DEFAULT, UserPermissionsEnum.SELF_PROVISIONING]}>
        <BalanceSheet />
      </RequiredAuthRoute>
    )
  },
  {
    path: endpointByPage.REGISTER,
    component: (
      <Register />
    )
  },
  {
    path: endpointByPage.EMAIL_VERIFICATION,
    component: (
      <EmailVerification />
    )
  },
  {
    path: endpointByPage.LOGIN,
    component: (
      <Login />
    )
  },
  {
    path: endpointByPage.PROVISIONING,
    component: (
      <RequiredAuthRoute allowedUserType={[UserPermissionsEnum.PROVISIONING, UserPermissionsEnum.SELF_PROVISIONING]}>
        <ProvisioningPage />
      </RequiredAuthRoute>
    )
  },
  {
    path: endpointByPage.INVOICES,
    component: (
      <RequiredAuthRoute allowedUserType={[UserPermissionsEnum.DEFAULT, UserPermissionsEnum.SELF_PROVISIONING]}>
        <InvoicesPage />
      </RequiredAuthRoute>
    )
  },
  {
    path: '*',
    component: (
      <PageNotFound />
    )
  }
]

export default routes