import { DevicesReportingOutputType } from "../../types"

export const devicesReportingMock: DevicesReportingOutputType = {
  smartphone: {
    clicks: 100,
    impressions: 1000,
    cost: 1000,
    co2: 140
  },
  tablet: {
    clicks: 50,
    impressions: 500,
    cost: 500,
    co2: 20
  },
  desktop: {
    clicks: 30,
    impressions: 300,
    cost: 300,
    co2: 120
  },
  tv: {
    clicks: 20,
    impressions: 200,
    cost: 200,
    co2: 20
  }
}