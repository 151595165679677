import React from 'react'

import H3 from '../../Text/Headlines/H3'
import { DownloadIcon } from '../../Icons'
import { generateCsv } from '../../../helpers/generateCsv'
import { ColumnItem, ElementItem } from '../types'
import { DownloadContainer, downloadIcon, TitleWrapper } from './styles'

type TableTitleProps = {
  title: string
  download?: boolean
  columns: ColumnItem[]
  elements: ElementItem[]
}

const TableTitle = ({title, download, elements, columns}: TableTitleProps) => {
  return (
    <TitleWrapper>
      <H3>
        {title}
      </H3>
      {download && (
        <DownloadContainer
          onClick={() => {
            generateCsv(
              elements,
              columns.map((column) => column.name),
              'table_data'
            )
          }}
        >
          <DownloadIcon style={downloadIcon} />
        </DownloadContainer>
      )}
    </TitleWrapper>
  )
}

export default TableTitle