import React from "react"

import { ButtonContainer, ChoiceButtonInput, ChoiceButtonLabel } from "./style"

type ChoiceItemProps = {
  icon: React.JSX.Element | null
  label: string
  isActive: boolean
  name: string
  handleSelect: (val: string) => void
  type: 'radio' | 'checkbox'
}

const ChoiceItem = ({
  icon,
  label,
  isActive,
  name,
  handleSelect,
  type
}: ChoiceItemProps) => {
  return (
    <ButtonContainer key={label} active={isActive}>
    <ChoiceButtonInput
      type={type}
      id={label}
      value={label}
      name={name}
      onChange={() => handleSelect(label)}
    />
    <ChoiceButtonLabel htmlFor={label}>
      {icon && <>{icon}</>}
      {label}
    </ChoiceButtonLabel>
  </ButtonContainer>
  )
}

export default ChoiceItem
