import React from 'react'
import { Container, createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import routes from './routes'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((element, index) => {
          return (
            <Route
              path={element.path}
              element={element.component}
              key={element.path + index}
            />
          )
        })}
      </Routes>
    </BrowserRouter>
  )
}

const root = document.getElementById('root')

createRoot(root as Container).render(<React.StrictMode><App /></React.StrictMode>)