export enum ReportingApiSectionEnum {
  impact = 'impact',
  global = 'global',
  devices = 'devices',
  locations = 'locations',
  highlights = 'highlights',
  download = 'download'
}

export const checkIfReportingApiSectionEnum = (
  value: any
): value is ReportingApiSectionEnum => {
  return (
    value &&
    Object.values(ReportingApiSectionEnum).includes(
      value as ReportingApiSectionEnum
    )
  )
}

export type ReportingBySectionOutputType =
  | DevicesReportingOutputType
  | GlobalReportingOutputType
  | ImpactReportingOutputType
  | HighlightsReportingOutputType
  | DownloadReportingOutputType
  | LocationsReportingOutputType

export type LocationsReportingOutputType = {
  location: string
  clicks: number
  impressions: number
}[]

export type DevicesReportingRowType = {
  clicks: number
  impressions: number
  cost: number
  co2: number
}

export type DevicesReportingOutputType = {
  smartphone: DevicesReportingRowType
  tablet: DevicesReportingRowType
  desktop: DevicesReportingRowType
  tv: DevicesReportingRowType
}

export type ImpactReportingOutputType = {
  co2PerDay: {
    date: string
    co2: number
  }[],
  co2PerDevice: {
    smartphone: number
    tablet: number
    desktop: number
    tv: number
  },
  co2: number
}

export type GlobalReportingRowType = {
  day: string
  value: number
}

export type GlobalReportingAPIType = {
  date: string
  clicks: number
  impressions: number
  cost: number
  co2: number
}[]

export type GlobalReportingOutputType = {
  clicks: GlobalReportingRowType[]
  impressions: GlobalReportingRowType[]
  cost: GlobalReportingRowType[]
  co2: GlobalReportingRowType[]
}

export type HighlightsReportingOutputType = {
  clicks: number
  impressions: number
  co2: number
  energy: number
  cost: number
  impact: number
  co2PerEuro: number
  cpc: number
  ctr: number
}

export type DownloadReportingOutputType = {
  date: string
  clicks: number
  impressions: number
  cost: number
  co2: number
  impact: number
}[]
