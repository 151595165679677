import React, { CSSProperties } from 'react'

import { StyledSubheadline } from './style'

type SubheadlineProps = {
  children: string
  style?: CSSProperties
}

const Subheadline = ({ children, style }: SubheadlineProps) => {
  return <StyledSubheadline style={style}>{children}</StyledSubheadline>
}

export default Subheadline
