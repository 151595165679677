import React from 'react'

import { Colors, FontSize, Margin } from '../../../constants'
import { P } from '../../Text'

type ErrorMessageProps = {
  message: string
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <P
      style={{
        color: Colors.red,
        margin: `${Margin.m1} 0 ${Margin.m1} ${Margin.m3}`,
        fontSize: FontSize.sm,
      }}
    >
      {message}
    </P>
  )
}

export default ErrorMessage
