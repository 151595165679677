import { backendURL } from "../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../helpers/axios/get"
import { ErrorType } from "../../../../types/error"
import { checkIfCompaniesForProvisioningUserApi, CompaniesForProvisioningUser } from "./types"

export const getCompaniesApi = async (): Promise<CompaniesForProvisioningUser | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/companies`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfCompaniesForProvisioningUserApi(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors du chargement de vos entreprises.'
  }
}