import React, { CSSProperties, ReactNode } from 'react'

import { StyledH1 } from './style'

type H1Props = {
  children: ReactNode
  style?: CSSProperties
}

const H1 = ({ children, style }: H1Props) => {
  return <StyledH1 style={style}>{children}</StyledH1>
}

export default H1
