import { PieChartDataItem } from "../../../../../../components/Chart/PieChart/types"
import { ElementItem } from "../../../../../../components/Table/types"
import { Colors } from "../../../../../../constants"
import { PlatformsReportingOutputType } from "../../../../../../services/api/reporting/types"

export const formatPlatformsData = (reportingData: PlatformsReportingOutputType) => {
  const co2PieChartData: PieChartDataItem[] = [
    ...reportingData.google ? [
      {
        label: "Google",
        value: reportingData.google.co2,
        color: Colors.redGoogle
      }
    ] : [],
    ...reportingData.meta ? [
      {
        label: "Meta",
        value: reportingData.meta.co2,
        color: Colors.blueMeta
      }
    ] : []
  ]

  const impressionsPieChartData: PieChartDataItem[] = [
    ...reportingData.google ? [
      {
        label: "Google",
        value: reportingData.google.impressions,
        color: Colors.redGoogle
      }
    ] : [],
    ...reportingData.meta ? [
      {
        label: "Meta",
        value: reportingData.meta.impressions,
        color: Colors.blueMeta
      }
    ] : []
  ]

  const clicksPieChartData: PieChartDataItem[] = [
    ...reportingData.google ? [
      {
        label: "Google",
        value: reportingData.google.clicks,
        color: Colors.redGoogle
      }
    ] : [],
    ...reportingData.meta ? [
      {
        label: "Meta",
        value: reportingData.meta.clicks,
        color: Colors.blueMeta
      }
    ] : []
  ]

  const costPieChartData: PieChartDataItem[] = [
    ...reportingData.google ? [
      {
        label: "Google",
        value: reportingData.google.cost,
        color: Colors.redGoogle
      }
    ] : [],
    ...reportingData.meta ? [
      {
        label: "Meta",
        value: reportingData.meta.cost,
        color: Colors.blueMeta
      }
    ] : []
  ]

  const tableElements: ElementItem[] = [
    ...reportingData.google ? [{
      platform: "Google",
      co2: reportingData.google.co2,
      clicks: reportingData.google.clicks,
      impressions: reportingData.google.impressions,
      cost: reportingData.google.cost
    }] : [],
    ...reportingData.meta ? [{
      platform: "Meta",
      co2: reportingData.meta.co2,
      clicks: reportingData.meta.clicks,
      impressions: reportingData.meta.impressions,
      cost: reportingData.meta.cost
    }] : []
  ]

  return {
    co2PieChartData,
    impressionsPieChartData,
    clicksPieChartData,
    costPieChartData,
    tableElements
  }
}
