import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../contexts/AppContext'
import { checkCookies, checkIfPermissions, clearAllCookies, getCookie } from '../helpers/cookie'
import { logoutApi } from '../services/api/auth/logout'
import UnauthenticatedLoadingPage from '../pages/LoadingPage/UnauthenticatedLoadingPage'
import { UserPermissionsEnum } from '../types/permissions'

const RequiredAuthRoute = ({
  children,
  allowedUserType
}: {
  children: ReactNode,
  allowedUserType?: UserPermissionsEnum
}) => {
  const navigate = useNavigate()
  const [companyName, setCompanyName] = useState<string | null>(null)
  const [userType, setUserType] = useState<UserPermissionsEnum | null>(null)

  // TODO review this
  useEffect(() => {
    ; (async function () {
      if (userType == null) {
        const companyName = getCookie('companyName')
        const permissions = getCookie('permissions')

        const areCookiesValid = checkCookies()

        if (checkIfPermissions(permissions) && areCookiesValid) {
          setCompanyName(companyName ?? null)
          setUserType(permissions)
        } else {
          await handleLogout()
        }
      }
    })()
  }, [])

  const handleLogout = async () => {
    clearAllCookies()
    setCompanyName(null)
    setUserType(null)
    await logoutApi()
    navigate('/login')
  }

  if (userType == null) {
    return <UnauthenticatedLoadingPage />
  } else if (allowedUserType !== undefined && allowedUserType !== userType) {
    navigate('/')
    return
  }

  return (
    <AppContext.Provider
      value={{
        companyName,
        setCompanyName,
        userType,
        setUserType,
        handleLogout
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default RequiredAuthRoute
