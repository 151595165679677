import React, { useEffect, useState } from 'react'

import PageTemplate from '../../templates/Page'
import BalanceSheetSelection from './BalanceSheetSelection'
import { getCompaniesApi } from '../../services/api/companies/get/getList'
import { getBalanceSheetsPerCompanyApi } from '../../services/api/balanceSheets/get/getList'
import { checkIfErrorType } from '../../types/error'
import ErrorPage from '../ErrorPage'
import LoadingPage from '../LoadingPage'
import { getCampaignsPerBalanceSheetApi } from '../../services/api/campaigns/get/get'
import { CampaignPerBalanceSheet, CampaignsPerBalanceSheet, GetCampaignsApiOutput } from '../../services/api/campaigns/get/types'
import { linkCampaignToBalanceSheetCampaign } from '../../services/api/campaigns/create/linkCampaignToBalanceSheetCampaign'
import { unlinkCampaignFromBalanceSheet } from '../../services/api/balanceSheets/delete/unlinkCampaignFromBalanceSheet'
import AddCampaignSection from './AddCampaignSection'
import CampaignLink from './AddCampaignSection/CampaignLink'

const ProvisioningPage = () => {
  const [companies, setCompanies] = useState<{id: string, name: string}[] | null>(null)
  const [balanceSheets, setBalanceSheets] = useState<{id: string, name: string}[] | null>(null)
  const [campaigns, setCampaigns] = useState<CampaignsPerBalanceSheet | null>(null)
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null)
  const [selectedBalanceSheet, setSelectedBalanceSheet] = useState<string | null>(null)
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [displayAddSection, setDisplayAddSection] = useState(false)
  const [displayLinkSection, setDisplayLinkSection] = useState(false)
  const [draftCampaign, setDraftCampaign] = useState<CampaignPerBalanceSheet| null>(null)

  useEffect(() => {
    getCompanies()
  }, [])

  const getCompanies = async () => {
    const companiesFromApi = await getCompaniesApi()

    if (checkIfErrorType(companiesFromApi)) {
      setFetchError("")
    } else {
      setCompanies(companiesFromApi)
    }
  }

  const getBalanceSheets = async (companyId: string) => {
    const balanceSheetsFromApi = await getBalanceSheetsPerCompanyApi({ company: companyId })

    if (checkIfErrorType(balanceSheetsFromApi)) {
      setFetchError("")
    } else {
      setBalanceSheets(balanceSheetsFromApi)
    }
  }

  const getCampaigns = async (balanceSheetId: string) => {
    const campaignsFromApi = await getCampaignsPerBalanceSheetApi({ balanceSheetId: balanceSheetId })

    if (checkIfErrorType(campaignsFromApi)) {
      setFetchError("")
    } else {
      setCampaigns(campaignsFromApi)
    }
  }

  const handleChangeSelectedCompany = async (value: string | null) => {
    if(value != null) {
      const companyId = companies?.find(c => c.name === value)?.id
      if (companyId) {
        await getBalanceSheets(companyId)
        setSelectedCompany(value)
        setSelectedBalanceSheet(null)
        setCampaigns(null)
      }
    }
  }

  const handleChangeSelectedBalanceSheet = async (value: string | null) => {
    if (value != null && balanceSheets != null) {
      const balanceSheetId = balanceSheets.find(bs => bs.name === value)?.id
      if (balanceSheetId) {
        await getCampaigns(balanceSheetId)
        setSelectedBalanceSheet(value)
      }
    }
  }

  const handleAddCampaign = () => {
    setDisplayAddSection(true)
    setDisplayLinkSection(false)
    setDraftCampaign(null)
  }

  const handleLinkCampaign = () => {
    setDisplayLinkSection(true)
    setDisplayAddSection(false)
  }

  const handleEditCampaign = (draftCampaign: CampaignPerBalanceSheet) => {
    setDisplayAddSection(true)
    setDraftCampaign(draftCampaign)
  }

  const handleEndProvisioning = async () => {
    const balanceSheetId = selectedBalanceSheet != null 
    ? balanceSheets?.find(bs => bs.name === selectedBalanceSheet)?.id ?? null
    : null

    balanceSheetId != null && await getCampaigns(balanceSheetId)
    setDisplayAddSection(false)
    setDisplayLinkSection(false)
  }

  const handleCancelAdd = () => {
    setDisplayAddSection(false)
    setDisplayLinkSection(false)
  }

  const handleLinkCampaignToBalanceSheet = async (campaigns: GetCampaignsApiOutput) => {
    const campaignIds = campaigns.map(campaign => campaign.id)

    if (balanceSheetId) {
      const updatedCampaigns = await Promise.all(
        campaignIds.map(async (campaignId) => (
          await linkCampaignToBalanceSheetCampaign({ campaignId, balanceSheetId })
        ))
      )
  
      const hasError = updatedCampaigns.some(response => checkIfErrorType(response))
      if (!hasError) {
        handleEndProvisioning()
        setDisplayLinkSection(false)
      }
    }
  }

  const handleDelete = async (campaignName: string) => {
    const campaignId = campaigns?.find(campaign => campaign.name === campaignName)?.id

    const balanceSheetId = selectedBalanceSheet != null 
    ? balanceSheets?.find(bs => bs.name === selectedBalanceSheet)?.id ?? null
    : null

    if(campaignId && balanceSheetId) {
      const unlikedCampaign = await unlinkCampaignFromBalanceSheet({campaignId, balanceSheetId})

      if (!checkIfErrorType(unlikedCampaign)) {
        handleEndProvisioning()
      }
    }
  }
 
  const companyNames = companies != null ? companies.map(c => c.name) : []
  const balanceSheetNames = balanceSheets != null ? balanceSheets.map(b => b.name) : []

  if (fetchError) {
    return <ErrorPage message={fetchError} />
  }

  if (companies === null) {
    return <LoadingPage />
  }

  const companyProps = {
    names: companyNames,
    selected: selectedCompany
  }
  const balanceSheetProps = {
    names: balanceSheetNames,
    selected: selectedBalanceSheet
  }

  const companyId = selectedCompany != null 
    ? companies?.find(c => c.name === selectedCompany)?.id ?? null
    : null

  const balanceSheetId = selectedBalanceSheet != null 
    ? balanceSheets?.find(bs => bs.name === selectedBalanceSheet)?.id ?? null
    : null

  return (
    <PageTemplate title='Provisioning'>
      {!displayAddSection && !displayLinkSection && <BalanceSheetSelection
        company={companyProps}
        balanceSheet={balanceSheetProps}
        campaigns={campaigns}
        balanceSheetId={balanceSheetId}
        handleAddCampaign={handleAddCampaign}
        handleEditCampaign={handleEditCampaign}
        handleCompanyChange={handleChangeSelectedCompany}
        handleBalanceSheetChange={handleChangeSelectedBalanceSheet}
        handleLinkCampaign={handleLinkCampaign}
        handleDelete={handleDelete}
      />}
      {displayAddSection && !displayLinkSection && balanceSheetId != null && companyId != null &&
        <AddCampaignSection 
          companyId={companyId} 
          balanceSheetId={balanceSheetId} 
          handleEndProvisioning={handleEndProvisioning} 
          draftCampaign={draftCampaign}
          handleCancel={handleCancelAdd}
        />
      }
      {displayLinkSection && !displayAddSection &&
        <CampaignLink 
          companyId={companyId} 
          balanceSheetCampaigns={campaigns} 
          handleLinkCampaignToBalanceSheet={handleLinkCampaignToBalanceSheet} 
          handleCancel={handleCancelAdd} 
          balanceSheetId={balanceSheetId}
        />
      }
    </PageTemplate>
  )
}

export default ProvisioningPage
