import { CampaignsPerBalanceSheet, checkIfCampaignsPerBalanceSheetApi, checkIfGetCampaignsApiOutput, GetCampaignsApiOutput } from "./types"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../helpers/axios/get"
import { backendURL } from "../../../../constants"
import { ErrorType } from "../../../../types/error"

type GetCampaignsApiInput = {
  startDate?: string | null
  endDate?: string | null
}

export const getCampaignsApi = async ({ startDate, endDate }: GetCampaignsApiInput): Promise<GetCampaignsApiOutput | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/campaigns${
      startDate ? `?startDate=${startDate}` : ''
    }${
      endDate
        ? startDate
          ? `&endDate=${endDate}`
          : `?endDate=${endDate}`
        : ''
    }`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfGetCampaignsApiOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Error while fetching campaigns'
  }
}

export interface GetCampaignsPerBalanceSheetApiInput {
  balanceSheetId: string
}

export const getCampaignsPerBalanceSheetApi = async ({ balanceSheetId }: GetCampaignsPerBalanceSheetApiInput): 
  Promise<CampaignsPerBalanceSheet | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/balance-sheets/${balanceSheetId}/campaigns`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfCampaignsPerBalanceSheetApi(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors du chargement de vos campagnes.'
  }
}

export const getCampaignsByCompanyIdApi = async (companyId: string): 
  Promise<GetCampaignsApiOutput | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/companies/${companyId}/campaigns`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfGetCampaignsApiOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Error while fetching campaigns'
  }
}