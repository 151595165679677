import React, { ReactNode } from 'react'

import { Container } from './style'

type PropsType = {
  elements: ReactNode[]
  backgroundColor?: string
  width?: string
  style?: React.CSSProperties
}

const Box = ({ elements, backgroundColor, width, style }: PropsType) => {
  return (
    <Container style={style} width={width} backgroundColor={backgroundColor}>
      {elements}
    </Container>
  )
}

export default Box
