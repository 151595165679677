import React from 'react'

import { P } from '../../Text'
import { DivContainer, descriptionStyle, labelStyle } from './styles'

type CheckboxLabelProps = {
  label: string | JSX.Element
  description?: string
}

const CheckboxLabel = ({ label, description }: CheckboxLabelProps) => {
  return (
    <DivContainer>
      {description ? (
        <>
          <P style={labelStyle} bold>
            {label}
          </P>
          <P style={descriptionStyle} small>
            {description}
          </P>
        </>
      ) : (
        <P style={labelStyle}>{label}</P>
      )}
    </DivContainer>
  )
}

export default CheckboxLabel
