import { backendURL } from "../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../helpers/axios/get"
import { ErrorType } from "../../../../types/error"
import { checkIsDownloadInvoiceApiOutput, DownloadInvoiceApiOutput } from "./types"

export const downloadInvoiceApi = async (invoiceId: string): Promise<DownloadInvoiceApiOutput | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/invoices/${invoiceId}/download`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIsDownloadInvoiceApiOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors du téléchargement de votre facture.'
  }
}
