import React, { CSSProperties, ReactNode } from 'react'
import { StyledLink } from './style'

type LinkProps = {
  children: ReactNode
  onClick: () => void
  style?: CSSProperties
}

const Link = ({ children, onClick, style }: LinkProps) => {
  return (
    <StyledLink style={style} onClick={onClick}>{children}</StyledLink>
  )
}

export default Link