import styled from '@emotion/styled'

import Colors from '../../constants/colors'
import Margin from '../../constants/margin'
import FontSize from '../../constants/fontSize'
import FontWeight from '../../constants/fontWeight'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkBlue};
  margin-top: ${Margin.m4};
  margin-bottom: ${Margin.m4};
`

export const DivNumbers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${Margin.m3};
`

export const Number = styled.div<{ isSelected: boolean }>`
  margin: 0 ${Margin.m3};
  font-size: ${FontSize.base};
  cursor: pointer;
  font-weight: ${({ isSelected }) =>
    isSelected ? FontWeight.YETIC_BOLD : FontWeight.YETIC_NORMAL};
  text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};
`

export const DivArrows = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  margin: 0 ${Margin.m2};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
`
