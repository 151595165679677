import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import RegisterView from './View'
import { RegisterFieldsEnum, RegisterStepEnum } from './types'
import {
  checkIfRegisterOutputSuccess,
  RegisterApiOutput,
} from '../../services/api/auth/register/types'
import { checkIfErrorType } from '../../types/error'
import { checkIfCheckUserExistsApiSuccessOutput, CheckUserExistsApiOutput } from '../../services/api/user/types'
import CheckEmailRegisterFormInput from './Form/Inputs/CheckEmailInput'
import NewUserRegisterFormInputs from './Form/Inputs/NewUserInputs'
import { getRegisterStep } from './helpers/step'
import { getFormErrors } from './helpers/formErrors'
import { handleRegisterSubmissions } from './helpers/submission'
import RegisterCompanyNameInput from './Form/Inputs/CompanyNameInput'
import RegisterPasswordInput from './Form/Inputs/PasswordUserInput'
import { getFormDescription } from './helpers/description'

const Register = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checkUserResult, setCheckUserResult] = useState<CheckUserExistsApiOutput | null>(null)
  const [registerResult, setRegisterResult] = useState<RegisterApiOutput | null>(null)
  const [hasCompanyNameBeenEntered, setHasCompanyNameBeenEntered] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [registerResult, checkUserResult])

  const navigate = useNavigate()

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: RegisterFieldsEnum
  ) => {
    setHasBeenSubmitted(false)

    switch (field) {
      case RegisterFieldsEnum.FIRST_NAME:
        setFirstName(e.target.value)
        break

      case RegisterFieldsEnum.LAST_NAME:
        setLastName(e.target.value)
        break

      case RegisterFieldsEnum.PHONE:
        setPhone(e.target.value)
        break

      case RegisterFieldsEnum.EMAIL:
        setEmail(e.target.value)
        break

      case RegisterFieldsEnum.PASSWORD:
        setPassword(e.target.value)
        break

      case RegisterFieldsEnum.PASSWORD_CONFIRMATION:
        setPasswordConfirmation(e.target.value)
        break

      case RegisterFieldsEnum.COMPANY_NAME:
        setCompanyName(e.target.value)
        break
    }
  }

  const apiError =
    hasBeenSubmitted
      ? registerResult && checkIfErrorType(registerResult)
      ? registerResult.error
      : checkUserResult && checkIfErrorType(checkUserResult)
      ? checkUserResult.error
      : ''
      : ''

  const registerSuccessful = checkIfRegisterOutputSuccess(registerResult)
  const mustFillForm = checkIfCheckUserExistsApiSuccessOutput(checkUserResult)
  const userExists = mustFillForm && checkUserResult.found
  // TODO
  const registerStep = getRegisterStep(!!checkUserResult, userExists, hasCompanyNameBeenEntered)

  const handleGoToLogin = () => {
    navigate('/login')
  }

  const formErrors = getFormErrors({
    firstName,
    lastName,
    phone,
    email,
    password,
    passwordConfirmation,
    companyName,
    userExists
  })

  const getFormError = (field: RegisterFieldsEnum) => {
    if (!hasBeenSubmitted) {
      return ''
    }

    return formErrors[field]
  }

  const getFormInputs = () => {
    switch (registerStep) {
      case RegisterStepEnum.CHECK_EMAIL:
        return (
          <CheckEmailRegisterFormInput
            email={email}
            handleFieldChange={handleFieldChange}
            formError={getFormError(RegisterFieldsEnum.EMAIL)}
          />
        )

      case RegisterStepEnum.ENTER_PASSWORD:
        return (
          <>
            <RegisterCompanyNameInput
              companyName={companyName}
              handleFieldChange={(e) => handleFieldChange(e, RegisterFieldsEnum.COMPANY_NAME)}
              formError={getFormError(RegisterFieldsEnum.COMPANY_NAME)}
            />
            <RegisterPasswordInput
              password={password}
              handleFieldChange={(e) => handleFieldChange(e, RegisterFieldsEnum.PASSWORD)}
              formError={getFormError(RegisterFieldsEnum.PASSWORD)}
            />
          </>
        )

      case RegisterStepEnum.ENTER_COMPANY_NAME:
        return (
          <RegisterCompanyNameInput
            companyName={companyName}
            handleFieldChange={(e) => handleFieldChange(e, RegisterFieldsEnum.COMPANY_NAME)}
            formError={getFormError(RegisterFieldsEnum.COMPANY_NAME)}
          />
        )

      default:
        return (
          <NewUserRegisterFormInputs
            email={email}
            password={password}
            passwordConfirmation={passwordConfirmation}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            companyName={companyName}
            handleFieldChange={handleFieldChange}
            getFormError={getFormError}
          />
        )
    }
  }


  return (
    <RegisterView
      description={getFormDescription(registerStep)}
      handleSubmit={(e: React.FormEvent) => {
        e.preventDefault()
        handleRegisterSubmissions({
          email,
          password,
          companyName,
          firstName,
          lastName,
          phone,
          step: registerStep,
          checkUserResult,
          formErrors,
          setCheckUserResult,
          setHasBeenSubmitted,
          setLoading,
          setRegisterResult,
          setHasCompanyNameBeenEntered
        })
      }}
      apiError={apiError}
      registerSuccessful={registerSuccessful}
      handleGoToLogin={handleGoToLogin}
      loading={loading}
    >
      {getFormInputs()}
    </RegisterView>
  )
}

export default Register
