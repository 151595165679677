import React, { CSSProperties, ReactNode } from 'react'

import { StyledH3 } from './style'

type H3Props = {
  children: ReactNode
  style?: CSSProperties
}

const H3 = ({ children, style }: H3Props) => {
  return <StyledH3 style={style}>{children}</StyledH3>
}

export default H3
