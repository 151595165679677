import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { DevicesReportingOutputType } from "../../types"
import { checkIfDevicesReporting } from "../../types/checkers"
import { devicesReportingMock } from "../mock/devices"

type GetBalanceSheetDevicesReportingInput = {
  balanceSheetId: string
}

export const getBalanceSheetDevicesReporting = async ({
  balanceSheetId
}: GetBalanceSheetDevicesReportingInput): Promise<DevicesReportingOutputType | null> => {
  try {
    // const response = await axiosGet({
    //   url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/devices`
    // })

    const response = {
      type: 'success',
      data: devicesReportingMock
    }

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfDevicesReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}
