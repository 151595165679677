import React from 'react'

import { LineChartTooltipProps } from '../types'
import { LineChartTooltipItem, LineChartTooltipItemColor, LineChartTooltipItemDataContainer, LineChartTooltipItemLegend, LineChartTooltipItemName, LineChartTooltipItemPercentage, LineChartTooltipItemValue, LineChartTooltipTitle } from './Default/style'
import { Point } from '../lineChartDrawer'

const DefaultLineChartTooltip = <PointType extends Point>({
  chartId,
  data
}: LineChartTooltipProps<PointType>) => {
  return (
    <>
      <LineChartTooltipTitle
        id={`${chartId}-tooltip-title`}
      ></LineChartTooltipTitle>
      {data.map((d) => (
        <LineChartTooltipItem id={`${chartId}-tooltip-${d.name}`} key={d.name}>
          <LineChartTooltipItemLegend>
            <LineChartTooltipItemColor color={d.color} />
            <LineChartTooltipItemName>{d.name}</LineChartTooltipItemName>
          </LineChartTooltipItemLegend>
          <LineChartTooltipItemDataContainer>
            <LineChartTooltipItemPercentage
              className={`${chartId}-tooltip-${d.name}-percentage`}
            ></LineChartTooltipItemPercentage>
            <LineChartTooltipItemValue
              className={`${chartId}-tooltip-${d.name}-value`}
            ></LineChartTooltipItemValue>
          </LineChartTooltipItemDataContainer>
        </LineChartTooltipItem>
      ))}
    </>
  )
}

export default DefaultLineChartTooltip
