import { LocationsReportingOutputType } from "../../types"

export const locationReportingMock: LocationsReportingOutputType = [
  {
    location: 'Rouen, France',
    clicks: 100,
    impressions: 1000
  },
  {
    location: 'Paris, France',
    clicks: 123,
    impressions: 1622
  },
  {
    location: 'Le Havre, France',
    clicks: 77,
    impressions: 378
  }
]