import { checkEmailFormat, checkNameFormat, checkPasswordFormat, checkPhoneFormat } from "../../../helpers/checkers"
import { RegisterFieldsEnum, RegisterFormErrors } from "../types"

type FormErrorsInput = {
  email: string
  firstName: string
  lastName: string
  phone: string
  password: string
  passwordConfirmation: string
  companyName: string
  userExists: boolean
}

export const getFormErrors = ({
  email,
  firstName,
  lastName,
  phone,
  password,
  passwordConfirmation,
  companyName,
  userExists
}: FormErrorsInput): RegisterFormErrors => ({
  [RegisterFieldsEnum.EMAIL]: checkEmailFormat(email)
    ? ''
    : `Le format de l'adresse e-mail est invalide.`,
  [RegisterFieldsEnum.FIRST_NAME]: !userExists
    ? checkNameFormat(firstName)
      ? ''
      : 'Le nom doit contenir au moins deux caractères.'
    : '',
  [RegisterFieldsEnum.LAST_NAME]: !userExists
    ? checkNameFormat(lastName)
      ? ''
      : 'Le nom doit contenir au moins deux caractères.'
    : '',
  [RegisterFieldsEnum.PHONE]: !userExists
    ? checkPhoneFormat(phone)
      ? ''
      : 'Le format du numéro de téléphone est invalide.'
    : '',
  [RegisterFieldsEnum.PASSWORD]: checkPasswordFormat(password)
    ? ''
    : 'Le mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule et un nombre.',
  [RegisterFieldsEnum.PASSWORD_CONFIRMATION]: !userExists ?
    password === passwordConfirmation
      ? ''
      : 'Les mots de passe entrés ne correspondent pas.'
    : '',
  [RegisterFieldsEnum.COMPANY_NAME]: checkNameFormat(companyName)
    ? ''
    : 'Le nom de l\'entreprise doit contenir au moins deux caractères.'
})