import { CSSProperties } from "react"
import styled from "@emotion/styled"

import { Colors, FontSize, Margin } from "../../../../constants"

export const StepContainer = styled.div`
  width: 100%;
`

export const StepHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${Margin.m5};
  cursor: pointer;
`

export const StepCount = styled.div`
  width: 30px;  
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${Colors.darkBlue};
  border-radius: 50%;
  font-size: ${FontSize.base};
`

export const stepTitleStyle: CSSProperties = {
  flex: 1
}

export const topChevronStyle: CSSProperties = {
  transform: 'rotate(180deg)',
  transition: 'transform 300ms'
}

export const bottomChevronStyle: CSSProperties = {
  transform: 'rotate(0deg)',
  transition: 'transform 300ms'
}

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m4};
`

export const ListItem = styled.li`
  line-height: 1.5;
` 