import * as d3 from 'd3'

const drawBackgroundLines = (
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  yScales: d3.ScaleLinear<number, number, never>[],
  yAxisTicks: number[],
  widthWithoutMargin: number
) => {
  svg
    .selectAll('.y-axis-line')
    .data(yAxisTicks)
    .enter()
    .append('line')
    .attr('class', 'y-axis-line')
    .attr('x1', 0)
    .attr('x2', widthWithoutMargin)
    .attr('y1', (d) => yScales[0](d))
    .attr('y2', (d) => yScales[0](d))
    .attr('stroke', '#e8e4e3')
}

export default drawBackgroundLines
