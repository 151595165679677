import React, { CSSProperties, ReactNode } from 'react'

import { StyledLeftMenuItem, StyledLeftMenuItemContent } from './style'

type LeftMenuItemProps = {
  selected?: boolean
  logo: JSX.Element
  children: ReactNode
  onClick?: () => void
  style?: CSSProperties
  hoverColor?: string
  selectedColor?: string
}

const LeftMenuItem = ({ selected, children, logo, onClick, style, hoverColor, selectedColor }: LeftMenuItemProps) => {
  return (
    <StyledLeftMenuItem
      onClick={onClick}
      selected={selected}
      style={style}
      hoverColor={hoverColor}
      selectedColor={selectedColor}
    >
      <StyledLeftMenuItemContent>
        {logo}
        {children}
      </StyledLeftMenuItemContent>
    </StyledLeftMenuItem>
  )
}

export default LeftMenuItem