import { checkUpdateGooglePerfMaxCampaign, UpdateGooglePerfMaxCampaign } from "./types"
import { convertObjectToFormData } from "../../../../../helpers/convertObjectToFromData"
import { backendURL } from "../../../../../constants"
import { ErrorType } from "../../../../../types/error"
import { CampaignFormat, CampaignPlatform } from "../../../../../types/formatAndPlatform"
import { axiosPost, checkIsAxiosPostOutputSuccess } from "../../../../../helpers/axios/post"

type UpdateLogsCampaignApiInput = {
  campaignId: string
  platform: CampaignPlatform
  format: CampaignFormat
  csvFile: File
}

export const updateLogsCampaignApi = async ({
  campaignId,
  platform,
  format,
  csvFile
}: UpdateLogsCampaignApiInput): Promise<UpdateGooglePerfMaxCampaign | ErrorType> => {
  const formData = convertObjectToFormData({
    platform: platform.toLocaleLowerCase(),
    format: format.toLocaleLowerCase(),
    file: csvFile
  })

  const response = await axiosPost({
    url: `${backendURL}/campaigns/${campaignId}/logs`,
    contentType: 'multipart/form-data',
    payload: formData
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkUpdateGooglePerfMaxCampaign(response.data)) {
      return response.data
    }
  }
  

  return {
    error: 'Une erreur est survenue lors de l upload des logs.'
  }
}