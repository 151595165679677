import {
  LineChartData,
  Point as LineChartPoint
} from '../../../../../../components/Chart/LineChart/lineChartDrawer'
import { formatDateToFrench } from '../../../../../../helpers/date'
import { GlobalReportingOutputType } from '../../../../../../services/api/reporting/types'


type FormatClickImpressionCostDataOutput = {
  kpi: LineChartData<LineChartPoint>
  tableData: {
    id: string
    day: string
    clicks: number | string
    impressions: number | string
    co2: number | string
    cost: number | string
  }[]
}

export const formatClickImpressionCostData = (
  data: GlobalReportingOutputType
): FormatClickImpressionCostDataOutput => {
  const totalClicks = data.clicks.reduce(
    (acc, click) => acc + click.value,
    0
  )
  const totalImpressions = data.impressions.reduce(
    (acc, impression) => acc + impression.value,
    0
  )
  const globalCTR =
    totalImpressions > 0 ? (totalClicks / totalImpressions) * 100 : 0

  const kpi: LineChartData<LineChartPoint> = [
    {
      name: 'Clics',
      color: 'blue',
      values: data.clicks.map((click) => ({
        time: new Date(click.day),
        value: click.value
      })),
      caption: 'nombre de clics',
      isAugmentationGood: true
    },
    {
      name: 'Impressions',
      color: 'deeppink',
      values: data.impressions.map((impression) => ({
        time: new Date(impression.day),
        value: impression.value
      })),
      caption: "nombre d'impressions",
      isAugmentationGood: true
    },
    {
      name: 'CO2',
      color: 'green',
      unit: ' g',
      values: data.co2.map((ctr) => ({
        time: new Date(ctr.day),
        value: ctr.value
      })),
      caption: "co2 émis",
      isAugmentationGood: true
    },
    {
      name: 'Coût',
      color: 'orange',
      unit: '€',
      values: data.cost.map((cost) => ({
        time: new Date(cost.day),
        value: cost.value
      })),
      caption: 'coût',
      isAugmentationGood: false
    }
  ]

  const days = new Set([
    ...data.clicks.map((click) => click.day),
    ...data.impressions.map((impression) => impression.day),
    ...data.co2.map((co2) => co2.day),
    ...data.cost.map((cost) => cost.day)
  ])

  const tableData = Array.from(days).map((day) => {
    const clicks = data.clicks.find((click) => click.day === day)
    const impressions = data.impressions.find(
      (impression) => impression.day === day
    )
    const co2 = data.co2.find((co2) => co2.day === day)
    const cost = data.cost.find((cost) => cost.day === day)

    return {
      id: day,
      day: formatDateToFrench(new Date(day), true),
      clicks: clicks !== undefined ? clicks.value : '-',
      impressions: impressions !== undefined ? impressions.value : '-',
      co2: co2 !== undefined ? co2.value : '-',
      cost: cost !== undefined ? cost.value : '-'
    }
  })

  return { kpi, tableData }
}
