import React from "react"

import { P } from "../../Text"
import { Margin } from "../../../constants"

type ChoiceItemsTitleProps = { title: string }

const ChoiceItemsTitle = ({ title }: ChoiceItemsTitleProps) => {
  return <P style={{ marginBottom: Margin.m3 }}>{title}</P>
}

export default ChoiceItemsTitle
