import { checkIfDate } from "../../../../types/nativeTypes"
import { ApiPlatformEnum, BalanceSheetApiStatusEnum, checkIsApiPlatform, checkIsBalanceSheetApiStatus } from "../../../../constants/api"

export type GetBalanceSheetsSort = 'createdAt' | 'startDate' | 'name'

export type GetBalanceSheetApiOutput = {
  id: string
  name: string
  startDate: string
  endDate: string
  status: BalanceSheetApiStatusEnum
  createdAt: string
  platforms: ApiPlatformEnum[]
  campaignIds: string[]
}

export type GetBalanceSheetsApiOutput = GetBalanceSheetApiOutput[]

export const checkIfGetBalanceSheetsApiOutput = (value: any): value is GetBalanceSheetsApiOutput => {
  return Array.isArray(value)
    && value.every((item) =>
      item && item.id && typeof item.id === 'string'
      && item.name && typeof item.name === 'string'
      && item.startDate && checkIfDate(item.startDate)
      && item.endDate && checkIfDate(item.endDate)
      && item.status && typeof item.status === 'string'
      && checkIsBalanceSheetApiStatus(item.status)
      && item.createdAt && checkIfDate(item.createdAt)
      && Array.isArray(item.platforms) && item.platforms.every((platform: any) => typeof platform === 'string' && checkIsApiPlatform(platform))
      && Array.isArray(item.campaignIds) && item.campaignIds.every((campaignId: any) => typeof campaignId === 'string')
  )
}

export type BalanceSheetPerCompany = {
  name: string,
  id: string
}

export type BalanceSheetsPerCompany = BalanceSheetPerCompany[]

export const checkIfBalanceSheetPerCompanyApi = (value: any): value is BalanceSheetsPerCompany => {
  return Array.isArray(value)
    && value.every((item) =>
      item && item.id && typeof item.id === 'string'
      && item.name && typeof item.name === 'string'
  )
}

export type GetOneBalanceSheetApiOutput = {
  id: string
  name: string
  startDate: string
  endDate: string
  status: BalanceSheetApiStatusEnum
  createdAt: string
}


export const checkIfGetOneBalanceSheetApiOutput = (value: any): value is GetOneBalanceSheetApiOutput => {
  return value && value.id && typeof value.id === 'string'
  && value.name && typeof value.name === 'string'
  && value.startDate && checkIfDate(value.startDate)
  && value.endDate && checkIfDate(value.endDate)
  && value.status && typeof value.status === 'string'
  && checkIsBalanceSheetApiStatus(value.status)
  && value.createdAt && checkIfDate(value.createdAt)
}