export function formatDateToFrench(date: Date, short?: boolean): string {
  const months = short
    ? [
        'janv.',
        'févr.',
        'mars',
        'avril',
        'mai',
        'juin',
        'juil.',
        'août',
        'sept.',
        'oct.',
        'nov.',
        'déc.'
      ]
    : [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre'
      ]

  const [day, month, year] = date.toLocaleDateString().split('/')
  if (!day || !month || !year) {
    return ''
  }
  const formattedDate = `${
    parseInt(day, 10) !== 1 ? parseInt(day, 10) : '1er'
  } ${months[parseInt(month, 10) - 1]} ${year}`
  return formattedDate
}

export function formatDateToString(date: Date): string {
  const day =
    date.getDate().toString().length === 1
      ? `0${date.getDate()}`
      : date.getDate().toString()
  const month =
    (date.getMonth() + 1).toString().length === 1
      ? `0${date.getMonth() + 1}`
      : (date.getMonth() + 1).toString()
  return `${day}/${month}/${date.getFullYear()}`
}

export const formatDatePeriodToFrench = (startDate: Date, endDate: Date): string => {
  const months = [
    'janv.',
    'févr.',
    'mars',
    'avril',
    'mai',
    'juin',
    'juil.',
    'août',
    'sept.',
    'oct.',
    'nov.',
    'déc.'
  ]

  const isSameYear = startDate.getFullYear() === endDate.getFullYear()
  const isSameMonth = startDate.getMonth() === endDate.getMonth()

  const [startDateDay, startDateMonth, startDateYear] = startDate.toLocaleDateString().split('/')
  if (!startDateDay || !startDateMonth || !startDateYear) {
    return ''
  }

  const [endDateDay, endDateMonth, endDateYear] = endDate.toLocaleDateString().split('/')
  if (!endDateDay || !endDateMonth || !endDateYear) {
    return ''
  }

  const formattedStartDateDay = parseInt(startDateDay, 10) !== 1 ? parseInt(startDateDay, 10) : '1er'
  const formattedEndDateDay = parseInt(endDateDay, 10) !== 1 ? parseInt(endDateDay, 10) : '1er'

  if (isSameYear && isSameMonth) {
    return `${formattedStartDateDay} - ${formattedEndDateDay} ${months[startDate.getMonth()]} ${startDateYear}`
  }
  if (isSameYear) {
    return `${formattedStartDateDay} ${months[startDate.getMonth()]} - ${formattedEndDateDay} ${months[endDate.getMonth()]} ${startDateYear}`
  }

  return `${formatDateToFrench(startDate, true)} - ${formatDateToFrench(endDate, true)}`
}


type checkIfDatesInRangeInput = {
  rangeToCheck: {
    startDateToCheck: string | Date
    endDateToCheck: string | Date
  }
  baseRange: {
    baseStartDate: string | Date
    baseEndDate: string | Date
  }
}

export const checkIfDatesInRange = ({rangeToCheck, baseRange}: checkIfDatesInRangeInput) => {
  const {startDateToCheck, endDateToCheck} = rangeToCheck
  const {baseStartDate, baseEndDate} = baseRange

  const isStartDateInclude = new Date(startDateToCheck).getTime() > new Date(baseStartDate).getTime()
  const isEndDateInclude = new Date(endDateToCheck).getTime() < new Date(baseEndDate).getTime()

  return isStartDateInclude && isEndDateInclude
}

export const checkIfFrenchShortDate = (value: string) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  return dateRegex.test(value)
}

export const compareFrenchShortDates = (valueA: string, valueB: string) => {
  if (!(checkIfFrenchShortDate(valueA) && checkIfFrenchShortDate(valueB))) {
    return 0
  }

  const [dayA, monthA, yearA] = valueA.split('/').map(Number)
  const [dayB, monthB, yearB] = valueB.split('/').map(Number)

  const dateA = new Date(yearA, monthA - 1, dayA)
  const dateB = new Date(yearB, monthB - 1, dayB)

  return dateA.getTime() - dateB.getTime()
}