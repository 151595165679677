import { ReactNode } from 'react'
import { Selection, BaseType } from "d3"

import { LineChartData, Point } from './lineChartDrawer'

export enum LineChartAggregatorEnum {
  SUM = 'sum',
  AVERAGE = 'average'
}

export enum RangeSelectionIdEnum {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day'
}

export const checkIfRangeSelectionId = (
  val: any
): val is RangeSelectionIdEnum => {
  return val !== undefined && Object.values(RangeSelectionIdEnum).includes(val)
}

export const checkIfRangeSelectionOption = (
  val: any
): val is RangeSelectionOption => {
  return (
    val &&
    checkIfRangeSelectionId(val.id) &&
    val.label !== undefined &&
    typeof val.label === 'string'
  )
}

export type TooltipComponentType<PointType extends Point> = (
  props: LineChartTooltipProps<PointType>
) => ReactNode

export type PointsDataToDisplayType<PointType extends Point> = {
  pointData: PointType
  datasetIndex: number
}[]

export type InputTooltipRenderer<PointType extends Point> = {
  pointsDataToDisplay: PointsDataToDisplayType<PointType>
  fullData: LineChartData<PointType>
  tooltip: Selection<BaseType, unknown, HTMLElement, any>
  time: Date
  endTime: Date | undefined
  chartId: string
}

export type TooltipRenderer<PointType extends Point> = ({
  pointsDataToDisplay,
  fullData,
  tooltip,
  time,
  endTime,
  chartId
}: InputTooltipRenderer<PointType>) => void

export type TooltipConfiguration<PointType extends Point> = {
  tooltipContent: TooltipComponentType<PointType>
  updateFunction: TooltipRenderer<PointType>
}

export type LineChartTooltipProps<PointType extends Point> = {
  chartId: string
  data: LineChartData<PointType>
}

export type DateComparatorType = (date1: Date, date2: Date) => boolean

export type AggregationFunctionType = (values: number[]) => number

export type AggregatedPointBuilder<PointType extends Point> = (
  aggregatedPoints: PointType[],
  aggregatedValue: number
) => PointType

export type RangeSelectorData<PointType extends Point> = {
  selectionOptions: RangeSelectionOption[]
  defaultSelectionId: RangeSelectionIdEnum
  buildAggregatedPoint: AggregatedPointBuilder<PointType>
}

export type RangeSelectionOption = {
  id: RangeSelectionIdEnum
  label: string
}
