import styled from '@emotion/styled'

import { Colors, FontSize, FontWeight, Margin, Padding } from '../../constants'

export const SmallCTA = styled.button`
  display: inline;
  justify-content: center;
  width: fit-content;
  border: 1px solid ${Colors.greyBorder};
  background-color: ${Colors.blue_01};
  min-width: 90px;
  border-radius: 4px;
  align-items: center;
  gap: ${Margin.m3};
  cursor: pointer;
  padding: ${Padding.p3} ${Padding.p5};
  margin: 0 ${Margin.m3};
  font-size: ${FontSize.lg};
  color: ${Colors.darkBlue};
  font-weight: ${FontWeight.YETIC_BOLD};

  :hover {
    border-color: ${Colors.greyHoverBorder};
  }
`
