import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import { P } from '../../../../../components/Text'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import { formatNumber } from '../../../../../helpers/rounds'
import KpiCard from '../KpiCard'

type PropsType = {
  ctr?: number
  isLoading: boolean
}

// TODO wording of the tooltip
export const CTRCard = ({
  ctr,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.ctr}
      title='CTR'
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : ctr !== undefined ? formatNumber(ctr) + ' %'
        : 'N/A'
      }
      tooltipContent={<P small>{`Le nombre de clics par impression`}</P>}
    />
  )
}
