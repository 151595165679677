import styled from '@emotion/styled'

import { FontSize } from '../../constants'

export const DivTooltip = styled.div`
  position: relative;
`

export const TooltipIconStyle = styled.span<{
  dark?: boolean
  hover?: boolean
  light?: boolean
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${({ dark, light }) => (dark ? '12px' : light ? '15px' : '15px')};
  height: ${({ dark, light }) => (dark ? '12px' : light ? '15px' : '15px')};
  font-size: ${({ dark, light }) => (dark ? '10px' : light ? '12px' : '12px')};
  color: ${({ dark, light }) =>
    dark ? '#fff' : light ? '#ffffff' : '#152a4c'};
  background-color: ${({ dark, light }) =>
    dark ? '#495A74' : light ? 'none' : 'none'};
  border: ${({ dark, light }) =>
    dark ? 'none' : light ? '1px solid #ffffff' : '1px solid #152a4c'};
  border-radius: 50%;
  cursor: ${({ hover }) => (hover ? 'default' : 'pointer')};
  margin-left: 6px;
  align-self: flex-start;
`

export const TooltipNoIconStyle = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const TooltipContentStyle = styled.div`
  font-size: ${FontSize.xs};
  width: 300px;
  box-shadow: 0px 0px 5px #66666680;
  border-radius: 4px;
  position: absolute;
  font-weight: 500;
  padding: 12px;
  top: 8px;
  right: 0;
  color: #707e89;
  text-align: left;
  background-color: white;
  z-index: 200;
  max-width: 264px;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: transparent;
`
