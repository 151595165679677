import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import { P } from '../../../../../components/Text'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import { formatNumber } from '../../../../../helpers/rounds'
import KpiCard from '../KpiCard'

type PropsType = {
  cost?: number
  isLoading: boolean
}

export const CostCard = ({
  cost,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.cost}
      title="Coût"
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : cost !== undefined ? formatNumber(cost) + ' €' : 'N/A'
      }
      tooltipContent={<P small>{`Le montant total dépensé pour diffuser la campagne publicitaire`}</P>}
    />
  )
}
