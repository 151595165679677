export const checkNameFormat = (val: string) => {
  return val.length >= 2 && !val.split('').every(char => char === ' ')
}

export const checkEmailFormat = (value: string) => {
  const regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')

  return regex.test(value)
}

export const checkPhoneFormat = (value: string) => {
  const regex = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$')

  return regex.test(value)
}

const checkIfContainsLowerCase = (val: string) => {
  return /[a-z]/.test(val)
}

const checkIfContainsUpperCase = (val: string) => {
  return /[A-Z]/.test(val)
}

export const checkPasswordFormat = (password: string) => {
  if (password.length < 8) {
    return false
  }

  if (!checkIfContainsUpperCase(password)) {
    return false
  }

  if (!checkIfContainsLowerCase(password)) {
    return false
  }

  if (!password.split('').find((char) => !isNaN(Number(char)))) {
    return false
  }

  return true
}
