import React from "react"

import { StyledWrapper } from "./style"

type LineCardProps = {
  icon: React.JSX.Element | null
  content: React.JSX.Element
}

const LineCard = ({ icon, content }: LineCardProps) => {
  return (
    <StyledWrapper>
        {icon}
        {content}
    </StyledWrapper>
  )
}

export default LineCard
