import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  mapReportingApiTabSection,
  ReportingCampaignType,
  ReportingHighlightsDataType,
  SectionDataType,
  ReportingTabKeyEnum,
  ReportingCampaignDownloadDataType
} from '../types'
import { getReportingDataForSections, PageReportingTypeEnum, SectionsToFetch } from '../helpers/query'
import { getStartAndEndDate } from '../helpers/date'
import ReportingHeader from '../ReportingHeader/campaign'
import ReportingBody from '../ReportingBody'

const tabs: ReportingTabKeyEnum[] = [ReportingTabKeyEnum.impact, ReportingTabKeyEnum.global, ReportingTabKeyEnum.devices, ReportingTabKeyEnum.locations]

export interface ReportingCampaignProps {
  campaigns: ReportingCampaignType[]
  setFetchError: (error: string | null) => void
  selectedCampaignId: string | undefined
}

const ReportingCampaign = ({ campaigns, setFetchError, selectedCampaignId }: ReportingCampaignProps) => {
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<string | null>(null)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isHighlightsDataLoading, setIsHighlightsDataLoading] = useState(true)
  const [highlightsData, setHighlightsData] = useState<ReportingHighlightsDataType | null>(null)
  const [isSectionDatatLoading, setIsSectionDataLoading] = useState(true)
  const [sectionData, setSectionData] = useState<SectionDataType | null>(null)

  const [_, setSearchParams] = useSearchParams()

  let selectedCampaign: ReportingCampaignType | undefined

  if (selectedCampaignId === 'all') {
    const { startDate, endDate } = getStartAndEndDate(campaigns ?? [])
    selectedCampaign = {
      id: "all",
      name: "Toutes les campagnes",
      type: "",
      startDate,
      endDate
    }
  } else {
    selectedCampaign = campaigns?.find((campaign) => campaign.id === selectedCampaignId)
  }


  useEffect(() => {
    if (selectedCampaign) {
      const sectionsToFetch: SectionsToFetch = {
        highlights: isHighlightsDataLoading,
        sectionTabName: mapReportingApiTabSection(tabs[activeTabIndex])
      }

      ;(async () => {
        const { sectionData: newSectionData, highlightsData: newHighlightsData, error} = await
          getReportingDataForSections({
            id: selectedCampaign.id,
            sections: sectionsToFetch,
            startDate: selectedStartDate || undefined,
            endDate: selectedEndDate || undefined,
            pageType: PageReportingTypeEnum.CAMPAIGN
          })

        if (error) {
          setFetchError('Une erreur est survenue lors du chargement des KPI')
        } else {
          if (newSectionData !== null) {
            setSectionData(newSectionData)
          }
          if (newHighlightsData !== null) {
            setHighlightsData(newHighlightsData)
          }
        }

        setIsHighlightsDataLoading(false)
        setIsSectionDataLoading(false)
      })()
    } else {
      setHighlightsData(null)
      setSectionData(null)
    }
  }, [activeTabIndex, selectedCampaignId, selectedStartDate, selectedEndDate])

  const handleChangeTabIndex = (index: number) => {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index)
      setIsSectionDataLoading(true)
    }
  }

  const handleChangeStartDateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStartDate(e.target.value)
    setIsHighlightsDataLoading(true)
  }

  const handleChangeEndDateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEndDate(e.target.value)
    setIsHighlightsDataLoading(true)
  }

  const handleCampaignChange = (campaignName: string | null) => {
    if (campaignName === "Toutes les campagnes") {
      setSearchParams({ 'campaign-id': 'all' })
    } else if (campaignName) {
      const currentSelectedCampaign = campaigns?.find((campaign) => campaign.name === campaignName) || null
      setSearchParams({ 'campaign-id': currentSelectedCampaign?.id ?? '' })
    }

    setIsHighlightsDataLoading(true)
  }

  const downloadData: ReportingCampaignDownloadDataType | null = highlightsData !== null ? [
    {
      clicks: highlightsData.clicks,
      co2: highlightsData.co2,
      co2PerEuro: highlightsData.co2PerEuro,
      cost: highlightsData.cost,
      cpc: highlightsData.cpc,
      ctr: highlightsData.ctr,
      impact: highlightsData.impact,
      impressions: highlightsData.impressions
    }
  ] : null

  return (
    <>
      <ReportingHeader
        campaigns={campaigns}
        selectedCampaign={selectedCampaign ?? null}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        onCampaignChange={handleCampaignChange}
        onChangeStartDateInput={handleChangeStartDateInput}
        onChangeEndDateInput={handleChangeEndDateInput}
      />
      {selectedCampaign &&
        <ReportingBody
          pageType={PageReportingTypeEnum.CAMPAIGN}
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          isHighlightsDataLoading={isHighlightsDataLoading}
          highlightsData={highlightsData}
          downloadData={downloadData}
          isSectionDatatLoading={isSectionDatatLoading}
          sectionData={sectionData}
          onChangeTabIndex={handleChangeTabIndex}
          key={selectedCampaignId}
          selectedCampaignId={selectedCampaignId}
        />
      }
    </>
  )
}

export default ReportingCampaign