import React, { useEffect, useState } from 'react'

import { Margin } from '../../../../constants'
import { BalanceSheetStatusEnum, convertApiStatusToStatus } from '../../../BalanceSheet/types'
import Tag from '../../../../components/Tag'
import { getTagVariantFromStatus } from '../../../../components/Tag/style'
import { P } from '../../../../components/Text'
import EditIcon from '../../../../components/Icons/edit'
import { getOneBalanceSheetApi } from '../../../../services/api/balanceSheets/get/getOne'
import { checkIfErrorType } from '../../../../types/error'
import BalanceSheetStatusEditModal from './EditStatusModal'
import { updateBalanceSheetStatusApi } from '../../../../services/api/balanceSheets/update/status'

export interface BalanceSheetStatusEditionProps {
  balanceSheetId: string
}

const BalanceSheetStatusEdition = ({ balanceSheetId }: 
  BalanceSheetStatusEditionProps) => {
  const [status, setStatus] = useState<BalanceSheetStatusEnum | null>(null)
  const [isEditModalOpened, setIsModalOpened] = useState(false)

  useEffect(() => {
    (async () => {
      const balanceSheet = await getOneBalanceSheetApi({ balanceSheetId })
      if (!checkIfErrorType(balanceSheet)) {
        setStatus(convertApiStatusToStatus(balanceSheet.status))
      } else {
        alert('Une erreur est survenue lors de la récupération de data au sujet du bilan')
      }
    })()
  }, [balanceSheetId])

  const onSubmit = async (newStatus: BalanceSheetStatusEnum) => {
    const result = await updateBalanceSheetStatusApi({ 
      balanceSheetId, 
      status: newStatus
    })
    
    if (!checkIfErrorType(result)) {
      setStatus(convertApiStatusToStatus(result.status))
    } else {
      alert('Une erreur est survenue pendant la mise à jour du statut')
    }
  }

  return (
    <div style={{marginTop: Margin.m7}}>
      <P style={{marginBottom: Margin.m3}}>Statut du bilan :</P>
      {status != null && (
        <div style={{display: "flex"}}>
          <Tag variant={getTagVariantFromStatus(status)}>
            {status}
          </Tag>
          <span style={{margin: Margin.m3, cursor: "pointer"}} onClick={() => setIsModalOpened(true)}><EditIcon  /></span>
          {isEditModalOpened && <BalanceSheetStatusEditModal
            initialStatus={status}
            onClose={() => setIsModalOpened(false)}
            onCancel={() => setIsModalOpened(false)}
            onSubmit={onSubmit}
            apiError=""
          />}
        </div>
      )}
    </div>
  )
}

export default BalanceSheetStatusEdition