import { GetBalanceSheetsApiOutput } from "../../../services/api/balanceSheets/get/types"
import { GetCampaignsApiOutput } from "../../../services/api/campaigns/get/types"
import { ReportingBalanceSheetType, ReportingCampaignType } from "../types"

export const mapGetCampaignsApiOutputToReportingCampaigns = (data: GetCampaignsApiOutput): ReportingCampaignType[] => {
  return data.map((campaign) => ({
    id: campaign.id,
    name: campaign.name,
    type: campaign.format,
    startDate: campaign.startDate,
    endDate: campaign.endDate,
    status: campaign.status
  }))
}

export const mapGetBalanceSheetsApiOutputToReportingBalanceSheets = (data: GetBalanceSheetsApiOutput): ReportingBalanceSheetType[] => {
  return data.map((campaign) => ({
    id: campaign.id,
    name: campaign.name,
    startDate: campaign.startDate,
    endDate: campaign.endDate,
    status: campaign.status
  }))
}