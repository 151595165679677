import React from 'react'
import { useNavigate } from 'react-router-dom'

import { P } from '../../../components/Text'
import { FontWeight } from '../../../constants'
import { SmallCTA } from '../style'

const ReportingLinecardContent = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/reporting')
  }
  
  return (
    <P
      style={{
        fontWeight: FontWeight.YETIC_MEDIUM,
      }}
    >
      Rendez-vous sur la page <SmallCTA onClick={handleClick}>Reporting</SmallCTA> pour voir les
      rapports que nous avons générés en fonction de vos bilans.
    </P>
  )
}

export default ReportingLinecardContent
