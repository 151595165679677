import React from 'react'

import { descriptionStyle, FooterButtons, ModalInsideContent, ModalOverlay, ModalWrapper } from './style'
import { H3, P } from '../Text'
import Button from '../Button'

type ModalProps = {
  title?: string
  description?: string
  onClose: () => void
  width?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  style?: React.CSSProperties
  overlayStyle?: React.CSSProperties
  children: React.ReactNode
  mainButton?: {
    label: string
    onClick: () => void
  }
  secondaryButton?: {
    label: string
    onClick: () => void
  }
}

const Modal = ({
  title,
  description,
  onClose,
  width = '60vw',
  maxWidth = '600px',
  height = 'auto',
  maxHeight = '80vh',
  style,
  overlayStyle,
  children,
  mainButton = { label: 'Valider', onClick: () => {} },
  secondaryButton = { label: 'Annuler', onClick: onClose }
}: ModalProps) => {
  return (
    <>
      <ModalOverlay onClick={onClose} style={overlayStyle} />
      <ModalWrapper style={style} width={width} height={height} maxWidth={maxWidth} maxHeight={maxHeight}>
        <ModalInsideContent>
          {title && <H3>{title}</H3>}
          {description && <P style={descriptionStyle}>{description}</P>}
          {children}
        </ModalInsideContent>
        {mainButton || secondaryButton ? (
          <FooterButtons>
            {secondaryButton && <Button onClick={secondaryButton.onClick} type='secondary' small>{secondaryButton.label}</Button>}
            {mainButton && <Button onClick={mainButton.onClick} small>{mainButton.label}</Button>}
          </FooterButtons>
        ) : null}
      </ModalWrapper>
    </>
  )
}

export default Modal
