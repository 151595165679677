import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { DownloadReportingOutputType } from "../../types"
import { checkIfDownloadReporting } from "../../types/checkers"
import { downloadDataReportingMock } from "../mock/download"

type GetBalanceSheetDownloadReportingInputType = {
  balanceSheetId: string
  startDate?: string
  endDate?: string
}

export const getBalanceSheetDownloadReporting = async ({
  balanceSheetId,
  startDate,
  endDate
}: GetBalanceSheetDownloadReportingInputType): Promise<DownloadReportingOutputType | null> => {
  try {
    // const response = await axiosGet({
    //   url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/download`
    // })

    const response = {
      type: 'success',
      data: downloadDataReportingMock
    }

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfDownloadReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}
