import React from 'react'

import { PieChartDataItem } from '../types'
import {
  PieChartCaptionItem,
  PieChartCaptionSquare,
  StyledWrapper,
} from './style'
import { P } from '../../../Text'

type PropsType = {
  data: PieChartDataItem[]
  total: number
}

const PieChartCaptions = ({ data, total }: PropsType) => {
  return (
    <StyledWrapper>
      {data.map((item) => (
        <PieChartCaptionItem>
          <PieChartCaptionSquare color={item.color} />
          <P>
            {item.label} ({Math.round((item.value / total) * 10000) / 100}%)
          </P>
        </PieChartCaptionItem>
      ))}
    </StyledWrapper>
  )
}

export default PieChartCaptions
