import { RangeSelectionIdEnum, LineChartAggregatorEnum, AggregatedPointBuilder } from "../types"
import { Point, LineChartData } from "../lineChartDrawer"
import aggregator from "./aggregators"
import { calculPercentageEvolution } from "./calculation"

export const processData = <PointType extends Point>(
  data: LineChartData<PointType>,
  selectedRangeId: RangeSelectionIdEnum | null,
  defaultAggregator: LineChartAggregatorEnum,
  buildAggregatedPoint: AggregatedPointBuilder<PointType> | null
) => {
  for (let i = 0; i < data.length; i++) {
    const aggregationMethod: LineChartAggregatorEnum =
      data[i].aggregator ?? defaultAggregator

    if (selectedRangeId && buildAggregatedPoint) {
      data[i].values = aggregator(
        selectedRangeId,
        data[i].values,
        aggregationMethod,
        buildAggregatedPoint
      )
    }

    for (let j = 0; j < data[i].values.length; j++) {
      const currentValue = data[i].values[j]
      const previousValue = data[i].values[j - 1]

      if (previousValue !== undefined) {
        data[i].values[j].percentageEvolution = calculPercentageEvolution(
          previousValue.value,
          currentValue.value
        )
      }
    }
  }

  return data
}
