import { CampaignFormat, CampaignPlatform, checkIfCampaignFormat, checkIfCampaignPlatform } from "../../../../types/formatAndPlatform"
import { CampaignStatus, checkIfCampaignStatus } from "../../../../types/status.ts"
import { checkIfDate } from "../../../../types/nativeTypes"

export type GetCampaignsApiOutput = {
  id: string
  googleCampaignId: string
  name: string
  format: string
  createdAt: string
  updatedAt: string
  startDate: string
  endDate: string
}[]

export const checkIfGetCampaignsApiOutput = (value: any): value is GetCampaignsApiOutput => {
  return Array.isArray(value)
    && value.every((item) =>
      item && item.id && typeof item.id === 'string'
      && item.googleCampaignId && typeof item.googleCampaignId === 'string'
      && item.name && typeof item.name === 'string'
      && item.format && typeof item.format === 'string'
      && item.createdAt && checkIfDate(item.createdAt)
      && item.updatedAt && checkIfDate(item.updatedAt)
      && item.startDate && checkIfDate(item.startDate)
      && item.endDate && checkIfDate(item.endDate)
  )
}

export type CampaignPerBalanceSheet = {
  name: string,
  id: string,
  linkedAt: string,
  status: CampaignStatus,
  platform: CampaignPlatform,
  format: CampaignFormat
}

export const checkIfCampaignPerBalanceSheetApi = (value: any): value is CampaignPerBalanceSheet => {
  return (
    typeof value === 'object' 
    && value !== null 
    && typeof value.name === 'string' 
    && typeof value.id === 'string' 
    && typeof value.linkedAt === 'string' && checkIfDate(value.linkedAt)
    && value.platform && typeof value.platform === 'string' && checkIfCampaignPlatform(value.platform)
    && value.format && typeof value.format === 'string' && checkIfCampaignFormat(value.format)
    && value.status && typeof value.status === 'string' && checkIfCampaignStatus(value.status)
  )
}

export type CampaignsPerBalanceSheet = CampaignPerBalanceSheet[]

export const checkIfCampaignsPerBalanceSheetApi = (value: any): value is CampaignsPerBalanceSheet => {
  return Array.isArray(value) && value.every((item) => checkIfCampaignPerBalanceSheetApi(item))
}
