import axios from 'axios'

import { QueryResponseType } from './types'

export type AxiosDeleteType = {
  url: string
  payload?: any
  contentType?: string
}

type QueryDeleteOutputSuccessType = {
  type: QueryResponseType.SUCCESS
  data: any
}

type QueryDeleteOutputErrorType = {
  type: QueryResponseType.ERROR
  error: {
    data: {
      code: string
      message: string
    }
  }
}

export const checkIsAxiosDeleteOutputSuccess = (
  value: any
): value is QueryDeleteOutputSuccessType => {
  if (value && value.type && value.data) {
    if (value.type === QueryResponseType.SUCCESS) {
      return true
    }
  }
  return false
}

export const axiosDelete = async ({
  url,
  payload,
  contentType
}: AxiosDeleteType): Promise<
  QueryDeleteOutputSuccessType | QueryDeleteOutputErrorType
> => {
  try {
    const response = await axios.delete(url, 
      {       
        data: payload,
        headers: {
          'Content-Type': contentType ?? 'application/json'
        },
        withCredentials: true 
      }
    )

    if (
      checkIsAxiosDeleteOutputSuccess({
        type: QueryResponseType.SUCCESS,
        data: response.data,
      })
    ) {
      return {
        type: QueryResponseType.SUCCESS,
        data: response.data,
      }
    }
    return {
      type: QueryResponseType.ERROR,
      error: response.data,
    }
  } catch (error: any) {
    return {
      type: QueryResponseType.ERROR,
      error: error.response ?? error.request ?? error,
    }
  }
}
