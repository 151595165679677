import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import { Margin } from '../../../../../constants'

export const googleIconStyle: CSSProperties = {
  height: '15px',
}

export const metaIconStyle: CSSProperties = {
  height: '18px',
}

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${Margin.m3};
`

export const tiktokIconStyle: CSSProperties = {
  height: '18px'
}