import styled from "@emotion/styled"

import { Margin } from "../../constants"

export const Container = styled.div`
  margin-bottom: ${Margin.m7};
  display: flex;
  flex-direction: column;
`

export const ItemsContainer = styled.div`
  display: flex;
  gap: ${Margin.m5};
`
