import React from 'react'

import Dropdown from '../../../components/Dropdown'
import DateFilters from './DateFilters'
import { 
  ReportingBalanceSheetType
} from '../types'
import { StyledReportingHeader } from '../style'

type ReportingHeaderBalanceSheetProps = {
  balanceSheets: ReportingBalanceSheetType[] | null
  selectedBalanceSheet: ReportingBalanceSheetType | null
  selectedStartDate: string | null
  selectedEndDate: string | null
  onBalanceSheetChange: (name: string | null) => void
  onChangeStartDateInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeEndDateInput: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ReportingHeaderBalanceSheet = ({
  balanceSheets,
  selectedBalanceSheet,
  selectedStartDate,
  selectedEndDate,
  onBalanceSheetChange,
  onChangeStartDateInput,
  onChangeEndDateInput
}: ReportingHeaderBalanceSheetProps) => {
  const balanceSheetsNames = balanceSheets?.map((balanceSheet) => balanceSheet.name)
    .filter((balanceSheet): balanceSheet is string => balanceSheet != null) ?? []

  return (
    <StyledReportingHeader>
      <Dropdown
        label='Bilan'
        options={balanceSheetsNames}
        selected={selectedBalanceSheet?.name ?? null}
        onValueChange={onBalanceSheetChange}
        style={{ flex: 1 }}
      />
      {selectedBalanceSheet && (
        <DateFilters
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          handleChangeStartDate={onChangeStartDateInput}
          handleChangeEndDate={onChangeEndDateInput}
          defaultStartDate={selectedBalanceSheet.startDate}
          defaultEndDate={selectedBalanceSheet.endDate}
          today={new Date().toISOString().split('T')[0]}
          disabled
        />
      )}
    </StyledReportingHeader>
  )
}

export default ReportingHeaderBalanceSheet