import { UserPermissionsEnum } from "../types/permissions"

type Cookies = Record<string, string>

export const getCookie = (name: string): string | undefined => {
  const cookieString = decodeURIComponent(document.cookie)
  const cookies: Cookies = cookieString
    .split('; ')
    .map((cookie) => cookie.split('='))
    .reduce(
      (acc, [cookieName, cookieValue]) => ({
        ...acc,
        [cookieName]: cookieValue
      }),
      {}
    )

  return cookies[name]
}

export const clearAllCookies = () => {
  const cookieNames = ['yamReportingToken', 'companyName', 'permissions']
  const pastDate = 'Thu, 01 Jan 1970 00:00:01 GMT'

  cookieNames.forEach((cookieName) => {
    document.cookie = `${cookieName}=; expires=${pastDate}; path=/;`
  })
}

export const checkIfPermissions = (permissions: string | undefined): permissions is UserPermissionsEnum => {
  return !!permissions && Object.values(UserPermissionsEnum).includes(permissions as UserPermissionsEnum)
}

export const checkCookies = () => {
  const companyName = getCookie('companyName')
  const token = getCookie('yamReportingToken')
  const permissions = getCookie('permissions')
  const arePermissionsValid = checkIfPermissions(permissions)

  if (permissions === UserPermissionsEnum.DEFAULT) {
    if (!companyName) {
      return false
    }
  }

  return token && permissions && arePermissionsValid
}