import * as d3 from 'd3'

import { formatDateToFrench } from '../../../../../helpers/date'
import { TooltipRenderer } from '../../types'
import { Point, LineChartData } from '../../lineChartDrawer'
import { checkIfEventTargetIsSVGElement } from '../../../../../types/common'
import updateTooltip from './updateTooltip'

const drawTooltip = <PointType extends Point>(
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  data: LineChartData<PointType>,
  chartId: string,
  x: d3.ScaleBand<string>,
  yScales: d3.ScaleLinear<number, number, never>[],
  renderTooltip: TooltipRenderer<PointType>
) => {
  const tooltip = d3.select(`#${chartId}-tooltip`)

  svg
    .selectAll('.dots')
    .data(data)
    .join('g')
    .style('fill', (d) => d.color)
    .attr('class', (d) => d.name)
    .selectAll('.points')
    .data((d, i) =>
      d.values.map((value, index) => ({
        value: { ...value, index },
        datasetIndex: i
      }))
    )
    .join('circle')
    .attr('cx', (d) => x(formatDateToFrench(d.value.time)) ?? 0)
    .attr('cy', (d) => yScales[d.datasetIndex](+d.value.value))
    .attr('r', 25)
    .style('opacity', 0)
    .attr('class', (d) => `i${d.value.index} index`)
    .on('mousemove', (e: MouseEvent, d) => {
      const hoveredElement = e.currentTarget
      if (checkIfEventTargetIsSVGElement(hoveredElement)) {
        updateTooltip({
          tooltip,
          hoveredElement,
          data,
          d,
          chartId,
          renderTooltip
        })
      }
    })
    .on('mouseout', () => {
      tooltip.style('opacity', 0)
    })
}

export default drawTooltip
