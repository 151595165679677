import { Selection, BaseType } from 'd3'

import { formatDateToFrench } from '../../../../../helpers/date'
import {
  PointsDataToDisplayType,
  TooltipRenderer
} from '../../types'
import { Point, LineChartData } from '../../lineChartDrawer'

type InputType<PointType extends Point> = {
  tooltip: Selection<BaseType, unknown, HTMLElement, any>
  hoveredElement: SVGElement
  data: LineChartData<PointType>
  d: {
    value: PointType
    datasetIndex: number
  }
  chartId: string
  renderTooltip: TooltipRenderer<PointType>
}

const updateTooltip = <PointType extends Point>({
  tooltip,
  hoveredElement,
  data,
  d,
  chartId,
  renderTooltip
}: InputType<PointType>) => {
  const elementCircle = hoveredElement.getBoundingClientRect()
  const elementCenterX = elementCircle.left + elementCircle.width / 2
  const elementCenterY = elementCircle.top + elementCircle.height / 2

  const pointsDataToDisplay: PointsDataToDisplayType<PointType> = data.map(
    (dataset, index) => {
      const pointData = dataset.values.filter(
        (val) =>
          formatDateToFrench(val.time) === formatDateToFrench(d.value.time)
      )[0]

      return { pointData, datasetIndex: index }
    }
  )

  tooltip.style('opacity', 1)
  tooltip.style('left', `${elementCenterX}px`)
  tooltip.style('top', `${elementCenterY}px`)

  const time = d.value.time
  const endTime = d.value.endTime

  renderTooltip({
    pointsDataToDisplay,
    fullData: data,
    tooltip,
    time,
    endTime,
    chartId
  })
}

export default updateTooltip
