import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { LogoWrapper, Navigation, selectedLogoColor, StyledLeftMenu, StyledLogo } from './style'
import LeftMenuItem from './LeftMenuItem'
import { EditFileIcon, HomeIcon, ReportingIcon, TableIcon } from '../../../components/Icons'
import { Colors } from '../../../constants'
import { endpointByPage, PageEnum } from '../../../types/page'
import { AppContext } from '../../../contexts/AppContext'
import LogoImg from '../../../../public/assets/images/logo.png'
import { UserPermissionsEnum } from '../../../types/permissions'
import LeftMenuBottom from './LeftMenuBottom'

const LeftMenu = ({ page }: { page: PageEnum }) => {
  const navigate = useNavigate()

  const { userType, companyName, handleLogout } = useContext(AppContext)

  // TODO : Provisioning will only be for provisioning users
  // Hide several pages for the provisioning users
  return (
    <StyledLeftMenu>
      <LogoWrapper onClick={() => navigate(endpointByPage.HOME)}>
        <StyledLogo src={LogoImg} alt="logo Yetic" />
      </LogoWrapper>
      <Navigation>
        <LeftMenuItem
          onClick={() => navigate(endpointByPage.HOME)}
          logo={<HomeIcon color={page === PageEnum.HOME ? selectedLogoColor : Colors.veryDarkGrey} />}
          selected={page === PageEnum.HOME}
        >
          Accueil
        </LeftMenuItem>
        {userType === UserPermissionsEnum.DEFAULT && <LeftMenuItem
          onClick={() => navigate(endpointByPage.REPORTING)}
          logo={<ReportingIcon color={page === PageEnum.REPORTING ? selectedLogoColor : Colors.veryDarkGrey} />}
          selected={page === PageEnum.REPORTING}
        >
          Reporting
        </LeftMenuItem>}
        {userType === UserPermissionsEnum.DEFAULT && <LeftMenuItem
          onClick={() => navigate(endpointByPage.BALANCE_SHEET)}
          logo={<TableIcon color={page === PageEnum.BALANCE_SHEET ? selectedLogoColor : Colors.veryDarkGrey} />}
          selected={page === PageEnum.BALANCE_SHEET}
        >
          Bilans
        </LeftMenuItem>}
        {userType === UserPermissionsEnum.PROVISIONING && <LeftMenuItem
          onClick={() => navigate(endpointByPage.PROVISIONING)}
          logo={<EditFileIcon color={page === PageEnum.PROVISIONING ? selectedLogoColor : Colors.veryDarkGrey}/>}
          selected={page === PageEnum.PROVISIONING}
        >
          Provisioning
        </LeftMenuItem>}
      </Navigation>
      <LeftMenuBottom companyName={companyName} handleLogout={handleLogout} />
    </StyledLeftMenu>
  )
}

export default LeftMenu
