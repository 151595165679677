import React, { CSSProperties, FC } from 'react'
import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'

const StyledInputRange = styled.input<{
  width?: string
  disabled?: boolean
  withBorder?: boolean
  error?: string
}>`
  -webkit-appearance: none;
  margin: ${Margin.m4} ${Margin.m0};
  width: ${(props) => (props.width ? props.width : '100%')};

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    animate: 0.2s;
    background: ${Colors.lightGrey};
    border-radius: 25px;
  }

  &::-webkit-slider-thumb {
    width: 15px;
    height: 15px;

    margin-top: -4px;

    border-radius: 50%;
    background: ${Colors.grey};
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0);
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    -webkit-appearance: none;
  }

  &:focus::-webkit-slider-runnable-track {
    background: #03a9f4;
  }

  ${(props: { withBorder?: boolean; error?: string }) =>
    props.error
      ? 'border: 1px solid red;'
      : props.withBorder
        ? 'border: 1px solid #C4C9D2;'
        : ''})
`

export type InputRangeProps = {
  style: CSSProperties
  width?: string
  min?: number
  max?: number
  step?: number
  defaultValue?: number
  error?: string
}

const InputRange: FC<InputRangeProps> = ({
  style,
  min,
  max,
  step,
  defaultValue,
  width,
  error
}) => (
  <StyledInputRange
    type="range"
    style={style}
    min={min}
    max={max}
    step={step}
    value={defaultValue}
    width={width}
    error={error}
  />
)

export default InputRange
