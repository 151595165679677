import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Padding from '../../../constants/padding'
import Colors from '../../../constants/colors'
import Margin from '../../../constants/margin'

export const LineChartContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  padding-bottom: ${Padding.p8};
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 3px #142a4c80;
  background-color: ${Colors.white};
`

export const DropDownContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  padding-top: ${Padding.p5};
  padding-right: ${Padding.p5};
  box-sizing: border-box;
`

export const dropDownStyle: CSSProperties = {
  minWidth: '150px'
}

export const LineChartRoot = styled.div`
  width: 100%;
`

export const LineChartTooltipContainer = styled.div`
  position: absolute;
  background-color: ${Colors.white};
  border-radius: 10px;
  padding: ${Padding.p6} ${Padding.p5};
  box-shadow: 0px 0px 5px #142a4c80;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: ${Margin.m3};
`
