import { checkUpdateGooglePerfMaxCampaign, UpdateGooglePerfMaxCampaign } from "./types"
import { axiosPut, checkIsAxiosPutOutputSuccess } from "../../../../../helpers/axios/put"
import { backendURL } from "../../../../../constants"
import { ErrorType } from "../../../../../types/error"

type UpdateCampaignApiInput = {
  campaignId: string
  duration: number
  ratioSmartphone: number,
  ratioDesktop: number,
  ratioTablet: number,
  ratioTv: number
}

export const updateCampaignApi = async ({
  campaignId,
  duration,
  ratioSmartphone,
  ratioDesktop,
  ratioTablet,
  ratioTv
}: UpdateCampaignApiInput): Promise<UpdateGooglePerfMaxCampaign | ErrorType> => {
  const response = await axiosPut({
    url: `${backendURL}/campaigns/${campaignId}`,
    payload: {
      duration,
      ratioSmartphone,
      ratioDesktop,
      ratioTablet,
      ratioTv
    }
  })

  if (checkIsAxiosPutOutputSuccess(response)) {
    if (checkUpdateGooglePerfMaxCampaign(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors de la mise à jour des ratio et de la durée.'
  }
}