import React, { useState } from 'react'
import Modal from '../../../../../components/Modal'
import Dropdown from '../../../../../components/Dropdown'
import { Colors } from '../../../../../constants'
import { BalanceSheetStatusEnum } from '../../../../BalanceSheet/types'

type BalanceSheetStatusEditModalProps = {
  initialStatus: BalanceSheetStatusEnum
  onClose: () => void
  onCancel: () => void
  onSubmit: (newStatus: BalanceSheetStatusEnum) => void
  apiError: string
}

const BalanceSheetStatusEditModal = ({
  initialStatus,
  onClose,
  onCancel,
  onSubmit,
  apiError
}: BalanceSheetStatusEditModalProps) => {
  const [dropdownStatus, setDropdownStatus] = useState<BalanceSheetStatusEnum | null>(initialStatus)

  const statuts = [BalanceSheetStatusEnum.WAITING_FOR_PROVISIONING,
    BalanceSheetStatusEnum.PROVISIONING_IN_PROGRESS, 
    BalanceSheetStatusEnum.READY
  ]

  const handleChangeStatus = (value: string | null) => {
    if (value === BalanceSheetStatusEnum.WAITING_FOR_PROVISIONING ||
      value === BalanceSheetStatusEnum.PROVISIONING_IN_PROGRESS ||
      value === BalanceSheetStatusEnum.READY
    ) {
      setDropdownStatus(value)
    } else {
      setDropdownStatus(null)
    }
  }

  const handleSubmit = () => {
    if (dropdownStatus == null) {
      alert("Veillez sélectionner un status avant de valider")
    } else {
      onSubmit(dropdownStatus)
      onClose()
    }
  }

  return (
    <Modal
      title={'Sélectionner un statut'}
      description='Veuillez choisir le nouveau statut du bilan.'
      onClose={onClose}
      mainButton={{
        label: 'Valider',
        onClick: handleSubmit
      }}
      secondaryButton={{
        label: 'Annuler',
        onClick: onCancel
      }}
    >
      <Dropdown
        label='Statut du bilan'
        options={statuts}
        selected={dropdownStatus}
        onValueChange={handleChangeStatus}
        style={{ flex: 1 }}
      />
      {apiError && <div style={{color: Colors.red, textAlign: "center"}}>
        {apiError}
      </div>}
    </Modal>
  )
}

export default BalanceSheetStatusEditModal