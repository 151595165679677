import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from './types'

const ArrowDropdown = ({
  height = '24px',
  width = '24px',
  style,
  color = Colors.darkBlue,
  handleClick
}: IconType) => {
  return (
    <svg
      style={style}
      onClick={handleClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <path
        d="M4.66667 6.66667L8 10L11.3333 6.66667L12
         7.33333L8 11.3333L4 7.33333L4.66667 6.66667Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowDropdown
