import React from 'react'

import { ImpactTooltipTitle } from './styles'
import { P } from '../../../../../../components/Text'
import { Margin } from '../../../../../../constants'

const ImpactTooltip = () => {
  return (
    <div>
      <ImpactTooltipTitle>
        <P bold>Emissions de CO₂ évitées</P>
      </ImpactTooltipTitle>
      <P small>
        {`Ce pourcentage est la différence entre le CO₂eq d'une campagne créée sur le Yetic Ad Manager et le CO₂eq d'une campagne avec un paramétrage considéré moyen.`}
      </P>
      <P small style={{ marginTop: Margin.m4}}>
        {`Ce paramétrage moyen est basé sur des données de Mediametrie et Httparchive.`}
      </P>
    </div>
  )
}

export default ImpactTooltip
