import { checkCreateCampaignApi, CreateCampaign } from "./types"
import { axiosPost, checkIsAxiosPostOutputSuccess } from "../../../../helpers/axios/post"
import { backendURL } from "../../../../constants"
import { ErrorType } from "../../../../types/error"
import { CampaignFormat, CampaignPlatform } from "../../../../types/formatAndPlatform"

type CreateCampaignApiInput = {
  companyId: string
  balanceSheetId: string
  platform: CampaignPlatform
  format: CampaignFormat
  name: string
}

export const createCampaignApi = async ({
  companyId,
  balanceSheetId,
  platform,
  format,
  name
}: CreateCampaignApiInput): Promise<CreateCampaign | ErrorType> => {
  const response = await axiosPost({
    url: `${backendURL}/campaigns`,
    payload: {
      companyId,
      balanceSheetId,
      platform: platform.toLocaleLowerCase(),
      format: format.toLocaleLowerCase(),
      name
    }
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkCreateCampaignApi(response.data)) {
      return response.data
    }
  }
  

  return {
    error: 'Une erreur est survenue lors de la création de la campagne.'
  }
}