import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppContext } from '../../../contexts/AppContext'
import Step from './Step'
import Tip from './Tip'
import { H3 } from '../../../components/Text'
import Paragraph from '../../../components/Text/Paragraph'
import Button from '../../../components/Button'
import { UserPermissionsEnum } from '../../../types/permissions'
import { HelpTutorialContainer, helpTutorialParagraphStyle, PageLink, Span, StepsContainer, StyledPageHeader, TipContainer } from './style'

type HelpDisplayProps = {
  isHelpDisplay: boolean
}

const HelpTutorial = ({isHelpDisplay}: HelpDisplayProps) => {
  const [stepDisplayStates, setStepDisplayStates] = useState({
    1: false,
    2.1: false,
    2.2: false,
    3: false,
  })
  
  const { userType } = useContext(AppContext)
  
  const navigate = useNavigate()

  const handleDisplayStep = (id: number) => {
    setStepDisplayStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const steps = [
    {
      id: 1,
      count: 1,
      title: 'Créez un bilan',
      instructions : [
        <>Accédez à la page <PageLink onClick={() => navigate('/balance-sheet')}>Bilan</PageLink></>,
        'Cliquez sur Nouveau bilan',
        'Définissez la période et les plateformes sur lesquelles les campagnes inclues seront diffusées'
      ],
      tip: "Vérifiez attentivement les informations, le bilan n'est plus modifiable une fois enregistré.",
      permission: [UserPermissionsEnum.DEFAULT, UserPermissionsEnum.PROVISIONING, UserPermissionsEnum.SELF_PROVISIONING]
    },
    {
      id: 2.1,
      count: 2,
      title: 'Ajoutez une campagne',
      instructions : [
        <>Accéder à la page <PageLink onClick={() => navigate('/provisioning')}>Provisioning</PageLink></>,
        'Cliquez sur Créer une campagne',
        'Renseignez ses caractéristiques',
        'Importez les logs au format .csv'
      ],
      tip: "Les logs sont téléchargeables depuis la plateforme qui diffuse votre campagne.",
      permission: [UserPermissionsEnum.PROVISIONING, UserPermissionsEnum.SELF_PROVISIONING]
    },
    {
      id: 2.2,
      count: 2,
      title: 'Ajout des campagnes',
      link: null,
      instructions : [
        "Le provisioneur va ajouter vos campagnes au bilan. Vous pourrez ensuite les visualiser dans l'onglet Reporting."
      ],
      tip: null,
      permission: [UserPermissionsEnum.DEFAULT]
    },
    {
      id: 3,
      count: 3,
      title: 'Suivez les performances',
      instructions : [
        <>Accédez à la page <PageLink onClick={()=> navigate('/reporting')}>Reporting</PageLink></>,
        'Visualisez les données de vos campagnes.'
      ],
      tip: null,
      permission: [UserPermissionsEnum.DEFAULT, UserPermissionsEnum.PROVISIONING, UserPermissionsEnum.SELF_PROVISIONING]
    }
  ]
  
  const userSteps = steps.filter((step) => step.permission.includes(userType ?? UserPermissionsEnum.DEFAULT))

  return (
    <HelpTutorialContainer isHelpDisplay={isHelpDisplay}>
      <StyledPageHeader>
        <H3>Tutoriel</H3>
      </StyledPageHeader>
      <>
        <StepsContainer>
          {userSteps.map((step) => (
            <Step 
              key={step.title} 
              count={step.count} 
              title={step.title} 
              instructions={step.instructions} 
              isStepDisplay={stepDisplayStates[step.id]} 
              tip={step.tip} handleDisplayStep={() => handleDisplayStep(step.id)} 
            />
          ))}
          <Tip>
            <TipContainer>
              <Paragraph style={helpTutorialParagraphStyle}><Span>Campagne:</Span> Ensemble de publicités ciblées sur différentes plateformes.</Paragraph>
              <Paragraph style={helpTutorialParagraphStyle}><Span>Bilan:</Span> Ensemble de campagnes.</Paragraph>
            </TipContainer>
          </Tip> 
        </StepsContainer>
        <Button onClick={() => navigate('/help')}>Accéder à la page d'aide</Button>
      </>
    </HelpTutorialContainer>
  )
}

export default HelpTutorial