import { checkIfCheckUserExistsApiRawSuccessOutput, CheckUserExistsApiOutput } from "./types"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../helpers/axios/get"
import { backendURL } from "../../../constants"
import { ErrorType } from "../../../types/error"

type CheckUserExistsApiInput = {
  email: string
}

export const checkUserExistsApi = async ({ email }: CheckUserExistsApiInput): Promise<CheckUserExistsApiOutput | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/users?email=${email}`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfCheckUserExistsApiRawSuccessOutput(response.data)) {
      return { found: true }
    }
  } else if (response.error.status === 404) {
    return { found: false }
  }

  return {
    error: 'Une erreur est survenue'
  }
}