import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { DownloadReportingOutputType } from "../../types"
import { checkIfDownloadReporting } from "../../types/checkers"
import { downloadDataReportingMock } from "../mock/download"

type GetCompanyDownloadReportingInputType = {
  startDate?: string
  endDate?: string
}

export const getCompanyDownloadReporting = async ({
  startDate,
  endDate
}: GetCompanyDownloadReportingInputType): Promise<DownloadReportingOutputType | null> => {
  try {
    // const response = await axiosGet({
    //   url: `${backendURL}/reports/campaigns/all/download${
    //     startDate ? `?startDate=${startDate}` : ''
    //   }${
    //     endDate
    //       ? startDate
    //         ? `&endDate=${endDate}`
    //         : `?endDate=${endDate}`
    //       : ''
    //   }`
    // })

    const response = {
      type: 'success',
      data: downloadDataReportingMock
    }

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfDownloadReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}
