import React from 'react'

import { Co2TooltipTitle, ParagraphContainer } from './styles'
import { P } from '../../../../../../components/Text'

type PropsType = {
  serverCo2?: number
  networkCo2?: number
  devicesCo2?: number
}

const Co2Tooltip = ({
  serverCo2,
  networkCo2,
  devicesCo2
}: PropsType) => {
  const roundedServerCo2 = serverCo2 === undefined ? undefined : Math.round(serverCo2 * 100) / 100
  const roundedNetworkCo2 = networkCo2 === undefined ? undefined : Math.round(networkCo2 * 100) / 100
  const roundedDevicesCo2 = devicesCo2 === undefined ? undefined : Math.round(devicesCo2 * 100) / 100

  return (
    <>
      <Co2TooltipTitle>
        <P bold>Détail des émissions de CO₂</P>
      </Co2TooltipTitle>
      <ParagraphContainer>
        <P bold>Réseau : {roundedNetworkCo2 !== undefined ? roundedNetworkCo2 : '- '}g</P>
        <P small>
          {`Les émissions dépendent de la source d'énergie (fossile ou renouvelable) utilisée pour
            alimenter l'infrastructure numérique, y compris les centres de données et les réseaux de transmission.`}
        </P>
      </ParagraphContainer>
      <ParagraphContainer>
        <P bold>Périphériques : {roundedDevicesCo2 !== undefined ? roundedDevicesCo2 : '- '}g</P>
        <P small>
          {`Les appareils utilisés émettent du CO2 pour visualiser les publicités.
            Les émissions dépendent de la consommation électrique de l'appareil et de la source d'énergie.`}
        </P>
      </ParagraphContainer>
      <ParagraphContainer>
        <P bold>Serveur : {roundedServerCo2 !== undefined ? roundedServerCo2 : '- '}g</P>
      </ParagraphContainer>
    </>
  )
}

export default Co2Tooltip
