import { ColumnItem } from "../../../../../components/Table/types"
import { AlignEnum } from "../../../../../types/common"

export const platformsTableColumns: ColumnItem[] = [
  {
    name: "platform",
    label: "Plateforme",
    width: '300px',
    align: AlignEnum.LEFT
  },
  {
    name: 'co2',
    label: 'CO2',
    isSortable: true,
    width: '250px',
    align: AlignEnum.LEFT,
    unit: ' g'
  },
  {
    name: "clicks",
    label: "Clics",
    isSortable: true,
    width: '250px',
    align: AlignEnum.LEFT
  },
  {
    name: "impressions",
    label: "Impressions",
    isSortable: true,
    width: '250px',
    align: AlignEnum.LEFT
  },
  {
    name: "cost",
    label: "Coût",
    isSortable: true,
    width: '250px',
    align: AlignEnum.LEFT,
    unit: ' €'
  }
]
