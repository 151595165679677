import styled from "@emotion/styled"

import { Margin } from "../../../../constants"
import { AlignEnum } from "../../../../types/common"

export const Cell = styled.div<{
  bold?: boolean
  width: string
  align?: AlignEnum
}>`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  width: ${(props) => props.width};
  height: 100%;
  box-sizing: border-box;
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  padding: 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  justify-content: ${({ align }) =>
    align === AlignEnum.LEFT
      ? 'flex-start'
      : align === AlignEnum.CENTER
      ? 'center'
      : 'flex-end'};
`