import React from 'react'

import { Co2TooltipTitle, ParagraphContainer } from './styles'
import { P } from '../../../../../../components/Text'

const Co2Tooltip = () => {
  return (
    <>
      <Co2TooltipTitle>
        <P bold>Détail des émissions de CO₂</P>
      </Co2TooltipTitle>
      <ParagraphContainer>
        <P bold>Réseau : - g</P>
        <P small>
          {`Les émissions dépendent de la source d'énergie (fossile ou renouvelable) utilisée pour
            alimenter l'infrastructure numérique, y compris les centres de données et les réseaux de transmission.`}
        </P>
      </ParagraphContainer>
      <ParagraphContainer>
        <P bold>Périphériques : - g</P>
        <P small>
          {`Les appareils utilisés émettent du CO2 pour visualiser les publicités.
            Les émissions dépendent de la consommation électrique de l'appareil et de la source d'énergie.`}
        </P>
      </ParagraphContainer>
    </>
  )
}

export default Co2Tooltip
