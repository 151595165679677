import React, { ReactNode } from 'react'

import { CardContent, Container, titleStyle } from './style'
import { H3, P } from '../../../../../components/Text'

type PropsType = {
  unit: string
  value: string
  width: string
  icon?: ReactNode
  height?: string
  color?: string
  title?: string
}

const StatCard = ({
  icon,
  width,
  height,
  value,
  unit,
  color,
  title
}: PropsType) => {
  return (
    <Container width={width} height={height}>
      {title && <H3 style={titleStyle}>{title}</H3>}
      <CardContent>
        <P style={{ color, fontSize: '64px', fontWeight: 600 }}>
          {value}
          {unit}
        </P>
        {icon}
      </CardContent>
    </Container>
  )
}

export default StatCard
