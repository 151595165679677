import { backendURL } from "../../../constants"
import { axiosPost, checkIsAxiosPostOutputSuccess } from "../../../helpers/axios/post"
import { checkIfUpdateEmailVerificationApiSuccessOutput } from "./types"
import { ErrorType } from "../../../types/error"

type UpdateVerificationEmailStatusInput = {
  token: string
}

export const updateVerificationEmailStatus = async ({ token }: UpdateVerificationEmailStatusInput): Promise<{updated: boolean} | ErrorType> => {
  const response = await axiosPost({
    url: `${backendURL}/verify`,
    payload: {token}
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkIfUpdateEmailVerificationApiSuccessOutput(response.data)) {
      return { updated: true }
    }
  }

  return { error: 'An error occured while verifying email' }
}