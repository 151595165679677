import React from 'react'

import YeticLogo from '../../../components/YeticLogo'
import { H2 } from '../../../components/Text'
import Button from '../../../components/Button'
import { MainWrapper, StyledWrapper, LogoWrapper, ContentWrapper } from '../style'

type RegisterSuccessProps = {
  handleGoToLogin: () => void
}

const RegisterSuccess = ({
  handleGoToLogin
}: RegisterSuccessProps) => {
  return (
    <MainWrapper>
      <StyledWrapper>
        <LogoWrapper>
          <YeticLogo width='120px' />
        </LogoWrapper>
        <ContentWrapper>
          <H2 style={{ textAlign: 'center' }}>
            Votre compte a été créé avec succès !
          </H2>
          <Button onClick={handleGoToLogin} width="fit-content">
            Se connecter
          </Button>
        </ContentWrapper>
      </StyledWrapper>
    </MainWrapper>
  )
}

export default RegisterSuccess
