import { DownloadReportingOutputType } from "../../types"

export const downloadDataReportingMock: DownloadReportingOutputType = [
  {
    date: '2024-05-01',
    clicks: 100,
    impressions: 1000,
    cost: 1000,
    co2: 100,
    impact: 0.1,
  },
  {
    date: '2024-05-02',
    clicks: 200,
    impressions: 2000,
    cost: 2000,
    co2: 200,
    impact: 0.2
  }
]