import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { ImpactReportingOutputType } from "../../types"
import { checkIfImpactReporting } from "../../types/checkers"
import { impactReportingMock } from "../mock/impact"

type GetBalanceSheetImpactReportingInputType = {
  balanceSheetId: string
}

export const getBalanceSheetImpactReporting = async ({
  balanceSheetId
}: GetBalanceSheetImpactReportingInputType): Promise<ImpactReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/impact`
    })

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfImpactReporting(response.data)) {
        return response.data
      } else {
        return null
      }
    }

    return null
  } catch (_) {
    return null
  }
}
