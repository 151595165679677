import React, { ReactNode } from 'react'

import { FormHeader, LoginRedirectWrapper, pErrorStyle } from './style'
import Button from '../../../components/Button'
import { Margin } from '../../../constants'
import { H2, P } from '../../../components/Text'
import Link from '../../../components/Text/Link'
import YeticLogo from '../../../components/YeticLogo'
import { ContentWrapper, LogoWrapper, MainWrapper, StyledWrapper } from '../style'
import YetiPanel from '../../../components/YetiPanel'

type RegisterFormProps = {
  children: ReactNode
  description?: string
  handleSubmit: (e: React.FormEvent) => void
  goToLogin: () => void
  apiError: string
  loading: boolean
}

const RegisterForm = ({
  children,
  description,
  handleSubmit,
  goToLogin,
  apiError,
  loading
}: RegisterFormProps) => {
  return (
    <MainWrapper onSubmit={handleSubmit}>
      <StyledWrapper>
          <LogoWrapper>
            <YeticLogo width='120px' />
          </LogoWrapper>
          <ContentWrapper>
            <FormHeader>
              <H2>Créez votre compte</H2>
              {description && <P>{description}</P>}
            </FormHeader>
            {children}
            <Button
              width="100%"
              marginTop={Margin.m4}
              loading={loading}
            >
              S'inscrire
            </Button>
            <LoginRedirectWrapper>
              <P>Vous avez déjà un compte ?</P>
              <Link
                onClick={goToLogin}
              >
                Connectez-vous
              </Link>
            </LoginRedirectWrapper>
            <P style={pErrorStyle}>{apiError}</P>
          </ContentWrapper>
      </StyledWrapper>
      <YetiPanel width='50vw' />
    </MainWrapper>
  )
}

export default RegisterForm
