export enum RegisterApiErrorCodes {
  UNKNOWN = 'UNKNOWN',
  MISSING_PARAMS = 'MISSING_PARAMS',
  INVALID_PHONE_NUMBER_FORMAT = 'INVALID_PHONE_NUMBER_FORMAT',
  INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT',
  INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT',
  INVALID_FIRST_NAME_FORMAT = 'INVALID_FIRST_NAME_FORMAT',
  INVALID_LAST_NAME_FORMAT = 'INVALID_LAST_NAME_FORMAT',
  INVALID_NAME_FORMAT = 'INVALID_NAME_FORMAT',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NOT_FOUND = 'NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  COMPANY_ALREADY_EXISTS = 'COMPANY_ALREADY_EXISTS'
}

export const checkIfRegisterApiErrorCode = (val: any): val is RegisterApiErrorCodes => {
  return Object.values(RegisterApiErrorCodes).includes(val)
}

const postCompanyApiErrorMessages: Record<RegisterApiErrorCodes, string> = {
  'UNKNOWN': 'Une erreur inconnue est survenue',
  'MISSING_PARAMS': 'Au moins un champ est manquant',
  'INVALID_EMAIL_FORMAT': `Le format de l'email est invalide`,
  'INVALID_FIRST_NAME_FORMAT': 'Le format du prénom est invalide',
  'INVALID_LAST_NAME_FORMAT': 'Le format du nom de famille est invalide',
  'INVALID_NAME_FORMAT': `Le format du nom de l'entreprise est invalide`,
  'INVALID_PASSWORD_FORMAT': `Le format du mot de passe est invalide`,
  'INVALID_PHONE_NUMBER_FORMAT': 'Le format du numéro de téléphone est invalide',
  'INVALID_CREDENTIALS': 'Mot de passe incorrect. Veuillez réessayer.',
  'NOT_FOUND': 'La ressource est introuvable',
  'USER_NOT_FOUND': 'Utilisateur introuvable',
  'COMPANY_ALREADY_EXISTS': 'Votre compte est déjà associée à cette entreprise. Veuillez vous connecter ou renseigner une autre entreprise.'
}

export const getRegisterErrorMessageFromErrorCode = (errorCode: RegisterApiErrorCodes) => {
  return postCompanyApiErrorMessages[errorCode]
}
