import { backendURL } from '../../../../constants'
import {
  axiosPost,
  checkIsAxiosPostOutputSuccess,
} from '../../../../helpers/axios/post'
import {
  checkIfRegisterApiErrorCode,
  getRegisterErrorMessageFromErrorCode,
  RegisterApiErrorCodes,
} from './errors'
import { checkIfRegisterOutputSuccess, RegisterApiInput, RegisterApiOutput } from './types'

export const registerApi = async ({
  email,
  firstName,
  lastName,
  phone,
  password,
  companyName
}: RegisterApiInput): Promise<RegisterApiOutput> => {
  const response = await axiosPost({
    url: `${backendURL}/register`,
    payload: {
      email,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(phone && { phone }),
      password,
      companyName
    },
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkIfRegisterOutputSuccess(response.data)) {
      return response.data
    } else {
      return {
        error: getRegisterErrorMessageFromErrorCode(
          RegisterApiErrorCodes.UNKNOWN
        ),
      }
    }
  } else {
    if (checkIfRegisterApiErrorCode(response.error?.data?.code)) {
      return {
        error: getRegisterErrorMessageFromErrorCode(
          response.error.data.code
        ),
      }
    } else {
      return {
        error: getRegisterErrorMessageFromErrorCode(
          RegisterApiErrorCodes.UNKNOWN
        ),
      }
    }
  }
}
