export enum PageEnum {
  HOME = 'HOME',
  REPORTING = 'REPORTING',
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN',
  BALANCE_SHEET = 'BALANCE_SHEET',
  PROVISIONING = 'PROVISIONING',
  INVOICES = 'INVOICES'
}

export const pageByEndpoint = {
  '/': PageEnum.HOME,
  '/reporting': PageEnum.REPORTING,
  '/register': PageEnum.REGISTER,
  '/login': PageEnum.LOGIN,
  '/balance-sheet': PageEnum.BALANCE_SHEET,
  '/provisioning': PageEnum.PROVISIONING,
  '/invoices': PageEnum.INVOICES
}

export const endpointByPage = Object.entries(pageByEndpoint).reduce<Record<PageEnum, string>>((acc, [endpoint, page]) => {
  acc[page] = endpoint;
  return acc;
}, {} as Record<PageEnum, string>)
