import styled from '@emotion/styled'

import FontSize from '../../constants/fontSize'
import Colors from '../../constants/colors'
import Margin from '../../constants/margin'
import Padding from '../../constants/padding'
import { maxTableContentHeight } from '../../constants/height'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${Margin.m4};
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${FontSize.base};
  border: 1px solid ${Colors.darkBlue_015};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${Colors.white};
  margin-bottom: ${Margin.m2};
`

export const TableHeader = styled.div`
  display: flex;
  padding: ${Padding.p5} 0;
  border-bottom: 1px solid ${Colors.darkBlue_015};
  background-color: ${Colors.grey};
`

export const TableRowsContainer = styled.ul<{maxHeight: number | undefined}>`
  display: flex;
  flex-direction: row;
  tablelist-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: ${props => props.maxHeight ? `${props.maxHeight}px` : `${maxTableContentHeight}px`};
  flex-wrap: wrap;
  overflow-y: scroll;
  `

export const NoResult = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`