import { backendURL } from '../../../../constants'
import {
  axiosPost,
  checkIsAxiosPostOutputSuccess,
} from '../../../../helpers/axios/post'
import { checkIfLogoutApiErrorCode, getLogoutErrorMessageFromErrorCode, LogoutApiErrorCodes } from './errors'
import { checkIfLogoutOutputSuccess, LogoutApiOutput } from './types'

export const logoutApi = async (): Promise<LogoutApiOutput> => {
  const response = await axiosPost({
    url: `${backendURL}/logout`,
    payload: {}
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkIfLogoutOutputSuccess(response.data)) {
      return response.data
    } else {
      return {
        error: getLogoutErrorMessageFromErrorCode(
          LogoutApiErrorCodes.UNKNOWN
        ),
      }
    }
  } else {
    if (checkIfLogoutApiErrorCode(response.error?.data?.code)) {
      return {
        error: getLogoutErrorMessageFromErrorCode(
          response.error.data.code
        ),
      }
    } else {
      return {
        error: getLogoutErrorMessageFromErrorCode(
          LogoutApiErrorCodes.UNKNOWN
        ),
      }
    }
  }
}
