import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import Colors from '../../constants/colors'

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const AnimatedLoaderContainer = styled.div<{
  size: number
  color?: string
}>`
  display: inline-block;

  &:after {
    content: ' ';
    display: block;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: 50%;
    border: ${(props) => props.size / 7}px solid #fff;
    border-color: ${(props) => props.color || Colors.darkBlue}
      ${(props) => props.color || Colors.darkBlue} transparent;
    animation: ${rotateAnimation} 1s linear infinite;
  }
`
