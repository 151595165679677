import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import { P } from '../../../../../components/Text'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import { formatNumber } from '../../../../../helpers/rounds'
import KpiCard from '../KpiCard'

type PropsType = {
  cpc?: number
  isLoading: boolean
}

export const CPCCard = ({
  cpc,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.cpc}
      title="CPC"
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : cpc !== undefined ? (
          formatNumber(cpc) + ' €'
        ) : 'N/A'
      }
      tooltipContent={<P small>{`Le coût par clic, soit le montant moyen dépensé pour chaque clic sur les annonces`}</P>}
    />
  )
}
