import { CSSProperties } from "react"
import styled from "@emotion/styled"

import { Margin } from "../../../../../constants"

export const multiSelectStyle: CSSProperties = {
  marginBottom: Margin.m7
}

export const paragraphStyle: CSSProperties = { 
  marginBottom: Margin.m3,
}

export const trashIconStyle: CSSProperties = {
  cursor: 'pointer'
}

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${Margin.m3};
`

export const tableNameStyle: CSSProperties = {
  maxWidth: '90%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}