import React from 'react'

import ActionsButton, { checkIfActionItemArray } from '../../ActionsButton'
import TableBodyCell from '../BodyCell'
import { ColumnItem, ElementItem } from '../types'
import { actionButtonSize, IconContainer, Row, RowContent } from './styles'

type TableRowProps = {
  rowIndex: number
  row: ElementItem
  columns: ColumnItem[]
  defaultWidth: string
}

const TableRow = ({rowIndex, row, columns, defaultWidth}: TableRowProps) => {
  const tableBodyCell = Object.keys(row).map((key, index) => {
    const currentValue = row[key]
    if (
      key === 'id' ||
      checkIfActionItemArray(currentValue) ||
      typeof currentValue === 'function'
    ) {
      return null
    }

    return (
      <TableBodyCell key={index} keyValue={key} columns={columns} defaultWidth={defaultWidth} row={row} currentValue={currentValue}/>
    )
  })

  return (
    <Row index={rowIndex} isClickable={!!row.onClick}>
      <RowContent>
        {tableBodyCell}
        {row.actions && row.actions.length > 0 ? (
          <IconContainer>
            <ActionsButton
              actions={row.actions}
              size={actionButtonSize}
            />
          </IconContainer>
        ) : null}
      </RowContent>
    </Row>
  )
}

export default TableRow