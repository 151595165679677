import React, { useState } from 'react'

import Input from '../../../../components/Input'

type RegisterCompanyNameInputProps = {
  companyName: string
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  formError: string
}

const RegisterCompanyNameInput = ({
  companyName,
  handleFieldChange,
  formError,
}: RegisterCompanyNameInputProps) => {
  return (
    <Input
      value={companyName}
      onChange={(e) => handleFieldChange(e)}
      containerWidth="100%"
      type="text"
      label={`Nom de l'entreprise`}
      error={formError}
    />
  )
}

export default RegisterCompanyNameInput
