import React from "react"

import { StyledLetter, StyledLettersContainer, StyledPicture, StyledWrapper } from "./style"
import { H2 } from "../Text"
import { Colors, Margin } from "../../constants"

const YetiPicture = require('../../../public/assets/images/questionning-yeti.png')

type PropsType = {
  width: string
}

const YetiPanel = ({
  width
}: PropsType) => {
  return (
    <StyledWrapper width={width}>
      <H2 style={{ color: Colors.white, marginBottom: Margin.m10, width: '400px', textAlign: 'right' }}>Mesurez l'impact de vos campagnes digitales</H2>
        <StyledLettersContainer>
          <StyledLetter fontSize='72px'>G</StyledLetter>
          <StyledLetter fontSize='78px'>R</StyledLetter>
          <StyledLetter fontSize='84px'>R</StyledLetter>
          <StyledLetter fontSize='90px'>R</StyledLetter>
          <StyledLetter fontSize='96px'>R</StyledLetter>
          <StyledLetter fontSize='86px'>...</StyledLetter>
        </StyledLettersContainer>
        <StyledPicture src={YetiPicture} />
    </StyledWrapper>
  )
}

export default YetiPanel
