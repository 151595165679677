export const roundToTwoDecimals = (nbToRound: number) => {
  return Math.round(nbToRound * 100) / 100
}

export const formatNumber = (number: number): string => {
  if (number < 1000) {
    return roundToTwoDecimals(number).toString().replace('.', ',')
  } else if (number < 1000000) {
    return `${roundToTwoDecimals(number / 1000)} k`.replace('.', ',')
  } else if (number < 1000000000) {
    return `${roundToTwoDecimals(number / 1000000)} M`.replace('.', ',')
  } else {
    return `${roundToTwoDecimals(number / 1000000000)} b`.replace('.', ',')
  }
}

export const roundToTwoSignificantDigits = (number: number) => {
  const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(number)))
  const roundedNumber =
    orderOfMagnitude !== 0
      ? Math.ceil(number / orderOfMagnitude) * orderOfMagnitude
      : 0
  return roundedNumber
}
