const Colors = {
  transparent: 'transparent',
  white: '#fff',
  veryLightGrey: '#fcfcfc',
  lightGrey: '#fafafa',
  grey: '#f4f7f8',
  mediumGrey: '#d6d6d6',
  darkGrey: '#b8b8b8',
  veryDarkGrey: '#848694',
  greyBorder: '#e6e6e6',
  greyHoverBorder: '#d6d6d6',
  black: '#000',
  darkBlue: '#142A4C',
  darkBlue_015: 'rgba(20, 42, 76, 0.15)',
  hoverDarkBlue: '#204a8a',
  darkBlueDisabled: '#848FA1',
  blue: '#458df7',
  blue_01: 'rgba(69, 141, 247, 0.1)',
  leftMenuItemHover: 'rgba(20, 42, 76, 0.06)',
  darkYellow: '#ffcc00',
  yellow: '#FFD700',
  red: '#EE0022',
  green: '#00B2B2'
}

export const HighlightColors = {
  co2: '#50514F',
  impact: '#59CECE',
  co2perEuro: '#765883',
  clicks: '#0D00FF',
  impressions: '#e752a3',
  cost: 'orange',
  cpc: '#9300FF',
  ctr: 'green'
}

export default Colors