import { BalanceSheetApiStatusEnum, checkIsBalanceSheetApiStatus } from "../../../../constants/api"

export type UpdateBalanceSheetStatus = {
  id: string,
  status: BalanceSheetApiStatusEnum
}

export const checkUpdateBalanceSheetStatus = (value: any): value is UpdateBalanceSheetStatus => {
  return value && value.id && typeof value.id === 'string'
    && value.status && typeof value.status === 'string' 
    && checkIsBalanceSheetApiStatus(value.status)
}