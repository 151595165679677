export enum GetBalanceSheetReportingErrorEnum {
  BALANCE_SHEET_ID = 'CAMPAIGN_ID',
  UNKNOWN = 'UNKNOWN',
  AUTH_FAILED = 'AUTH_FAILED',
  INVALID_DATE = 'INVALID_DATE',
  MISSING_SECTION = 'MISSING_SECTION',
  WRONG_SECTION = 'WRONG_SECTION'
}

export const GetBalanceSheetReportingErrorEnumTranslation: Record<
  GetBalanceSheetReportingErrorEnum,
  string
> = {
  [GetBalanceSheetReportingErrorEnum.BALANCE_SHEET_ID]: "L'id du bilan est requis",
  [GetBalanceSheetReportingErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [GetBalanceSheetReportingErrorEnum.AUTH_FAILED]: "L'authentification a échoué",
  [GetBalanceSheetReportingErrorEnum.INVALID_DATE]:
    'Les dates doivent être au format YYYY-MM-DD',
  [GetBalanceSheetReportingErrorEnum.MISSING_SECTION]: 'La section est requise',
  [GetBalanceSheetReportingErrorEnum.WRONG_SECTION]:
    'La section est requise et doit être correctement formatée'
}