import React, { useEffect, useRef, useState } from 'react'

import { ClickImpressionCostTabContainer } from './styles'
import { formatClickImpressionCostData } from './helpers/formatData'
import Table from '../../../../../components/Table'
import { AlignEnum, SortEnum } from '../../../../../types/common'
import { GlobalReportingOutputType } from '../../../../../services/api/reporting/types'
import { formatAnalyticsHeaderData } from '../../../../../helpers/charts'
import LineChart from '../../../../../components/Chart/LineChart'
import DefaultLineChartTooltip from '../../../../../components/Chart/LineChart/Tooltips'
import { renderDefaultTooltip } from '../../../../../components/Chart/LineChart/Tooltips/Default/renderer'
import { Margin } from '../../../../../constants'

type ClickImpressionCostTabProps = {
  reportingData: GlobalReportingOutputType
}

const ClickImpressionCostTab = ({
  reportingData
}: ClickImpressionCostTabProps) => {
  const [chartWidth, setChartWidth] = useState(400)
  const { kpi, tableData } =
    formatClickImpressionCostData(reportingData)
  const initialSelectionNames = ['Clics', 'Impressions', 'CO2']
  const [tablePage, setTablePage] = useState(1)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateChartWidth = () => {
      if (containerRef.current) {
        setChartWidth(containerRef.current.offsetWidth)
      }
    }

    updateChartWidth()

    window.addEventListener('resize', updateChartWidth)
    return () => {
      window.removeEventListener('resize', updateChartWidth)
    }
  }, [containerRef.current])

  const tableColumns = [
    {
      name: 'day',
      label: 'Jour',
      isSortable: true,
      width: '28%',
      align: AlignEnum.LEFT
    },
    {
      name: 'clicks',
      label: 'Clics',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'impressions',
      label: 'Impressions',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'co2',
      label: 'CO2',
      isSortable: true,
      width: '18%'
    },
    {
      name: 'cost',
      label: 'Coût',
      unit: '€',
      isSortable: true,
      width: '18%'
    }
  ]

  const defaultTableSort = {
    name: 'day',
    order: SortEnum.DESC
  }

  const chartId = `id${Math.random().toString(36).substring(2)}`

  const TooltipComponent = DefaultLineChartTooltip
  const renderTooltip = renderDefaultTooltip

  const cardWidth = '100%'

  return (
    <ClickImpressionCostTabContainer ref={containerRef}>
      <LineChart
        data={kpi}
        initialSelectionNames={initialSelectionNames}
        chartWidth={chartWidth}
        cardWidth={cardWidth}
        formatHeaderData={(data, initialSelectionNames) =>
          formatAnalyticsHeaderData(data, initialSelectionNames)
        }
        rangeSelectorData={null}
        tooltipComponent={TooltipComponent}
        renderTooltip={renderTooltip}
        chartId={chartId}
        style={{ marginBottom: Margin.m8 }}
      />
      <Table
          title="Performances des annonces par jour"
          columns={tableColumns}
          elements={tableData}
          sort={defaultTableSort}
          pagination={{
            totalItems: tableData.length,
            itemsByPage: 5,
            currentPage: tablePage,
            handleChangePage: setTablePage
          }}
          download
      />
    </ClickImpressionCostTabContainer>
  )
}

export default ClickImpressionCostTab
