import React, { useEffect, useRef, useState } from 'react'

import formatImpactData from './helpers/formatData'
import { ImpactReportingOutputType } from '../../../../../services/api/reporting/types'
import StatCard from '../StatCard'
import { LeafIcon } from '../../../../../components/Icons'
import { formatNumber } from '../../../../../helpers/rounds'
import { HighlightColors } from '../../../../../constants/colors'
import Table from '../../../../../components/Table'
import { TabContentWrapper } from '../style'
import { chartGap } from '../../../style'
import BarChart from '../../../../../components/Chart/BarChart'
import LineChart from '../../../../../components/Chart/LineChart'
import {
  defaultTablePerDeviceSort,
  defaultTablePerSourceSort,
  defaultTableSort,
  tableColumnsCO2,
  tableColumnsCO2PerDevice,
  tablesColumnsCO2PerSource
} from './constants'
import PieChart from '../../../../../components/Chart/PieChart'

type PropsType = {
  reportingData: ImpactReportingOutputType
}

const ImpactTab = ({ reportingData }: PropsType) => {
  const [chartWidth, setChartWidth] = useState(400)
  const [currentCO2ByDateTablePage, setCurrentCO2ByDateTablePage] = useState(1)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateChartWidth = () => {
      if (containerRef.current) {
        setChartWidth((containerRef.current.offsetWidth - chartGap) / 2)
      }
    }

    updateChartWidth()

    window.addEventListener('resize', updateChartWidth)
    return () => {
      window.removeEventListener('resize', updateChartWidth)
    }
  }, [containerRef.current])

  const {
    impactLineChartData,
    impactBarChartCO2PerDevice,
    impactPieChartCO2PerSource,
    impactTableDataCO2,
    impactTableDataCO2PerSource,
    impactTableDataCO2PerDevice,
    co2
  } = formatImpactData(reportingData)

  const cardWidth = `calc(50% - ${chartGap / 2}px)`
  const pieChartWidth = chartWidth - 200

  return (
    <>
      <TabContentWrapper ref={containerRef}>
        <PieChart
          title='Emissions de CO₂ par source'
          data={impactPieChartCO2PerSource}
          chartId='impact-co2-per-source'
          width={cardWidth}
          chartWidth={pieChartWidth}
          unit=' g'
        />
        <StatCard
          icon={<LeafIcon height={'100px'} />}
          value={formatNumber(co2)}
          unit={'g'}
          width={cardWidth}
          color={HighlightColors.co2}
          title={'Emissions de CO₂ total'}
        />
        <LineChart
          chartId='impactLineChart'
          title='Emissions de CO₂ par jour'
          rangeSelectorData={null}
          data={impactLineChartData}
          chartWidth={chartWidth}
          cardWidth={cardWidth}
          maxTicks={4}
        />
        <BarChart
          data={impactBarChartCO2PerDevice}
          title={'Emissions de CO₂ par appareil'}
          chartWidth={chartWidth}
          cardWidth={cardWidth}
          horizontal
        />
        <Table
          title={`Emissions de CO₂ par jour`}
          columns={tableColumnsCO2}
          elements={impactTableDataCO2}
          sort={defaultTableSort}
          pagination={{
            totalItems: impactTableDataCO2.length,
            itemsByPage: 6,
            currentPage: currentCO2ByDateTablePage,
            handleChangePage: setCurrentCO2ByDateTablePage
          }}
          download
        />
        <Table
          title={`Emissions de CO₂ par source`}
          columns={tablesColumnsCO2PerSource}
          elements={impactTableDataCO2PerSource}
          sort={defaultTablePerSourceSort}
          download
        />
        <Table
          title={'Emissions de CO₂ par appareil'}
          columns={tableColumnsCO2PerDevice}
          elements={impactTableDataCO2PerDevice}
          sort={defaultTablePerDeviceSort}
          download
        />
      </TabContentWrapper>
    </>
  )
}

export default ImpactTab
