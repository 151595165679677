import React from 'react'

import Tabs from '../../../components/Tabs'
import { Colors } from '../../../constants'
import { tabContainerStyle, tabStyle } from './style'

type StyledTabsProps = {
  items: string[]
  activeTabIndex: number
  handleTabClick: (index: number) => void
}

const StyledTabs = ({
  items,
  activeTabIndex,
  handleTabClick
}: StyledTabsProps) => {
  return <Tabs
      items={items}
      activeTabIndex={activeTabIndex}
      handleTabClick={handleTabClick}
      hasSeparator={false}
      containerStyle={tabContainerStyle}
      tabStyle={tabStyle}
      color={{
        tab: Colors.transparent,
        text: Colors.darkBlue,
        activeTab: Colors.darkBlue,
        activeText: Colors.white,
        hoverTab: Colors.darkBlue,
        hoverText: Colors.white
      }}
    />
}

export default StyledTabs