import React from 'react'

import { Colors } from '../../constants'
import { IconType } from './types'

const AddIcon = ({ height = '12px', width = '12px', style, color = Colors.darkBlue }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      stroke={color}
      fill="none"
      strokeLinecap='round'
      strokeWidth={1.5}
      viewBox="0 0 14 14"
    >
      <g
        transform="translate(-277 -241)"
      >
        <line
          y2="13"
          transform="translate(284 241.5)"
        />
        <line
          x1="13"
          transform="translate(277.5 248)"
        />
      </g>
    </svg>
  )
}

export default AddIcon
