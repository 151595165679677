import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import { Colors, Padding } from '../../../../../constants'

export const Container = styled.div<{ width: string; height?: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height ?? 'auto'};
  border-radius: 10px;
  box-shadow: 0px 0px 3px #142a4c80;
  background-color: ${Colors.white};
  box-sizing: border-box;
  padding: ${Padding.p8};
  display: flex;
  flex-direction: column;
`

export const CardContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export const titleStyle: CSSProperties = {
  width: '100%',
  textAlign: 'center',
  fontWeight: '700'
}
