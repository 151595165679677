import React from 'react'

import { P } from '../../components/Text'
import { ErrorPageContainer } from './style'
import Button from '../../components/Button'
import PageTemplate from '../../templates/Page'

type ErrorPageProps = {
  message?: string
  action?: {
    text: string
    onClick: () => void
  }
}

const ErrorPage = ({ message, action }: ErrorPageProps) => {
  return (
    <PageTemplate>
      <ErrorPageContainer>
        <P
          big
          style={{
            textAlign: 'center',
            maxWidth: '800px'
          }}
        >
          {message ?? 'Une erreur est survenue.'}
        </P>
        <Button
          onClick={
            action
              ? () => {
                  action.onClick()
                }
              : () => {
                  window.location.reload()
                }
          }
          style={{ width: 'fit-content' }}
        >
          {action ? action.text : 'Recharger la page'}
        </Button>
      </ErrorPageContainer>
    </PageTemplate>
  )
}

export default ErrorPage
