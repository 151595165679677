import styled from '@emotion/styled'

import Margin from '../../constants/margin'
import Colors from '../../constants/colors'
import Padding from '../../constants/padding'

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const TabsItemWithSeparator = styled.div<{
  isActive: boolean
  tabColor?: string
  activeColor?: string
  hoverColor?: string
  textColor?: string
  activeTextColor?: string
  hoverTextColor?: string
}>`
  text-align: center;
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  padding: ${Padding.p3} 0;
  background-color: ${(props) =>
    props.activeColor && props.isActive ? props.activeColor : props.tabColor};

  color: ${(props) =>
    props.isActive
      ? props.activeTextColor ?? Colors.darkBlue
      : props.textColor ?? Colors.darkBlueDisabled};

  &:hover {
    ${(props) =>
      props.hoverColor &&
      `
      background-color: ${props.hoverColor};
    `}

    ${(props) =>
      props.hoverTextColor &&
      `
      color: ${props.hoverTextColor};
    `}
  }
`

export const TabText = styled.span`
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
`

export const TabsSeparator = styled.span`
  margin: 0 ${Margin.m6};
  border-left: 2px solid ${Colors.darkBlue};
  height: 18px;
`
