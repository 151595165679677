import React from 'react'

import Modal from '../../../components/Modal'
import Input from '../../../components/Input'
import { StyledApiError, StyledForm } from './style'
import { BalanceSheetCreationFormData } from '../types'
import { ApiPlatformEnum } from '../../../constants/api'
import ChoiceItems from '../../../components/ChoiceItems'
import { GoogleIcon, MetaIcon, TiktokIcon } from '../../../components/Icons'

type BalanceSheetCreationModalProps = {
  onClose: () => void
  onCancel: () => void
  onSubmit: () => void
  formData: BalanceSheetCreationFormData
  onFormDataChange: (
    key: keyof BalanceSheetCreationFormData,
    value: string
  ) => void
  getFieldError: (field: keyof BalanceSheetCreationFormData) => string
  onPlatformSelect: (platform: string) => void
  apiError: string
}

const BalanceSheetCreationModal = ({
  onClose,
  onCancel,
  onSubmit,
  formData,
  onFormDataChange,
  onPlatformSelect,
  getFieldError,
  apiError
}: BalanceSheetCreationModalProps) => {
  const { name, startDate, endDate, platforms } = formData

  const choiceItemsOptions = [
    {
      icon: <GoogleIcon />,
      label: ApiPlatformEnum.GOOGLE,
    },
    {
      icon: <MetaIcon />,
      label: ApiPlatformEnum.META,
    },
    {
      icon: <TiktokIcon />,
      label: ApiPlatformEnum.TIKTOK,
    },
  ]

  return (
    <Modal
      title={'Créer un bilan'}
      description='Veuillez renseigner les informations nécessaires pour créer un bilan.'
      onClose={onClose}
      mainButton={{
        label: 'Créer mon bilan',
        onClick: onSubmit
      }}
      secondaryButton={{
        label: 'Annuler',
        onClick: onCancel
      }}
      height='608px'
    >
      <StyledForm>
        <Input
          label='Nom du bilan'
          placeholder='Nom du bilan'
          value={name}
          type='text'
          onChange={(e) => onFormDataChange('name', e.target.value)}
          error={getFieldError('name')}
        />
        <Input
          label='Date de début'
          placeholder='Date de début'
          value={startDate}
          type='date'
          max={endDate}
          onChange={(e) => onFormDataChange('startDate', e.target.value)}
          error={getFieldError('startDate')}
        />
        <Input
          label='Date de fin'
          placeholder='Date de fin'
          value={endDate}
          type='date'
          min={startDate}
          onChange={(e) => onFormDataChange('endDate', e.target.value)}
          error={getFieldError('endDate')}
        />
        <ChoiceItems
          title='Plateformes'
          options={choiceItemsOptions}
          selectedOptions={formData.platforms}
          handleSelect={onPlatformSelect}
          optionsGroupName='platforms-enum'
          type='checkbox'
          error={getFieldError('platforms')}
        />
        {apiError && <StyledApiError>{apiError}</StyledApiError>}
      </StyledForm>
    </Modal>
  )
}

export default BalanceSheetCreationModal
