import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import { Margin } from '../../../../../constants'

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Margin.m5};
`

export const tableNameStyle: CSSProperties = {
  maxWidth: '90%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}