import React, { useEffect, useRef, useState } from 'react'

import { PlatformsReportingOutputType } from '../../../../../services/api/reporting/types'
import { chartGap } from '../../../style'
import { PlatformsTabContainer } from './style'
import { formatPlatformsData } from './helpers/formatData'
import Table from '../../../../../components/Table'
import { platformsTableColumns } from './constants'
import PieChart from '../../../../../components/Chart/PieChart'

type PlatformsTabProps = {
  reportingData: PlatformsReportingOutputType
}

const PlatformsTab = ({ reportingData }: PlatformsTabProps) => {
  const [chartWidth, setChartWidth] = useState(400)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateChartWidth = () => {
      if (containerRef.current) {
        setChartWidth((containerRef.current.offsetWidth - chartGap) / 2 - 200)
      }
    }

    updateChartWidth()

    window.addEventListener('resize', updateChartWidth)
    return () => {
      window.removeEventListener('resize', updateChartWidth)
    }
  }, [containerRef.current])

  const {
    co2PieChartData,
    clicksPieChartData,
    costPieChartData,
    impressionsPieChartData,
    tableElements,
  } = formatPlatformsData(reportingData)

  const cardWidth = `calc(50% - ${chartGap / 2}px)`

  return (
    <PlatformsTabContainer ref={containerRef}>
      <PieChart
        title="CO2"
        chartWidth={chartWidth}
        width={cardWidth}
        data={co2PieChartData}
        unit=' g'
        chartId='platforms-co2'
      />
      <PieChart
        title="Clics"
        chartWidth={chartWidth}
        width={cardWidth}
        data={clicksPieChartData}
        chartId='platforms-clicks'
      />
      <PieChart
        title="Coût"
        chartWidth={chartWidth}
        width={cardWidth}
        data={costPieChartData}
        unit=' €'
        chartId='platforms-cost'
      />
      <PieChart
        title="Impressions"
        chartWidth={chartWidth}
        width={cardWidth}
        data={impressionsPieChartData}
        chartId='platforms-impressions'
      />
      <Table
        title="Données par plateforme"
        columns={platformsTableColumns}
        elements={tableElements}
        download
      />
    </PlatformsTabContainer>
  )
}

export default PlatformsTab
