import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { PlatformsReportingOutputType, ReportingApiSectionEnum } from "../../types"
import { checkIfPlatformsReporting } from "../../types/checkers"

type GetBalanceSheetPlatformsReportingInputType = {
  balanceSheetId: string
}

export const getBalanceSheetPlatformsReporting = async ({
  balanceSheetId
}: GetBalanceSheetPlatformsReportingInputType): Promise<PlatformsReportingOutputType | null> => {
  try {
    const response = await axiosGet({
      url: `${backendURL}/reports/balance-sheets/${balanceSheetId}/${ReportingApiSectionEnum.platforms}`
    })

    if (
      checkIsAxiosGetOutputSuccess(response) &&
      checkIfPlatformsReporting(response.data)
    ) {
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}
