import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { StyledWrapper } from './style'
import LeftMenuItem from '../../LeftMenuItem'
import { InvoiceIcon, LogoutIcon } from '../../../../../components/Icons'
import { Colors } from '../../../../../constants'

type UserSubmenuProps = {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  handleLogout: () => void
  companyName: string | null
}

const UserSubmenu = ({
  isOpen,
  setIsOpen,
  handleLogout,
  companyName,
}: UserSubmenuProps) => {
  const menuRef = useRef<null | HTMLDivElement>(null)

  const navigate = useNavigate()

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  const handleNavigateInvoices = () => {
    navigate('/invoices')
  }

  return (
    <StyledWrapper ref={menuRef} isOpen={isOpen}>
      {companyName && (
        <LeftMenuItem
          onClick={handleNavigateInvoices}
          logo={<InvoiceIcon color={Colors.veryDarkGrey} />}
        >
          Factures
        </LeftMenuItem>
      )}
      <LeftMenuItem
        onClick={handleLogout}
        logo={<LogoutIcon color={Colors.veryDarkGrey} />}
      >
        Déconnexion
      </LeftMenuItem>
    </StyledWrapper>
  )
}

export default UserSubmenu
