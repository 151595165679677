import React, { ReactNode } from 'react'

import Box from '../../../../../components/Box'
import { StyledBox, StyledTooltipWrapper, boxStyle } from './style'
import { P } from '../../../../../components/Text'
import { Tooltip } from '../../../../../components/Tooltip'

type KpiCardProps = {
  backgroundColor: string
  color?: string
  title: string
  value: ReactNode
  tooltipContent: string | JSX.Element
}

const KpiCard = ({
  backgroundColor,
  color,
  title,
  value,
  tooltipContent
}: KpiCardProps) => {
  return (
    <Box
      backgroundColor={backgroundColor}
      width="20%"
      style={boxStyle}
      elements={[
        <React.Fragment key={`${title}-${value}`}>
          <StyledBox>
            <P
              style={{ color: color ?? 'white' }}
            >
              {title}
            </P>
            <StyledTooltipWrapper>
              <Tooltip light hasIcon content={tooltipContent} />
            </StyledTooltipWrapper>
          </StyledBox>
          <P
            style={{ color: color ?? 'white' }}
            big
          >
            {value}
          </P>
        </React.Fragment>
      ]}
    />
  )
}

export default KpiCard