import React, { CSSProperties } from "react"

import { StyledLogo } from "./style"
import LogoImg from "../../../public/assets/images/logo.png"

type YeticLogoProps = {
  style?: CSSProperties
  width?: string
}

const YeticLogo = ({ width = "200px", style }: YeticLogoProps) => {
  return <StyledLogo src={LogoImg} style={style} width={width} />
}

export default YeticLogo
