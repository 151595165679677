import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { LocationsReportingOutputType } from "../../types"
import { checkIfLocationsReporting } from "../../types/checkers"
import { locationReportingMock } from "../mock/location"

type GetCampaignLocationsReportingInputType = {
  campaignId: string
  startDate?: string
  endDate?: string
}

export const getCampaignLocationsReporting = async ({
  campaignId,
  startDate,
  endDate
}: GetCampaignLocationsReportingInputType): Promise<LocationsReportingOutputType | null> => {
  try {
    // const response = await axiosGet({
    //   url: `${backendURL}/reports/campaigns/${campaignId}/location${
    //     startDate ? `?startDate=${startDate}` : ''
    //   }${
    //     endDate
    //       ? startDate
    //         ? `&endDate=${endDate}`
    //         : `?endDate=${endDate}`
    //       : ''
    //   }`
    // })

    const response = {
      type: 'success',
      data: locationReportingMock
    }

    if (
      checkIsAxiosGetOutputSuccess(response) &&
      checkIfLocationsReporting(response.data)
    ) {
      return response.data
    }
    return null
  } catch (_) {
    return null
  }
}