import React from 'react'

import PageTemplate from '../../templates/Page'
import { Colors } from '../../constants'
import { ReportingIcon, TableIcon } from '../../components/Icons'
import LineCard from '../../components/LineCard'
import BalanceSheetLinecardContent from './BalanceSheetLinecardContent'
import ReportingLinecardContent from './ReportingLinecardContent'

const Home = () => {
  return (
    <PageTemplate title="Mesurez l'empreinte écologique de votre communication digitale">
      <LineCard
        icon={<TableIcon width="64px" height="64px" color={Colors.darkBlue} />}
        content={<BalanceSheetLinecardContent />}
      />
      <LineCard
        icon={
          <ReportingIcon width="64px" height="64px" color={Colors.darkBlue} />
        }
        content={<ReportingLinecardContent />}
      />
    </PageTemplate>
  )
}

export default Home
