import { CampaignFormat, CampaignPlatform } from "../../../types/formatAndPlatform"

export type CampaignFormatAndPlatform = { 
  platform: CampaignPlatform | null, 
  format: CampaignFormat | null
}

export enum CreationFlowStep {
  CAMPAIGN_CREATION = 'CAMPAIGN_CREATION',
  CAMPAIGN_DETAILS = 'CAMPAIGN_DETAILS',
  CAMPAIGN_LOGS = 'CAMPAIGN_LOGS',
  PROVISIONING_ENDED = 'PROVISIONING_ENDED'
}