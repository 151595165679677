import axios from 'axios'

import { QueryResponseType } from './types'

export type AxiosPostType = {
  url: string
  payload: any
  contentType?: string
}

type QueryPostOutputSuccessType = {
  type: QueryResponseType.SUCCESS
  data: any
}

type QueryPostOutputErrorType = {
  type: QueryResponseType.ERROR
  error: {
    data: {
      code: string
      message: string
    }
  }
}

export const checkIsAxiosPostOutputSuccess = (
  value: any
): value is QueryPostOutputSuccessType => {
  if (value && value.type && value.data) {
    if (value.type === QueryResponseType.SUCCESS) {
      return true
    }
  }
  return false
}

export const axiosPost = async ({
  url,
  payload,
  contentType
}: AxiosPostType): Promise<
  QueryPostOutputSuccessType | QueryPostOutputErrorType
> => {
  try {
    const response = await axios.post(url,
      payload,
      { 
        headers: {
          'Content-Type': contentType ?? 'application/json'
        },
        withCredentials: true 
      }
    )

    if (
      checkIsAxiosPostOutputSuccess({
        type: QueryResponseType.SUCCESS,
        data: response.data,
      })
    ) {
      return {
        type: QueryResponseType.SUCCESS,
        data: response.data,
      }
    }
    return {
      type: QueryResponseType.ERROR,
      error: response.data,
    }
  } catch (error: any) {
    return {
      type: QueryResponseType.ERROR,
      error: error.response ?? error.request ?? error,
    }
  }
}
