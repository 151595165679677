import styled from "@emotion/styled"

import { pagePadding } from "../../constants/padding"

export const chartGap = pagePadding

export const StyledReportingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`