import { 
  HighlightsReportingOutputType,
  ReportingBySectionOutputType,
  ReportingApiSectionEnum
} from "../../services/api/reporting/types"

export type ReportingBalanceSheetType = {
  id: string
  name: string
  startDate: string
  endDate: string
}

export type ReportingCampaignType = {
  id: string
  name: string
  type: string
  startDate: string
  endDate: string
}

export enum ReportingTabKeyEnum {
  impact = 'impact',
  global = 'global',
  platforms = 'platforms',
  campaigns = 'campaigns',
  devices = 'devices',
  locations = 'locations',
}

export const getReportingTabTranslation = (tabKey: ReportingTabKeyEnum) => {
  switch (tabKey) {
    case ReportingTabKeyEnum.impact:
      return 'Impact'
    case ReportingTabKeyEnum.global:
      return 'Par jour'
    case ReportingTabKeyEnum.devices:
      return 'Appareils'
    case ReportingTabKeyEnum.locations:
      return 'Localisations'
    case ReportingTabKeyEnum.platforms:
      return 'Par plateforme'
    case ReportingTabKeyEnum.campaigns:
      return 'Par campagne'
  }
}

export const mapReportingApiTabSection = (tabKey: ReportingTabKeyEnum) => {
  switch (tabKey) {
    case ReportingTabKeyEnum.impact:
      return ReportingApiSectionEnum.impact
    case ReportingTabKeyEnum.global:
      return ReportingApiSectionEnum.global
    case ReportingTabKeyEnum.devices:
      return ReportingApiSectionEnum.devices
    case ReportingTabKeyEnum.locations:
      return ReportingApiSectionEnum.locations
  }
}

export type ReportingHighlightsDataType = HighlightsReportingOutputType

export type ReportingDownloadDataType = {
  date: string
  clicks: number
  impressions: number
  cost: number
  co2: number
  impact: number
  co2perEuro: number
}[]

export type SectionDataType = ReportingBySectionOutputType

// TODO : this is temporary, to do in a clean way later
export const getReportingHeaderCampaignTypeTranslation = (type: string) => {
  switch (type) {
    case 'search':
      return 'Google Search'
    case 'display':
      return 'Google Display'
    case 'video':
      return 'Google Video (Youtube)'
    default:
      return ''
  }
}