import { backendURL } from "../../../../../constants"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../../helpers/axios/get"
import { DevicesReportingOutputType } from "../../types"
import { checkIfDevicesReporting } from "../../types/checkers"
import { devicesReportingMock } from "../mock/devices"

type GetCompanyDevicesReportingInputType = {
  startDate?: string
  endDate?: string
}

export const getCompanyDevicesReporting = async ({
  startDate,
  endDate
}: GetCompanyDevicesReportingInputType): Promise<DevicesReportingOutputType | null> => {
  try {
    // const response = await axiosGet({
    //   url: `${backendURL}/reports/campaigns/all/devices${
    //     startDate ? `?startDate=${startDate}` : ''
    //   }${
    //     endDate
    //       ? startDate
    //         ? `&endDate=${endDate}`
    //         : `?endDate=${endDate}`
    //       : ''
    //   }`
    // })

    const response = {
      type: 'success',
      data: devicesReportingMock
    }

    if (checkIsAxiosGetOutputSuccess(response)) {
      if (checkIfDevicesReporting(response.data)) {
        return response.data
      }
    }

    return null
  } catch (_) {
    return null
  }
}
