export enum LoginApiErrorCodes {
  UNKNOWN = 'UNKNOWN',
  MISSING_PARAMS = 'MISSING_PARAMS',
  NO_COMPANIES = 'NO_COMPANIES',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  UNVERIFIED_USER = 'UNVERIFIED_USER'
}

export const checkIfLoginApiErrorCode = (val: any): val is LoginApiErrorCodes => {
  return Object.values(LoginApiErrorCodes).includes(val)
}

const postCompanyApiErrorMessages: Record<LoginApiErrorCodes, string> = {
  'UNKNOWN': 'Une erreur inconnue est survenue',
  'MISSING_PARAMS': 'Au moins un champ est manquant',
  'NO_COMPANIES': 'Aucune entreprise ne correspond à ces identifiants',
  'INVALID_CREDENTIALS': 'Email ou mot de passe incorrect',
  'UNVERIFIED_USER': "Votre email n'a pas été vérifié"
}

export const getLoginErrorMessageFromErrorCode = (errorCode: LoginApiErrorCodes) => {
  return postCompanyApiErrorMessages[errorCode]
}
