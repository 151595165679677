import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import { P } from '../../../../../components/Text'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import { formatNumber } from '../../../../../helpers/rounds'
import KpiCard from '../KpiCard'

type PropsType = {
  energy?: number
  isLoading: boolean
}

export const EnergyCard = ({
  energy,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.co2}
      title="Energie consommée"
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : energy !== undefined ? formatNumber(energy) + ' kWh' : 'N/A'
      }
      tooltipContent={<P small>{`Energie consommée`}</P>}
    />
  )
}
