import * as d3 from 'd3'

import { formatDateToString } from '../../../../../helpers/date'
import { Point, LineChartData } from '../../lineChartDrawer'

const drawLines = <PointType extends Point>(
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  data: LineChartData<PointType>,
  yScales: d3.ScaleLinear<number, number, never>[],
  x: d3.ScaleBand<string>
) => {
  svg
    .selectAll('.lines')
    .data(data)
    .join('path')
    .attr('class', (d) => d.name)
    .attr('d', (d, i) =>
      d3
        .line<PointType>()
        .x((d) => x(formatDateToString(d.time, false)) ?? 0)
        .y((point) => yScales[i](+point.value))(d.values)
    )
    .attr('stroke', (d) => d.color)
    .style('stroke-width', 2)
    .style('fill', 'none')
}

export default drawLines
