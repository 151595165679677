import React, { CSSProperties, useEffect, useRef, useState } from 'react'

import { arrowSvgStyle, checkSvgStyle, DropdownError, selectedDropdownColor, StyledDropdown, StyledDropdownOptionItem, StyledDropdownOptions, StyledDropdownSelect, StyledLabel, StyledMultiDropdownOptionItem } from './style'
import ArrowDropdown from '../Icons/arrowDropdown'
import CheckIcon from '../Icons/check'
import { Margin } from '../../constants'

type MultiSelectDropdownProps = {
  label?: string
  options: string[] | null
  selected: string[]
  onSelectionChange: (selection: string[]) => void
  style?: CSSProperties
  marginBottom?: string
  selectStyle?: CSSProperties
  optionsStyle?: CSSProperties
  optionsPosition?: 'top' | 'bottom'
  error?: string
  displayAllSelected?: boolean
  disabledOptions?: string[]
}

const MultiSelectDropdown = ({ label, options, selected, onSelectionChange, style, marginBottom = Margin.m7, selectStyle, optionsStyle, optionsPosition, error, displayAllSelected = true, disabledOptions }: MultiSelectDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleUpdateSelected = (option: string) => {
    const newSelection = selected?.includes(option) ? selected?.filter((selectedOption) => selectedOption !== option) : [...(selected ?? []), option]
    onSelectionChange(newSelection)
    setIsOpen(false)
  }

  return (
    <StyledDropdown ref={containerRef} style={style} marginBottom={error ? `calc(${marginBottom + '24px'})` : marginBottom}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledDropdownSelect onClick={handleDropdownToggle} style={selectStyle} error={!!error}>
        {displayAllSelected ? selected.length > 0 ? selected.join(', ') : 'Sélectionner une option' : selected.length > 0 ? selected[selected.length -1] : 'Sélectionner une option'}
        <ArrowDropdown style={{ ...arrowSvgStyle, ...(isOpen ? { transform: 'rotate(180deg) translateY(-8%)' } : {}) }} width='20px' />
      </StyledDropdownSelect>
      {isOpen && (
        <StyledDropdownOptions style={optionsStyle} position={optionsPosition}>
          {options && options.length > 0 ? options.map((option) => (
            <StyledMultiDropdownOptionItem
              key={option}
              selected={selected.includes(option)}
              onClick={() => handleUpdateSelected(option)}
              disabled={disabledOptions?.includes(option)}
            >
              {option}
              {selected.includes(option) && <CheckIcon color={selectedDropdownColor} style={checkSvgStyle} width='18px' />}
            </StyledMultiDropdownOptionItem>
          )) : <StyledDropdownOptionItem selected={false} disabled>Aucune option disponible</StyledDropdownOptionItem>}
        </StyledDropdownOptions>
      )}
      {error ? (
        <DropdownError>{error}</DropdownError>
      ) : null}
    </StyledDropdown>
  )
}

export default MultiSelectDropdown