import { RegisterStepEnum } from "../types"

export const getFormDescription = (step: RegisterStepEnum) => {
  switch (step) {
    case RegisterStepEnum.CHECK_EMAIL:
      return ''
    case RegisterStepEnum.ENTER_PASSWORD:
      return `Cette adresse e-mail est déjà utilisée sur notre plateforme. Merci d'entrer votre mot de passe pour finaliser l'inscription de cette entreprise.`
    case RegisterStepEnum.ENTER_COMPANY_NAME:
      return `Entrez le nom de l'entreprise pour laquelle vous souhaitez vous inscrire.`
    case RegisterStepEnum.FILL_FORM:
      return 'Merci de compléter le formulaire pour finaliser votre inscription.'
  }
}