import styled from '@emotion/styled'

import Colors from '../../constants/colors'

export const ErrorPageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: ${Colors.white};
  transform: translateY(-50px);
`
