import React from 'react'
import { useNavigate } from 'react-router-dom'

import { P } from '../../../components/Text'
import { FontWeight } from '../../../constants'
import { SmallCTA } from '../style'

const BalanceSheetLinecardContent = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/balance-sheet')
  }

  return (
    <P
      style={{
        fontWeight: FontWeight.YETIC_MEDIUM,
      }}
    >
      Cliquez sur <SmallCTA onClick={handleClick}>Bilans</SmallCTA> pour accéder aux bilans que
      vous avez déjà ajoutés, ou pour en créer un nouveau.
    </P>
  )
}

export default BalanceSheetLinecardContent
