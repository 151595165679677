import React, { useState } from 'react'

import { CampaignFormatAndPlatform, CreationFlowStep } from './types'
import CampaignCreation from './CampaignCreation'
import { createCampaignApi } from '../../../services/api/campaigns/create'
import { getCampaignFormatFromWording, getCampaignPlatformFromWording } from './helpers/wording'
import { checkIfErrorType } from '../../../types/error'
import CampaignDetails from './CampaignDetails'
import { CampaignPerBalanceSheet } from '../../../services/api/campaigns/get/types'

export interface AddCampaignSectionProps {
  companyId: string
  balanceSheetId: string
  handleEndProvisioning: () => void
  handleCancel: () => void
  draftCampaign?: CampaignPerBalanceSheet | null
}

const AddCampaignSection = ({ companyId, balanceSheetId, handleEndProvisioning, draftCampaign, handleCancel }: 
  AddCampaignSectionProps) => {
  const [name, setName] = useState(draftCampaign == null ? '' : draftCampaign.name)
  const [campaignType, setCampaignType] = useState<CampaignFormatAndPlatform>(
    draftCampaign == null ? { platform: null, format: null}
    : {
      platform: draftCampaign.platform,
      format: draftCampaign.format
    }
  )
  const [step, setStep] = useState<CreationFlowStep>(draftCampaign == null 
    ? CreationFlowStep.CAMPAIGN_CREATION
    : CreationFlowStep.CAMPAIGN_DETAILS
  )
  const [campaignId, setCampaignId] = useState<string | null>(draftCampaign == null
    ? null
    : draftCampaign.id
  )
  
  const handleChangeName = (value: string) => {
    setName(value)
  }

  const handleChangePlatform = (value: string | null) => {
    const platformValue = value == null ? null : getCampaignPlatformFromWording(value) ?? null
    setCampaignType({ platform: platformValue, format: null})
  }

  const handleChangeFormat = (value: string | null) => {
    const formatValue = value == null ? null : getCampaignFormatFromWording(value) ?? null
    setCampaignType((prev) => ({ ...prev, format: formatValue}))
  }

  const handleCreateCampaign = async (campaignName: string) => {
    const { platform, format } = campaignType
    
    if (platform != null && format != null ) {
      const result = await createCampaignApi({ companyId, balanceSheetId, platform, format, name: campaignName })

      if (!checkIfErrorType(result)) {
        setCampaignId(result.id)
        setStep(CreationFlowStep.CAMPAIGN_DETAILS)
        return
      } else {
        alert('La création de la campagne a échoué')
      }
    }  else {
      alert('Plateforme ou format non rempli')
    }
  }

  const { format, platform } = campaignType

  const isCampaignTypeDefined = format != null && platform != null

  return (
    <div>
      {step === CreationFlowStep.CAMPAIGN_CREATION && <CampaignCreation
        name={name}
        campaignType={campaignType}
        handleChangeName={handleChangeName}
        handleChangePlatform={handleChangePlatform}
        handleChangeFormat={handleChangeFormat}
        handleCreateCampaign={handleCreateCampaign}
        handleCancel={handleCancel}
      />}
      {step === CreationFlowStep.CAMPAIGN_DETAILS && isCampaignTypeDefined && (
        <CampaignDetails 
          name={name}
          campaignPlatform={platform}
          campaignFormat={format}
          campaignId={campaignId || ''}
          handleEndProvisioning={handleEndProvisioning}
          handleCancel={handleCancel}
        />
      )}
    </div>
  )
}

export default AddCampaignSection