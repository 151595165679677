import React, { ReactNode } from 'react'

import RegisterSuccess from './Success'
import RegisterForm from './Form'

type RegisterViewProps = {
  children: ReactNode
  description?: string
  handleSubmit: (e: React.FormEvent) => void
  apiError: string
  registerSuccessful: boolean
  handleGoToLogin: () => void
  loading: boolean
}

const RegisterView = ({
  children,
  description,
  handleSubmit,
  apiError,
  registerSuccessful,
  handleGoToLogin,
  loading
}: RegisterViewProps) => {
  if (registerSuccessful) {
    return <RegisterSuccess title={'Votre compte a été créé avec succès !'} message={'Un email de vérification vous a été envoyé. Une fois votre adresse validée, vous pourrez accéder à votre espace.'} handleGoToLogin={handleGoToLogin}/>
  }

  return (
    <RegisterForm
      description={description}
      handleSubmit={handleSubmit}
      goToLogin={handleGoToLogin}
      apiError={apiError}
      loading={loading}
    >
      {children}
    </RegisterForm>
  )
}

export default RegisterView
