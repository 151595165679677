import { ReactNode } from 'react'

import { formatNumber } from '../../../helpers/rounds'

export const formatTableValue = (
  value?: string | number | JSX.Element,
  unit?: string
): ReactNode => {
  if (value === '-') {
    return value
  }

  if (value === undefined) {
    return '-'
  }

  if (typeof value === 'number') {
    return formatNumber(value) + (unit ?? '')
  }

  if (typeof value === 'string') {
    return value + (unit ?? '')
  }

  return value
}
