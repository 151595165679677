import React, { ReactNode, useEffect, useRef, useState } from 'react'

import LeftMenu from '../../pages/parts/LeftMenu'
import { H3 } from '../../components/Text'
import { HelpIcon } from '../../components/Icons'
import HelpTutorial from '../../pages/parts/HelpTutorial'
import { pageByEndpoint } from '../../types/page'
import { helpIconStyle, StyledContent, StyledContentScroll, StyledPageHeader } from './style'

type PageTemplateProps = {
  children: ReactNode
  cta?: ReactNode
  title?: string
}

const PageTemplate = ({ children, cta, title }: PageTemplateProps) => {
  const [isHelpDisplay, setIsHelpDisplay] = useState<boolean>(false)

  const helpRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (helpRef.current && !helpRef.current.contains(event.target as Node)) {
        setIsHelpDisplay(false)
      }
    }

    if (isHelpDisplay) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isHelpDisplay])

  const endpoint = window.location.pathname
  const page = pageByEndpoint[endpoint]

  return (
    <>
      <LeftMenu page={page} />
      <StyledContentScroll>
        <StyledContent>
          {title || cta ? (
            <StyledPageHeader>
              {title && <H3>{title}</H3>}
              {cta && <div>{cta}</div>}
            </StyledPageHeader>
          ) : null}
          {children}
        </StyledContent>
        <HelpIcon style={helpIconStyle} handleClick={() => setIsHelpDisplay(true)} />
        <div ref={helpRef}>
          <HelpTutorial isHelpDisplay={isHelpDisplay}/>
        </div>
      </StyledContentScroll>
    </>
  )
}

export default PageTemplate