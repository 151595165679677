import styled from '@emotion/styled'

import { chartGap } from '../../../style'
import { Margin } from '../../../../../constants'

export const PlatformsTabContainer = styled.div`
  width: 100%;
  gap: ${chartGap}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${Margin.m5};
`
