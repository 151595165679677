import { ErrorType } from "../../../types/error"

export type CheckUserExistsApiRawSuccessOutput = {
  success: true
}

export const checkIfCheckUserExistsApiRawSuccessOutput = (value: any): value is CheckUserExistsApiRawSuccessOutput => {
  return value && typeof value.success === 'boolean'
}

export type CheckUserExistsApiOutput = CheckUserExistsApiSuccessOutput | ErrorType

export type CheckUserExistsApiSuccessOutput = {
  found: boolean
}

export const checkIfCheckUserExistsApiSuccessOutput = (value: any): value is CheckUserExistsApiSuccessOutput => {
  return value && typeof value.found === 'boolean'
}