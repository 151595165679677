import { ErrorType } from "../../../../types/error"
import { checkIfDate } from "../../../../types/nativeTypes"
import { GetCompanyReportingErrorEnum, GetCompanyReportingErrorEnumTranslation } from "./error"
import { getCompanyDevicesReporting } from "./utils/devices"
import { getCompanyDownloadReporting } from "./utils/download"
import { getCompanyGlobalReporting } from "./utils/global"
import { getCompanyHighlightsReporting } from "./utils/highlight"
import { getCompanyImpactReporting } from "./utils/impact"
import { getCompanyLocationsReporting } from "./utils/location"
import { checkIfReportingApiSectionEnum, ReportingBySectionOutputType, ReportingApiSectionEnum } from "../types"

export type GetCampaignReportingBySectionInputType = {
  section: ReportingApiSectionEnum
  startDate?: string
  endDate?: string
}

export const getCompanyReportingBySection = async ({
  section,
  startDate,
  endDate
}: GetCampaignReportingBySectionInputType): Promise<
  | ReportingBySectionOutputType
  | ErrorType
> => {
  if (
    (startDate && !checkIfDate(startDate)) ||
    (endDate && !checkIfDate(endDate))
  ) {
    return {
      error:
        GetCompanyReportingErrorEnumTranslation[
          GetCompanyReportingErrorEnum.INVALID_DATE
        ]
    }
  }
  if (!checkIfReportingApiSectionEnum(section)) {
    return {
      error:
        GetCompanyReportingErrorEnumTranslation[
          GetCompanyReportingErrorEnum.WRONG_SECTION
        ]
    }
  }

  switch (section) {
    case ReportingApiSectionEnum.devices: {
      const result = await getCompanyDevicesReporting({
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.global: {
      const result = await getCompanyGlobalReporting({
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.impact: {
      const result = await getCompanyImpactReporting({
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.highlights: {
      const result = await getCompanyHighlightsReporting({
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.download: {
      const result = await getCompanyDownloadReporting({
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.locations: {
      const result = await getCompanyLocationsReporting({
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
  }

  return {
    error:
      GetCompanyReportingErrorEnumTranslation[
        GetCompanyReportingErrorEnum.UNKNOWN
      ]
  }
}
