export enum RegisterFieldsEnum {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  PASSWORD_CONFIRMATION = 'PASSWORD_CONFIRMATION',
  COMPANY_NAME = 'COMPANY_NAME'
}

export type RegisterFormErrors = Record<RegisterFieldsEnum, string>

export enum RegisterStepEnum {
  CHECK_EMAIL = 'CHECK_EMAIL',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
  ENTER_COMPANY_NAME = 'ENTER_COMPANY_NAME',
  FILL_FORM = 'FILL_FORM'
}