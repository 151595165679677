import React from 'react'

import { formatTableValue } from '../helpers/formatValue'
import { ColumnItem, ElementItem } from '../types'
import { Cell } from './styles'

type TableBodyCellProps = {
  columns: ColumnItem[]
  keyValue: string
  defaultWidth: string
  row: ElementItem
  currentValue: string | number | JSX.Element | undefined
}

const TableBodyCell = ({ columns, keyValue, defaultWidth, row, currentValue}: TableBodyCellProps) => {
  const column = columns.find((column) => column.name === keyValue)

  return (
    <Cell
      bold={column?.bold ?? false}
      width={column?.width ?? defaultWidth}
      onClick={() => {
        if (row.id && row.onClick) {
          row.onClick(row.id)
        }
      }}
      align={column?.align}
    >
      {formatTableValue(currentValue,column?.unit)}
    </Cell>
  )
}

export default TableBodyCell