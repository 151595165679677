import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import { P } from '../../../../../components/Text'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import { formatNumber } from '../../../../../helpers/rounds'
import KpiCard from '../KpiCard'

type PropsType = {
  clicks?: number
  isLoading: boolean
}

export const ClicksCard = ({
  clicks,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.clicks}
      title="Clics"
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : clicks !== undefined ? formatNumber(clicks) : 'N/A'
      }
      tooltipContent={<P small>{`Le nombre total de fois où les internautes ont cliqué sur les annonces`}</P>}
    />
  )
}
