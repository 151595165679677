import {
  LineChartData,
  DatasetsSelection,
  Point
} from '../components/Chart/LineChart/lineChartDrawer'
import { formatNumber } from './rounds'

export const formatAnalyticsHeaderData = (
  data: LineChartData<Point>,
  initialSelectionNames?: string[]
): DatasetsSelection => {
  const getHeaderKpi = (name: string, values: Point[]) => {
    if (values.length === 0) {
      return undefined
    }

    return formatNumber(
      values.reduce((current, point) => current + point.value, 0)
    )
  }

  return data.map((dataset) => ({
    name: dataset.name,
    color: dataset.color,
    isSelected: initialSelectionNames
      ? initialSelectionNames.includes(dataset.name) ?? false
      : true,
    headerKpi: getHeaderKpi(dataset.name, dataset.values),
    unit: dataset.unit
  }))
}
