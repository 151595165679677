import { 
  BalanceSheetsPerCompany, 
  checkIfBalanceSheetPerCompanyApi, 
  checkIfGetBalanceSheetsApiOutput, 
  GetBalanceSheetsApiOutput, 
  GetBalanceSheetsSort
} from "./types"
import { axiosGet, checkIsAxiosGetOutputSuccess } from "../../../../helpers/axios/get"
import { backendURL } from "../../../../constants"
import { ErrorType } from "../../../../types/error"
import { ListOrder } from "../../../../types/common"

type GetBalanceSheetsApiInput = {
  sort?: GetBalanceSheetsSort
  order?: ListOrder
}

export const getBalanceSheetsApi = async ({ sort, order }: GetBalanceSheetsApiInput): Promise<GetBalanceSheetsApiOutput | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/balance-sheets${sort ? `?sort=${sort}` : ''}${order ? sort ? `&order=${order}` : `?order=${order}` : ''}`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfGetBalanceSheetsApiOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors du chargement de vos bilans.'
  }
}

export interface GetBalanceSheetsPerCompanyApiInput {
  company: string
}

export const getBalanceSheetsPerCompanyApi = async ({ company }: GetBalanceSheetsPerCompanyApiInput): 
  Promise<BalanceSheetsPerCompany | ErrorType> => {
  const response = await axiosGet({
    url: `${backendURL}/companies/${company}/balance-sheets`
  })

  if (checkIsAxiosGetOutputSuccess(response)) {
    if (checkIfBalanceSheetPerCompanyApi(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors du chargement de vos bilans.'
  }
}