import * as d3 from 'd3';

const drawXAxis = (
  svg: d3.Selection<SVGGElement, unknown, null, undefined>,
  xAxisValues: string[],
  widthWithoutMargin: number,
  height: number,
  maxTicks: number
) => {
  const step = Math.ceil(xAxisValues.length / maxTicks);
  const displayedXAxisValues = xAxisValues.filter(
    (_, index) => index % step === 0
  );

  const x = d3.scaleBand().domain(xAxisValues).range([0, widthWithoutMargin]);

  const xDisplayedScale = d3
    .scaleBand()
    .domain(displayedXAxisValues)
    .range([0, widthWithoutMargin]);

  svg
    .append('g')
    .attr('transform', `translate(0, ${height})`)
    .attr('class', 'x-axis')
    .call(
      d3
        .axisBottom(xDisplayedScale)
        .tickSize(0)
        .tickPadding(14)
        .tickSizeOuter(0)
    );

  svg.selectAll('.x-axis').selectAll('text').style('font-size', '14px').style('transform', 'translateX(-45px)');

  return x;
};

export default drawXAxis;
