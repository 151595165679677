import { ErrorType } from "../../../../types/error"
import { GetBalanceSheetReportingErrorEnum, GetBalanceSheetReportingErrorEnumTranslation } from "./error"
import { getBalanceSheetDownloadReporting } from "./utils/download"
import { getBalanceSheetGlobalReporting } from "./utils/global"
import { getBalanceSheetHighlightsReporting } from "./utils/highlight"
import { getBalanceSheetImpactReporting } from "./utils/impact"
import { checkIfReportingApiSectionEnum, ReportingBySectionOutputType, ReportingApiSectionEnum } from "../types"
import { getBalanceSheetDevicesReporting } from "./utils/devices"
import { getBalanceSheetLocationsReporting } from "./utils/location"

export type GetBalanceSheetReportingBySectionInput = {
  balanceSheetId: string
  section: ReportingApiSectionEnum
}

export const getBalanceSheetReportingBySection = async ({
  balanceSheetId,
  section
}: GetBalanceSheetReportingBySectionInput): Promise<
  | ReportingBySectionOutputType
  | ErrorType
> => {
  if (!balanceSheetId) {
    return {
      error:
      GetBalanceSheetReportingErrorEnumTranslation[
        GetBalanceSheetReportingErrorEnum.BALANCE_SHEET_ID
      ]
    }
  }

  if (!checkIfReportingApiSectionEnum(section)) {
    return {
      error:
      GetBalanceSheetReportingErrorEnumTranslation[
        GetBalanceSheetReportingErrorEnum.WRONG_SECTION
      ]
    }
  }

  switch (section) {
    case ReportingApiSectionEnum.devices: {
      const result = await getBalanceSheetDevicesReporting({
        balanceSheetId
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.global: {
      const result = await getBalanceSheetGlobalReporting({
        balanceSheetId
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.impact: {
      const result = await getBalanceSheetImpactReporting({
        balanceSheetId
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.highlights: {
      const result = await getBalanceSheetHighlightsReporting({
        balanceSheetId
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.download: {
      const result = await getBalanceSheetDownloadReporting({
        balanceSheetId
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.locations: {
      const result = await getBalanceSheetLocationsReporting({
        balanceSheetId
      })
      if (result) {
        return result
      }
      break
    }
  }

  return {
    error:
    GetBalanceSheetReportingErrorEnumTranslation[
      GetBalanceSheetReportingErrorEnum.UNKNOWN
    ]
  }
}
