import { CampaignFormat, checkIfGoogleCampaignFormat, checkIfMetaCampaignFormat, GoogleCampaignFormat, MetaCampaignFormat } from "../../../../../types/formatAndPlatform";
import { CampaignStatus } from "../../../../../types/status";

export const getWordingFromCampaignStatus = (status: CampaignStatus) => {
  if (status === CampaignStatus.DRAFT) {
    return "Brouillon"
  } else {
    return "Prêt"
  }
}

export const getWordingFromCampaignFormat = (format: CampaignFormat) => {
  if (checkIfGoogleCampaignFormat(format)) {
    if (format === GoogleCampaignFormat.SEARCH) {
      return "Google Search"
    } else if (format === GoogleCampaignFormat.DISPLAY) {
      return "Google Display"
    } else if (format === GoogleCampaignFormat.VIDEO) {
      return "Google Video"
    } else if (format === GoogleCampaignFormat.PERF_MAX) {
      return "Google Perf Max"
    } else {
      return "Autre Google"
    }
  } else if (checkIfMetaCampaignFormat(format)) {
    if (format === MetaCampaignFormat.TEXT) {
      return "Meta Text"
    } else if (format === MetaCampaignFormat.INSTA) {
      return "Meta Instagram"
    } else if (format === MetaCampaignFormat.MESSENGER) {
      return "Meta Messenger"
    } else {
      return "Autre Meta"
    }
  } else {
    return ''
  }
}