import { backendURL } from '../../../../constants'
import {
  axiosPost,
  checkIsAxiosPostOutputSuccess,
} from '../../../../helpers/axios/post'
import {
  checkIfLoginApiErrorCode,
  getLoginErrorMessageFromErrorCode,
  LoginApiErrorCodes,
} from './errors'
import { checkIfLoginOutputSuccess, checkIfLoginSeveralCompaniesSuccess, LoginApiInput, LoginApiOutput } from './types'

export const loginApi = async ({
  email,
  password,
  companyName
}: LoginApiInput): Promise<LoginApiOutput> => {
  const response = await axiosPost({
    url: `${backendURL}/login`,
    payload: {
      email,
      password,
      ...(companyName && { companyName }),
    },
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkIfLoginOutputSuccess(response.data)) {
      return response.data
    } else if (checkIfLoginSeveralCompaniesSuccess(response.data)) {
      return response.data
    } else {
      return {
        error: getLoginErrorMessageFromErrorCode(
          LoginApiErrorCodes.UNKNOWN
        ),
      }
    }
  } else {
    if (checkIfLoginApiErrorCode(response.error?.data?.code)) {
      return {
        error: getLoginErrorMessageFromErrorCode(
          response.error.data.code
        ),
      }
    } else {
      return {
        error: getLoginErrorMessageFromErrorCode(
          LoginApiErrorCodes.UNKNOWN
        ),
      }
    }
  }
}
