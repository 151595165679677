import { backendURL } from "../../../../constants"
import { axiosDelete, checkIsAxiosDeleteOutputSuccess } from "../../../../helpers/axios/delete"
import { ErrorType } from "../../../../types/error"
import { checkIfUnlinkCampaignFromBalanceSheetOutput, UnlinkCampaignFromBalanceSheetOutput } from "./types"

type UnlinkCampaignFromBalanceSheetInput = {
  balanceSheetId: string
  campaignId: string
}

export const unlinkCampaignFromBalanceSheet = async ({
  balanceSheetId,
  campaignId
}: UnlinkCampaignFromBalanceSheetInput): Promise<UnlinkCampaignFromBalanceSheetOutput | ErrorType> => {
  const response = await axiosDelete({
    url: `${backendURL}/balance-sheets/${balanceSheetId}/campaigns/${campaignId}`
  })

  if (checkIsAxiosDeleteOutputSuccess(response)) {
    if (checkIfUnlinkCampaignFromBalanceSheetOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors de la suppression du bilan.'
  }
}