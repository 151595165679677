import styled from "@emotion/styled"

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 64px;
  height: 60vh;
`
import { chartGap } from "../../style"
import { Margin } from "../../../../constants"

export const TabContentWrapper = styled.div`
  width: 100%;
  gap: ${chartGap}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 60vh;
  margin-top: ${Margin.m5};
`

export const EmptyTabWrapper = styled.div`
width: 100%;
  gap: ${chartGap}px;
  min-height: 60vh;
  margin-top: ${Margin.m11};
  display: flex;
  justify-content: center;
`