import { checkIfCampaignStatus } from "../../../../../types/status"

export type UpdateMetaInstagramCampaignDetails = {
  id: string,
  status: string
}

export const checkUpdateMetaInstagramCampaignDetails = (value: any): value is UpdateMetaInstagramCampaignDetails => {
  return value && value.id && typeof value.id === 'string'
      && value.status && typeof value.status === 'string' && checkIfCampaignStatus(value.status)
}