import React from 'react'

import Input from '../../../../../components/Input'

export interface InputNameProps {
  name: string
  handleChangeName: (value: string) => void
}


const InputName = ({ name, handleChangeName }: InputNameProps) => {
  const error = name.length < 3 ? "Entrez le nom de la campagne (au moins 3 caractères)" : undefined

  return <Input
    label='Nom de la campagne'
    placeholder='Nom de la campagne'
    value={name}
    type='text'
    onChange={(e) => handleChangeName(e.target.value)}
    error={error}
  />
}

export default InputName