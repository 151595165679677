import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../../../constants/fontFamily'

export const StyledH1 = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin: 0;
  font-family: ${YETIC_FONT_FAMILY};
  color: ${Colors.darkBlue};
`
