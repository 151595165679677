import styled from '@emotion/styled'

import Margin from '../../constants/margin'
import Padding from '../../constants/padding'

export const ChartTitle = styled.div`
  text-align: center;
  padding: ${Padding.p8};
  padding-bottom: ${Padding.p0};
  box-sizing: border-box;
  font-weight: 700;
`
