import styled from "@emotion/styled"

import { Colors, Margin, Padding } from "../../../constants"

export const StyledWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  box-sizing: border-box;
  border-radius: 10px;
  overflow: visible;
  box-shadow: 0px 0px 3px #142a4c80;
  background-color: ${Colors.white};
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${Padding.p8} ${Padding.p8} ${Padding.p8};
  flex-direction: column;
`

export const PieChartRoot = styled.div`
  margin-top: ${Margin.m8};
  overflow: visible;
`
