import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'

export const HighlightsContainer = styled.div`
  width: 100%;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const HighlightsRow = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const DownloadHighlightButton = styled.button`
  outline: none;
  border: none;
  background-color: ${Colors.transparent};
  cursor: pointer;
  margin-left: 8px;
`

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
