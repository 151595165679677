import React, { useEffect, useState } from 'react'

import AnimatedLoader from '../../components/AnimatedLoader'
import { LoadingPageContainer } from './style'
import PageTemplate from '../../templates/Page'

const LoadingPage = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaderVisible(true)
    }, 200)
  }, [])

  return (
    <PageTemplate>
      <LoadingPageContainer>
        {isLoaderVisible && <AnimatedLoader size={20} />}
      </LoadingPageContainer>
    </PageTemplate>
  )
}

export default LoadingPage
