import React from 'react'
import { useNavigate } from 'react-router-dom'

import ErrorPage from '..'
import { endpointByPage } from '../../../types/page'

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <ErrorPage
      message='Page introuvable'
      action={{
        text: 'Retour à l\'accueil',
        onClick: () => navigate(endpointByPage.HOME)
      }}
    />
  )
}

export default PageNotFound