enum Padding {
  auto = 'auto',
  p0 = '0px',
  p1 = '2px',
  p2 = '4px',
  p3 = '8px',
  p4 = '12px',
  p5 = '16px',
  p6 = '20px',
  p7 = '24px',
  p8 = '32px',
  p9 = '40px',
  p10 = '48px',
  p11 = '64px'
}

export const pagePadding = 32

export default Padding
