import { CSSProperties } from "react"

export const tableNameStyle: CSSProperties = {
  maxWidth: '90%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export const tableEditIconStyle: CSSProperties = {
  cursor: 'pointer',
  overflow: 'visible'
}
export const tiktokIconStyle: CSSProperties = {
  height: '20px'
}