import { CSSProperties } from 'react'
import styled from '@emotion/styled'

import Padding from '../../../../constants/padding'
import Margin from '../../../../constants/margin'

export const HeaderSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  margin: ${Margin.m4} 0;
`

export const SelectionItem = styled.div<{
  isSelected: boolean
  color: string
}>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${Margin.m3};
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  ${({ isSelected, color }) => isSelected && `border-bottom-color: ${color};`}
  flex: 0.25;
  min-width: 120px;
  max-width: 200px;
  padding-top: ${Padding.p6};
  padding-bottom: ${Padding.p3};
  box-sizing: border-box;
`

export const SelectionItemName = styled.div``

export const datasetNameStyle: CSSProperties = {
  textAlign: 'center'
}
