import React from 'react'

import { IconType } from './types'
import { Colors } from '../../constants'

const SheetIcon = ({
  height = '22px',
  width = '22px',
  style,
  color = Colors.darkBlue
}: IconType) => {
  return (
    <svg
      height={height}
      width={width}
      style={style}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin='round'
      stroke={color}
      fill='none'
    >
      <path d="M12 11H14.5H17" />
      <path d="M12 7H14.5H17" />
      <path d="M8 15V3.6C8 3.26863 8.26863 3 8.6 3H20.4C20.7314 3 21 3.26863 21 3.6V17C21 19.2091 19.2091 21 17 21V21" />
      <path d="M5 15H8H12.4C12.7314 15 13.0031 15.2668 13.0298 15.5971C13.1526 17.1147 13.7812 21 17 21H8H6C4.34315 21 3 19.6569 3 18V17C3 15.8954 3.89543 15 5 15Z" />
    </svg>
  )
}
export default SheetIcon
