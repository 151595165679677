import { checkUpdateBalanceSheetStatus, UpdateBalanceSheetStatus } from "./types"
import { axiosPut, checkIsAxiosPutOutputSuccess } from "../../../../helpers/axios/put"
import { backendURL } from "../../../../constants"
import { ErrorType } from "../../../../types/error"
import { BalanceSheetStatusEnum, convertStatusToApiStatus } from "../../../../pages/BalanceSheet/types"

type UpdateBalanceSheetStatusApiInput = {
  balanceSheetId: string
  status: BalanceSheetStatusEnum
}

export const updateBalanceSheetStatusApi = async ({
  balanceSheetId,
  status
}: UpdateBalanceSheetStatusApiInput): Promise<UpdateBalanceSheetStatus | ErrorType> => {
  const response = await axiosPut({
    url: `${backendURL}/balance-sheets/${balanceSheetId}`,
    payload: {
      status: convertStatusToApiStatus(status)
    }
  })

  if (checkIsAxiosPutOutputSuccess(response)) {
    if (checkUpdateBalanceSheetStatus(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors de la mise à jour du statut du bilan.'
  }
}