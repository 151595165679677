import { CSSProperties } from "react"
import styled from "@emotion/styled"

import { Colors, FontWeight, Margin, Padding } from "../../../constants"

export const HelpTutorialContainer = styled.div<{isHelpDisplay: boolean}>`
  background-color: ${Colors.grey};
  border-left: solid 2px ${Colors.greyBorder};
  box-shadow: 0px 0px 15px 0px rgba(0,0,0, 0.1);
  width: 100%;
  max-width: 350px;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  padding: ${Padding.p8} ${Padding.p7};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${Margin.m8};
  transform: translateX(${props => props.isHelpDisplay ? '0' : '350px'});
  transition: transform 300ms;
`

export const StyledPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${Margin.m7} 0 ${Margin.m5};
`

export const StepsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${Margin.m8};
  width: 100%;
  overflow-y: scroll;
  padding: 0 ${Padding.p3};
`

export const PageLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
`

export const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m3}
`

export const Span = styled.span`
  font-weight: ${FontWeight.YETIC_BOLD};
`

export const helpTutorialParagraphStyle: CSSProperties = {
  lineHeight: '1.5'
}