const googleSearchColumnNames = ["Campaign ID", "Campaign", "Campaign type", 
  "Day", "Device", "City (User location)", "Clicks", "Impr.", "Currency code", "Cost"]

interface CheckGoogleSearchLogInput {
  csvFile: File
}

export const checkGoogleSearchLog = async ({ csvFile }: CheckGoogleSearchLogInput): Promise<boolean> => {
  const fileContent = await new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      if (event.target?.result) {
        resolve(event.target.result as string)
      } else {
        reject(null)
      }
    }

    reader.onerror = () => {
      reject(null)
    }

    reader.readAsText(csvFile)
  })

  if (fileContent == null) {
    return false
  }

  const rows = fileContent.split('\n')
  if (rows.length === 0) {
    return false
  }

  const headers = rows[2].trim().split(',')
  const headersMatch = googleSearchColumnNames.every((column, index) => headers[index] === column)

  return headersMatch
}
