import React, { ReactNode, useState } from 'react'

import { Container, Number, DivNumbers, DivArrows } from './style'
import { ArrowFirst, ArrowLast, ArrowNext, ArrowPrevious } from '../Icons'

type PaginationProps = {
  totalItems: number
  itemsPerPage: number
  onChangePage: (page: number) => void
}

const Pagination = ({
  totalItems,
  itemsPerPage,
  onChangePage
}: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    onChangePage(page)
  }

  if (totalPages <= 1) return null

  const renderPageNumbers = () => {
    const pageNumbers: ReactNode[] = []
    let startPage = Math.max(1, currentPage - 2)
    let endPage = Math.min(totalPages, currentPage + 2)

    if (currentPage < 3) {
      endPage = Math.min(5, totalPages)
    } else if (currentPage > totalPages - 2) {
      startPage = Math.max(1, totalPages - 4)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Number
          key={i}
          isSelected={currentPage === i}
          onClick={() => {
            handlePageChange(i)
          }}
        >
          {i}
        </Number>
      )
    }
    return pageNumbers
  }

  return (
    <Container>
      <DivArrows
        onClick={() => {
          handlePageChange(1)
        }}
        isDisabled={currentPage === 1}
      >
        <ArrowFirst width="10px" />
      </DivArrows>
      <DivArrows
        onClick={() => {
          currentPage > 1 && handlePageChange(currentPage - 1)
        }}
        isDisabled={currentPage === 1}
      >
        <ArrowPrevious width="10px" />
      </DivArrows>
      <DivNumbers>{renderPageNumbers()}</DivNumbers>
      <DivArrows
        onClick={() => {
          currentPage < totalPages && handlePageChange(currentPage + 1)
        }}
        isDisabled={currentPage === totalPages}
      >
        <ArrowNext width="10px" />
      </DivArrows>
      <DivArrows
        onClick={() => {
          handlePageChange(totalPages)
        }}
        isDisabled={currentPage === totalPages}
      >
        <ArrowLast width="10px" />
      </DivArrows>
    </Container>
  )
}

export default Pagination
