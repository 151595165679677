import styled from '@emotion/styled'

import { Margin } from '../../../../constants'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m2};
  margin-top: auto;
  width: 100%;
  position: relative;
`
