import styled from '@emotion/styled'

import Margin from '../../../../../../../constants/margin'

export const Co2TooltipTitle = styled.div`
  text-align: center;
`

export const ParagraphContainer = styled.div`
  margin-top: ${Margin.m4};
`
