import styled from "@emotion/styled"

import { Colors, Margin } from "../../../constants"

export const leftMenuWidth = 240
export const selectedLogoColor = Colors.blue

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: ${Margin.m3};
`

export const StyledLeftMenu = styled.div`
  background-color: ${Colors.grey};
  height: 100%;
  width: ${leftMenuWidth}px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledLogo = styled.img`
  width: ${leftMenuWidth / 2}px;
  cursor: pointer;
`

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${Margin.m2};
  width: 100%;
  margin-top: ${Margin.m5};
`

export const LogoutButton = styled.div`
  margin-top: auto;
  cursor: pointer;

`

export const StyledItemText = styled.span`
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
