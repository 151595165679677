import styled from "@emotion/styled"

import { Colors, Margin, maxPageWidth } from "../../constants"
import { pagePadding } from "../../constants/padding"

export const StyledContentScroll = styled.div`
  background-color: ${Colors.white};
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const StyledContent = styled.div`
  padding: ${pagePadding}px;
  max-width: ${maxPageWidth}px;
  margin: 0 auto;
  width: 100%;
`

export const StyledPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${Margin.m7} 0 ${Margin.m5};
`