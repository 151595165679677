import React, { CSSProperties, FC, ReactNode, MouseEventHandler } from 'react'

import { StyledButtonText, StyledButtonSecondary, StyledButtonTertiary, StyledButton } from './style'
import AnimatedLoader from '../AnimatedLoader'

export type StyleButtonType = {
  marginBottom?: string
  marginTop?: string
  width?: string
  height?: string
  padding?: string
}

export type ButtonPropsType = {
  children?: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  width?: string
  height?: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'textButton'
  active?: boolean
  disabled?: boolean
  loading?: boolean
  marginBottom?: string
  marginTop?: string
  style?: CSSProperties
  padding?: string
  icon?: ReactNode
  small?: boolean
}

const Button = ({
  children,
  onClick = () => { },
  width,
  height,
  type = 'primary',
  active = false,
  disabled = false,
  loading = false,
  marginBottom = undefined,
  marginTop = undefined,
  padding,
  icon,
  small,
  style = {}
}: ButtonPropsType) => {
  if (type === 'textButton') {
    return (
      <StyledButtonText
        onClick={onClick}
        style={style}
        height={height}
        width={width}
        disabled={disabled || loading}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
      >
        {icon}
        {loading ? 'Chargement...' : children}
      </StyledButtonText>
    )
  } else if (type === 'secondary') {
    return (
      <StyledButtonSecondary
        onClick={onClick}
        style={style}
        height={height}
        width={width}
        disabled={disabled || loading}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
        small={small}
      >
        {icon}
        {loading ? <AnimatedLoader size={12} /> : children}
      </StyledButtonSecondary>
    )
  } else if (type === 'tertiary') {
    return (
      <StyledButtonTertiary
        onClick={onClick}
        style={style}
        height={height}
        width={width}
        disabled={disabled || loading}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
        small={small}
      >
        {icon}
        {loading ? <AnimatedLoader size={12} /> : children}
      </StyledButtonTertiary>
    )
  } else {
    return (
      <StyledButton
        onClick={onClick}
        style={style}
        height={height}
        width={width}
        disabled={disabled || loading}
        marginBottom={marginBottom}
        marginTop={marginTop}
        padding={padding}
        small={small}
      >
        {icon}
        {loading ? <AnimatedLoader color='white' size={12} /> : children}
      </StyledButton>
    )
  }
}

export default Button
