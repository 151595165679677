import React from 'react'

import { DevicesReportingOutputType } from '../../../../../services/api/reporting/types'
import { EmptyTabWrapper } from '../style'

type DevicesTabProps = {
  reportingData: DevicesReportingOutputType
}

const DevicesTab = ({ reportingData }: DevicesTabProps) => {
  return <EmptyTabWrapper>À venir!</EmptyTabWrapper>
}

export default DevicesTab
