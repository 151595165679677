import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  checkNameFormat,
  checkEmailFormat,
  checkPasswordFormat
} from '../../helpers/checkers'
import { clearAllCookies } from '../../helpers/cookie'
import LoginView from './View'
import { LoginFieldsEnum, LoginFormErrors } from './types'
import { loginApi } from '../../services/api/auth/login'
import { checkIfLoginOutputError, checkIfLoginOutputSuccess, checkIfLoginSeveralCompaniesSuccess, LoginApiOutput } from '../../services/api/auth/login/types'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [companyName, setCompanyName] = useState<string | null>(null)
  const [companies, setCompanies] = useState<string[] | null>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [queryResult, setQueryResult] = useState<LoginApiOutput | null>(null)

  const navigate = useNavigate()
  
  clearAllCookies()

  useEffect(() => {
    setLoading(false)
  }, [queryResult])

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: LoginFieldsEnum
  ) => {
    setHasBeenSubmitted(false)

    switch (field) {
      case LoginFieldsEnum.EMAIL:
        setEmail(e.target.value)
        break

      case LoginFieldsEnum.PASSWORD:
        setPassword(e.target.value)
        break
    }
  }

  const apiError =
    queryResult && hasBeenSubmitted && checkIfLoginOutputError(queryResult)
      ? queryResult.error
      : ''

  const showEmailAndPassword = !companies

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (Object.values(formErrors).every((err) => err === '')) {
      setLoading(true)
      const result = await loginApi({
        email,
        password,
        companyName
      })

      setQueryResult(result)

      if (checkIfLoginOutputSuccess(result)) {
        navigate('/')
      } else if (checkIfLoginSeveralCompaniesSuccess(result)) {
        setCompanies(result.companies)
      }
    }

    setHasBeenSubmitted(true)
  }

  const handleGoToRegister = () => {
    navigate('/register')
  }

  const formErrors: LoginFormErrors = {
    [LoginFieldsEnum.EMAIL]: checkEmailFormat(email)
      ? ''
      : `Le format de l'adresse e-mail est invalide.`,
    [LoginFieldsEnum.PASSWORD]: checkPasswordFormat(password)
      ? ''
      : 'Le mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule et un nombre.',
    [LoginFieldsEnum.COMPANY_NAME]: companies ? checkNameFormat(companyName ?? '')
      ? ''
      : 'Le nom de l\'entreprise doit contenir au moins deux caractères.'
      : ''
  }

  const getFormError = (field: LoginFieldsEnum) => {
    if (!hasBeenSubmitted) {
      return ''
    }

    return formErrors[field]
  }

  return (
    <LoginView
      email={showEmailAndPassword ? email : null}
      password={showEmailAndPassword ? password : null}
      companyName={companyName}
      companies={companies}
      showPassword={showPassword}
      handleFieldChange={handleFieldChange}
      handleCompanyChange={setCompanyName}
      handleSubmit={handleSubmit}
      setShowPassword={setShowPassword}
      getFormError={getFormError}
      apiError={apiError}
      handleGoToRegister={handleGoToRegister}
      loading={loading}
      description={companies ? 'Veuillez sélectionner l\'entreprise à laquelle vous souhaitez vous connecter.' : undefined}
    />
  )
}

export default Login
