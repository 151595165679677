import React, { useState } from 'react'

import Button from '../../../../../components/Button'
import {
  CampaignPlatform,
  MetaCampaignFormat,
} from '../../../../../types/formatAndPlatform'
import { Colors, Margin } from '../../../../../constants'
import { H3 } from '../../../../../components/Text'
import InputFileSection from '../InputFileSection'
import { SubmitFileErrorEnum, submitFileErrorWording } from '../constants/error'
import { checkMetaLog } from '../../../../../helpers/files/csv/checkMetaLog'
import { updateLogsCampaignApi } from '../../../../../services/api/campaigns/update/metaInstagram/logs'
import { checkIfErrorType } from '../../../../../types/error'
import AnimatedLoader from '../../../../../components/AnimatedLoader'

export interface GoogleSearchDetailsProps {
  name: string
  campaignId: string
  handleEndProvisioning: () => void
}

const campaignPlatform = CampaignPlatform.META
const campaignFormat = MetaCampaignFormat.INSTA

const InstagramDetails = ({ 
  name,
  campaignId,
  handleEndProvisioning 
}: GoogleSearchDetailsProps) => {
  const [csvFile, setCsvFile] = useState<File | null>(null)
  const [fetchFileError, setFetchFileError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const isCsvFileValid = csvFile != null

  const submitFile = async (value?: File) => {
    if (value === undefined) {
      setFetchFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_NOT_FOUND])
    } else {
      const isFormatValid = await checkMetaLog({ csvFile: value })
      if (isFormatValid) {
        setCsvFile(value)
        setFetchFileError(null)
      } else {
        setFetchFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_INVALID])
      }
    }
  }

  const deleteFile = () => {
    setCsvFile(null)
    setFetchFileError(null)
  }

  const validateProvisioning = async () => {
    setLoading(true)

    if (csvFile == null) {
      alert('Fichier non trouvé')
      return
    }
    
    const resultUpdateLogs = await updateLogsCampaignApi({ campaignId, platform: campaignPlatform, format: campaignFormat, csvFile })

    setLoading(false)

    if (!checkIfErrorType(resultUpdateLogs)) {
      handleEndProvisioning()
    } else {
      alert('La mise à jour de la campagne a échoué')
    }
  }

  return (
    <div>
      <H3>{`Campagne : "${name}"`}</H3>
      <InputFileSection 
        label="Fichier de logs :"
        uniqueKey='input-csv-campaign-logs'
        csvFile={csvFile} 
        submitFile={submitFile} 
        deleteFile={deleteFile} 
        error={fetchFileError} 
      />
      {isCsvFileValid && 
        <Button onClick={validateProvisioning} small style={{marginTop: Margin.m7}}>
          {loading ? <AnimatedLoader color={Colors.white} /> : 'Confirmer le provisioning'}
        </Button>
      }
    </div>
  )
}

export default InstagramDetails