import React from 'react'
import { UserPermissionsEnum } from '../types/permissions'

export type AppContextType = {
  companyName: string | null
  setCompanyName: (companyName: string | null) => void
  userType: UserPermissionsEnum | null
  setUserType: (userType: UserPermissionsEnum | null) => void
  handleLogout: () => void
}

export const AppContext = React.createContext<AppContextType>({
  companyName: null,
  setCompanyName: () => {},
  userType: null,
  setUserType: () => {},
  handleLogout: () => {}
})
