import React from 'react'

import { 
  getReportingTabTranslation,
  ReportingDownloadDataType,
  ReportingHighlightsDataType,
  SectionDataType,
  ReportingTabKeyEnum
} from '../types'
import { PageReportingTypeEnum } from '../helpers/query'
import { ReportingHighlights } from './Highlights/campaign'
import SectionContent from './SectionContent'
import StyledTabs from './StyledTabs'
import { ReportingHighlightsBalanceSheet } from './Highlights/balanceSheet'

type ReportingBodyProps = {
  pageType: PageReportingTypeEnum,
  tabs: ReportingTabKeyEnum[]
  activeTabIndex: number
  isHighlightsDataLoading: boolean
  highlightsData: ReportingHighlightsDataType | null
  downloadData: ReportingDownloadDataType | null
  isSectionDatatLoading: boolean
  sectionData: SectionDataType | null
  onChangeTabIndex: (index: number) => void
}

const ReportingBody = ({
  pageType,
  tabs,
  activeTabIndex,
  isHighlightsDataLoading,
  highlightsData,
  downloadData,
  isSectionDatatLoading,
  sectionData,
  onChangeTabIndex
}: ReportingBodyProps) => {
  const ReportingHighlightsComponent = pageType === PageReportingTypeEnum.CAMPAIGN 
    ? ReportingHighlights
    : ReportingHighlightsBalanceSheet
  return <>
    <ReportingHighlightsComponent
      data={highlightsData}
      downloadData={downloadData}
      isLoading={isHighlightsDataLoading}
    />
    <StyledTabs
      items={tabs.map((tab) => getReportingTabTranslation(tab))}
      activeTabIndex={activeTabIndex}
      handleTabClick={onChangeTabIndex}
    />
    <SectionContent
      activeTab={tabs[activeTabIndex]}
      sectionData={sectionData}
      isLoading={isSectionDatatLoading}
    />
  </>
}

export default ReportingBody