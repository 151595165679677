import { ApiPlatformEnum, BalanceSheetApiStatusEnum } from "../../constants/api"

export type BalanceSheetCreationFormData = {
  name: string
  startDate: string
  endDate: string
  platforms: string[]
}

export enum BalanceSheetStatusEnum {
  WAITING_FOR_PROVISIONING = 'En attente de provisionnement',
  PROVISIONING_IN_PROGRESS = 'Provisionnement en cours',
  READY = 'Prêt',
  WAITING_FOR_PAYMENT = 'En attente de paiement',
  DELETED = 'Supprimé'
}

export const platformOptions = Object.values(ApiPlatformEnum)
  .map(platform => platform[0].toUpperCase() + platform.slice(1))

export const convertPlatformsToApiFormat = (platforms: string[]): ApiPlatformEnum[] =>
  platforms
    .map(platform => platform.toLowerCase() as ApiPlatformEnum)

export const convertApiPlatformToPlatform = (platform: ApiPlatformEnum): string =>
  platform[0].toUpperCase() + platform.slice(1)

export const convertApiStatusToStatus = (status: BalanceSheetApiStatusEnum): BalanceSheetStatusEnum => {
  switch (status) {
    case BalanceSheetApiStatusEnum.WAITING_FOR_PROVISIONING:
      return BalanceSheetStatusEnum.WAITING_FOR_PROVISIONING
    case BalanceSheetApiStatusEnum.PROVISIONING_IN_PROGRESS:
      return BalanceSheetStatusEnum.PROVISIONING_IN_PROGRESS
    case BalanceSheetApiStatusEnum.READY:
      return BalanceSheetStatusEnum.READY
    case BalanceSheetApiStatusEnum.WAITING_FOR_PAYMENT:
      return BalanceSheetStatusEnum.WAITING_FOR_PAYMENT
    case BalanceSheetApiStatusEnum.DELETED:
      return BalanceSheetStatusEnum.DELETED
  }
}

export const convertStatusToApiStatus = (status: BalanceSheetStatusEnum): BalanceSheetApiStatusEnum => {
  switch (status) {
    case BalanceSheetStatusEnum.WAITING_FOR_PROVISIONING:
      return BalanceSheetApiStatusEnum.WAITING_FOR_PROVISIONING
    case BalanceSheetStatusEnum.PROVISIONING_IN_PROGRESS:
      return BalanceSheetApiStatusEnum.PROVISIONING_IN_PROGRESS
    case BalanceSheetStatusEnum.READY:
      return BalanceSheetApiStatusEnum.READY
    case BalanceSheetStatusEnum.WAITING_FOR_PAYMENT:
      return BalanceSheetApiStatusEnum.WAITING_FOR_PAYMENT
    case BalanceSheetStatusEnum.DELETED:
      return BalanceSheetApiStatusEnum.DELETED
  }
}