import React, { useEffect } from 'react'

import { drawChart } from './helpers/drawChart'
import { PieChartRoot, StyledWrapper } from './style'
import { ChartTitle } from '../style'
import { H3 } from '../../Text'
import PieChartCaptions from './Captions'
import { PieChartDataItem } from './types'

type PropsType = {
  title: string
  data: PieChartDataItem[]
  chartId: string
  unit?: string
  width: string
  chartWidth: number
}

const PieChart = ({
  title,
  data,
  chartId,
  unit,
  width,
  chartWidth,
}: PropsType) => {
  useEffect(() => {
    drawChart({
      data,
      total,
      width: chartWidth,
      height,
      innerRadius: 0,
      outerRadius,
      unit,
      chartId,
    })
  }, [data])

  const outerRadius = chartWidth / 2
  const height = chartWidth

  const total = data.reduce((prev, curr) => prev + curr.value, 0)

  return (
    <StyledWrapper width={width}>
      <ChartTitle>
        <H3>{title}</H3>
      </ChartTitle>
      <PieChartRoot id={chartId} />
      <PieChartCaptions data={data} total={total} />
    </StyledWrapper>
  )
}

export default PieChart
