const metaColumnNames = [
  'Campaign ID',
  'Day',
  'Platform',
  'Placement',
  'Device platform',
  'Reach',
  'Impressions',
  'Currency',
  'Amount spent (EUR)',
  'Clicks (all)',
  'CPC (all)',
  'Link clicks',
  'CPC (cost per link click)',
  'Reporting starts',
  'Reporting ends'
]

interface CheckMetaLogInput {
  csvFile: File
}

export const checkMetaLog = async ({ csvFile }: CheckMetaLogInput): Promise<boolean> => {
  const fileContent = await new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      if (event.target?.result) {
        resolve(event.target.result as string)
      } else {
        reject(null)
      }
    }

    reader.onerror = () => {
      reject(null)
    }

    reader.readAsText(csvFile)
  })

  if (fileContent == null) {
    return false
  }

  const rows = fileContent.split('\n')

  const headers = rows[0].trim().split(',')
  const headersMatch = metaColumnNames.every((column, index) => 
    headers[index].replaceAll('"', '') === column)

  return headersMatch
}
