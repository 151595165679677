import React from 'react'

import { IconType } from './types'
import { Colors } from '../../constants'

const SortIcon = ({
  height = '20px',
  width = '20px',
  style,
  color = Colors.black,
  handleClick,
}: IconType) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      onClick={handleClick}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          d="M12 4V20M12 4L8 8M12 4L16 8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  )
}

export default SortIcon
