import React from 'react'

import { DevicesReportingOutputType } from '../../../../../services/api/reporting/types'
import { EmptyTabWrapper } from '../style'

type CampaignsTabProps = {
  reportingData: DevicesReportingOutputType
}

const CampaignsTab = ({ reportingData }: CampaignsTabProps) => {
  return <EmptyTabWrapper>À venir!</EmptyTabWrapper>
}

export default CampaignsTab
