import React from 'react'

import Dropdown from '../../../components/Dropdown'
import DateFilters from './DateFilters'
import { 
  getReportingHeaderCampaignTypeTranslation,
  ReportingCampaignType,
} from '../types'
import { StyledReportingHeader } from '../style'
import { P } from '../../../components/Text'
import { Margin } from '../../../constants'

type ReportingHeaderProps = {
  campaigns: ReportingCampaignType[] | null
  selectedCampaign: ReportingCampaignType | null
  selectedStartDate: string | null
  selectedEndDate: string | null
  onCampaignChange: (name: string | null) => void
  onChangeStartDateInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeEndDateInput: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ReportingHeader = ({
  campaigns,
  selectedCampaign,
  selectedStartDate,
  selectedEndDate,
  onCampaignChange,
  onChangeStartDateInput,
  onChangeEndDateInput
}: ReportingHeaderProps) => {
  const campaignNames = [
    ...(campaigns && campaigns.length > 0 ? ["Toutes les campagnes"] : []), 
    ...(campaigns?.map((campaign) => campaign.name) || [])
  ]

  return (
    <>
      <StyledReportingHeader>
        <Dropdown
          label='Campagne'
          options={campaignNames}
          selected={selectedCampaign?.name ?? null}
          onValueChange={onCampaignChange}
          style={{ flex: 1, marginBottom: 0 }}
        />
        {selectedCampaign && (
          <DateFilters
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            handleChangeStartDate={onChangeStartDateInput}
            handleChangeEndDate={onChangeEndDateInput}
            defaultStartDate={selectedCampaign.startDate}
            defaultEndDate={selectedCampaign.endDate}
            today={new Date().toISOString().split('T')[0]}
          />
        )}
      </StyledReportingHeader>
      <P style={{marginTop: Margin.m7, marginBottom: Margin.m7}}>
        {selectedCampaign?.type 
          ? `Format : ${getReportingHeaderCampaignTypeTranslation(selectedCampaign.type)}`
          : ' '}
      </P>
    </>
  )
}

export default ReportingHeader