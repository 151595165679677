import * as d3 from 'd3'

import { roundToTwoSignificantDigits } from '../../../../../helpers/rounds'
import { Point, LineChartData } from '../../lineChartDrawer'

const drawYAxis = <PointType extends Point>(
  data: LineChartData<PointType>,
  widthWithoutMargin: number,
  height: number,
  svg: d3.Selection<SVGGElement, unknown, null, undefined>
) => {
  const yScales = data.map((d) => {
    const values = d.values.map((v) => v.value)
    return d3
      .scaleLinear()
      .domain([0, roundToTwoSignificantDigits(Math.max(...values))])
      .range([height, 0])
  })

  if (yScales.length === 1) {
    svg
      .append('g')
      .attr('class', 'y-axis')
      .call(
        d3.axisLeft(yScales[0]).tickSize(0).tickPadding(10).tickArguments([5])
      )
  } else if (yScales.length === 2) {
    svg
      .append('g')
      .attr('class', 'y-axis')
      .call(
        d3
          .axisLeft(yScales[0])
          .ticks(5)
          .tickSize(0)
          .tickPadding(10)
          .tickArguments([5])
      )
    svg
      .append('g')
      .attr('class', 'y-axis')
      .style('transform', `translateX(${widthWithoutMargin}px)`)
      .call(
        d3
          .axisRight(yScales[1])
          .ticks(5)
          .tickSize(0)
          .tickPadding(10)
          .tickArguments([5])
      )
  } else {
    svg
      .append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScales[0]).ticks(0).tickSize(0))
    svg
      .append('g')
      .attr('class', 'y-axis')
      .style('transform', `translateX(${widthWithoutMargin}px)`)
      .call(d3.axisRight(yScales[0]).ticks(0).tickSize(0))
  }

  svg.selectAll('.y-axis').selectAll('text').style('font-size', '14px')

  return yScales
}

export default drawYAxis
