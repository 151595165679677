import React, { CSSProperties } from 'react'

import { AnimatedLoaderContainer } from './style'

type AnimatedLoaderProps = {
  size?: number
  color?: string
  style?: CSSProperties
}

const AnimatedLoader = ({ size, color, style }: AnimatedLoaderProps) => {
  return (
    <AnimatedLoaderContainer size={size ?? 15} color={color} style={style} />
  )
}

export default AnimatedLoader
