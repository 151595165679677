import { 
  CampaignFormat,
  CampaignPlatform,
  GoogleCampaignFormat,
  MetaCampaignFormat,
  TiktokCampaignFormat
} from "../../../../types/formatAndPlatform";

export type WordingPerPlatformValue = "Google" | "Meta" | "TikTok"

const wordingPerPlatform: {[key: string]: WordingPerPlatformValue} = {
  [CampaignPlatform.GOOGLE]: "Google",
  [CampaignPlatform.META]: "Meta",
  [CampaignPlatform.TIKTOK]: "TikTok",
}

const platFormPerWording: {[key: string]: CampaignPlatform} = {
  "Google": CampaignPlatform.GOOGLE,
  "Meta": CampaignPlatform.META,
  "TikTok": CampaignPlatform.TIKTOK
}

export type WordingPerFormatValue = "Search" | "Display" | "Youtube" | "Perf Max" |
"Text" | "Insta" | "Short"

const wordingPerFormat: {[key: string]: WordingPerFormatValue}  = {
  [GoogleCampaignFormat.SEARCH]: "Search",
  [GoogleCampaignFormat.DISPLAY]: "Display",
  [GoogleCampaignFormat.VIDEO]: "Youtube",
  [GoogleCampaignFormat.PERF_MAX]: "Perf Max",
  [MetaCampaignFormat.TEXT]: "Text",
  [MetaCampaignFormat.INSTA]: "Insta",
  [TiktokCampaignFormat.SHORT]: "Short",
}

const formatPerWording: {[key: string]: CampaignFormat}  = {
  "Search": GoogleCampaignFormat.SEARCH,
  "Display": GoogleCampaignFormat.DISPLAY,
  "Youtube": GoogleCampaignFormat.VIDEO,
  "Perf Max": GoogleCampaignFormat.PERF_MAX,
  "Text": MetaCampaignFormat.TEXT,
  "Insta": MetaCampaignFormat.INSTA,
  "Short": TiktokCampaignFormat.SHORT
}

export const getWordingFromCampaignPlatform = (platform: CampaignPlatform) => {
  return wordingPerPlatform[platform]
}

export const getWordingFromCampaignFormat = (format: CampaignFormat) => {
  return wordingPerFormat[format]
}

export const getCampaignPlatformFromWording = (platform: string) => {
  return platFormPerWording[platform]
}

export const getCampaignFormatFromWording = (format: string) => {
  return formatPerWording[format]
}
