import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CampaignsReportingOutputType } from '../../../../../services/api/reporting/types'
import Table from '../../../../../components/Table'
import { ElementItem } from '../../../../../components/Table/types'
import { P } from '../../../../../components/Text'
import { campaignTabColumns } from './constants'
import { StyledWrapper, tableNameStyle } from './style'

type CampaignsTabProps = {
  reportingData: CampaignsReportingOutputType
}

const CampaignsTab = ({ reportingData }: CampaignsTabProps) => {
  const navigate = useNavigate()

  const tabElements: ElementItem[] = []
  const csvElements:  ElementItem[] = []

  reportingData.forEach((campaignItem) => {
    const { campaignId, campaignName, clicks, impressions, co2, cost } = campaignItem
  
    tabElements.push({
      id: campaignId,
      onClick: () => onReportingClick(campaignId),
      campaignName: <P style={tableNameStyle} title={campaignName}>{campaignName}</P>,
      clicks,
      impressions,
      co2,
      cost
    })

    csvElements.push({
      campaignName,
      clicks,
      impressions,
      co2,
      cost
    })
  })

  const onReportingClick = (campaignId: string) => {
    navigate(`/reporting?campaign-id=${encodeURIComponent(campaignId)}`)
  }

  return (
    <StyledWrapper>
      <Table
        title='Détail du bilan par campagne'
        columns={campaignTabColumns}
        elements={tabElements}
        csvElements={csvElements}
        download
      />
    </StyledWrapper>
  )
}

export default CampaignsTab
