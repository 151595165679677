import { checkIfCampaignStatus } from "../../../../types/status"

export type CreateCampaign = {
  id: string,
  status: string
}

export const checkCreateCampaignApi = (value: any): value is CreateCampaign => {
  return value && value.id && typeof value.id === 'string'
      && value.status && typeof value.status === 'string' && checkIfCampaignStatus(value.status)
}

export type LinkCampaignToBalanceSheetOutput = {
  ids: {
    balanceSheetId: string
    campaignId: string
  }
}

export const checkLinkCampaignToBalanceSheetOutput = (value: any): value is LinkCampaignToBalanceSheetOutput => {
  return value 
    && typeof value.balanceSheetId === 'string' 
    && typeof value.campaignId === 'string'
}