import Home from './Home'
import Login from './Login'
import PageNotFound from './ErrorPage/PageNotFound'
import Register from './Register'
import Reporting from './Reporting'

export {
  Home,
  Login,
  PageNotFound,
  Register,
  Reporting,
}