import styled from "@emotion/styled"

import { Colors, Margin, Padding } from "../../../../constants"

export const StyledLeftMenuItem = styled.div<{ selected?: boolean, hoverColor?: string, selectedColor?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s;
  padding: ${Padding.p4} ${Padding.p2};
  border-radius: 4px;

  ${({ selected, selectedColor }) => selected && `
    background-color: ${selectedColor ?? Colors.blue_01};
    color: ${Colors.black};
  `}

  ${({ selected, hoverColor }) => !selected && `
    cursor: pointer;
    color: ${Colors.veryDarkGrey};

    &:hover {
      background-color: ${hoverColor ?? Colors.leftMenuItemHover};
    }
  `}
`

export const StyledLeftMenuItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${Margin.m5};
  width: 80%;
`