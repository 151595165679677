export const openFileFromBase64 = (fileData: string) => {
  const binaryString = atob(fileData)
  const len = binaryString.length
  const bytes = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  const blob = new Blob([bytes], { type: 'application/pdf' })

  const url = URL.createObjectURL(blob)

  window.open(url)
}
