import { ErrorType } from "../../../../types/error"
import { checkIfDate } from "../../../../types/nativeTypes"
import { GetCampaignReportingErrorEnum, GetCampaignReportingErrorEnumTranslation } from "./error"
import { getCampaignDevicesReporting } from "./utils/devices"
import { getCampaignDownloadReporting } from "./utils/download"
import { getCampaignGlobalReporting } from "./utils/global"
import { getCampaignHighlightsReporting } from "./utils/highlight"
import { getCampaignImpactReporting } from "./utils/impact"
import { getCampaignLocationsReporting } from "./utils/location"
import { checkIfReportingApiSectionEnum, ReportingBySectionOutputType, ReportingApiSectionEnum } from "../types"

export type GetCampaignReportingBySectionInputType = {
  campaignId: string
  section: ReportingApiSectionEnum
  startDate?: string
  endDate?: string
}

export const getCampaignReportingBySection = async ({
  campaignId,
  section,
  startDate,
  endDate
}: GetCampaignReportingBySectionInputType): Promise<
  | ReportingBySectionOutputType
  | ErrorType
> => {
  if (!campaignId) {
    return {
      error:
        GetCampaignReportingErrorEnumTranslation[
          GetCampaignReportingErrorEnum.CAMPAIGN_ID
        ]
    }
  }

  if (
    (startDate && !checkIfDate(startDate)) ||
    (endDate && !checkIfDate(endDate))
  ) {
    return {
      error:
        GetCampaignReportingErrorEnumTranslation[
          GetCampaignReportingErrorEnum.INVALID_DATE
        ]
    }
  }
  if (!checkIfReportingApiSectionEnum(section)) {
    return {
      error:
        GetCampaignReportingErrorEnumTranslation[
          GetCampaignReportingErrorEnum.WRONG_SECTION
        ]
    }
  }

  switch (section) {
    case ReportingApiSectionEnum.devices: {
      const result = await getCampaignDevicesReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.global: {
      const result = await getCampaignGlobalReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.impact: {
      const result = await getCampaignImpactReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.highlights: {
      const result = await getCampaignHighlightsReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.download: {
      const result = await getCampaignDownloadReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
    case ReportingApiSectionEnum.locations: {
      const result = await getCampaignLocationsReporting({
        campaignId,
        startDate,
        endDate
      })
      if (result) {
        return result
      }
      break
    }
  }

  return {
    error:
      GetCampaignReportingErrorEnumTranslation[
        GetCampaignReportingErrorEnum.UNKNOWN
      ]
  }
}
