import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { ReportingBalanceSheetType } from '../../types'
import ReportingBalanceSheet from '../../ReportingBalanceSheet'

interface ReportingPageBalanceSheetsWithSearchParamProps {
  balanceSheets: ReportingBalanceSheetType[]
  setFetchError: (error: string | null) => void
}

export const ReportingPageBalanceSheetWithSearchParam = ({
  balanceSheets,
  setFetchError
}: ReportingPageBalanceSheetsWithSearchParamProps) => {
  const [searchParams] = useSearchParams()

  const balanceSheetIdInParams = searchParams.get('balance-sheet-id')
  let selectedBalanceSheetId: string | undefined = undefined
  
  if (balanceSheetIdInParams) {
    const selectedBalanceSheet = balanceSheets?.find((balanceSheet) => balanceSheet.id === balanceSheetIdInParams) || null
    if (balanceSheetIdInParams === 'all') {
      selectedBalanceSheetId = 'all'
    } else {
      selectedBalanceSheetId = selectedBalanceSheet?.id
    }
  }
  
  return <ReportingBalanceSheet balanceSheets={balanceSheets || []} setFetchError={setFetchError} selectedBalanceSheetId={selectedBalanceSheetId} />
}
