import React from 'react'

import { CampaignPerBalanceSheet, CampaignsPerBalanceSheet } from '../../../../services/api/campaigns/get/types'
import Table from '../../../../components/Table'
import { ColumnItem, ElementItem } from '../../../../components/Table/types'
import Tag from '../../../../components/Tag'
import { EditFileIcon, GoogleIcon, MetaIcon, TrashIcon } from '../../../../components/Icons'
import { formatDateToFrench } from '../../../../helpers/date'
import { CampaignPlatform } from '../../../../types/formatAndPlatform'
import { AlignEnum } from '../../../../types/common'
import { getWordingFromCampaignFormat, getWordingFromCampaignStatus } from './helpers/wording'
import { getTagVariantFromStatus, tableEditIconStyle, tableNameStyle, trashIconStyle } from './style'
import { P } from '../../../../components/Text'
import { Margin } from '../../../../constants'
import { CampaignStatus } from '../../../../types/status'

type BalanceSheetViewProps = {
  campaigns: CampaignsPerBalanceSheet
  handleEditCampaign: (campaign: CampaignPerBalanceSheet) => void
  handleDelete: (campaignName: string) => void
}

const tableColumns: ColumnItem[] = [
  {
    name: 'name',
    label: 'Nom',
    align: AlignEnum.LEFT,
    width: '25%',
    isSortable: true
  },
  {
    name: 'linked',
    label: `Date d'ajout`,
    align: AlignEnum.LEFT,
    width: '20%'
  },
  {
    name: 'status',
    label: 'Statut',
    align: AlignEnum.LEFT,
    width: '15%'
  },
  {
    name: 'platform',
    label: 'Plateforme',
    align: AlignEnum.LEFT,
    width: '15%'
  },
  {
    name: 'format',
    label: 'Format',
    align: AlignEnum.LEFT,
    width: '15%'
  },
  {
    name: 'delete',
    label: '',
    align: AlignEnum.RIGHT,
    width: '10%'
  },
]

const CampaignsTable = ({
  campaigns,
  handleDelete,
  handleEditCampaign
}: BalanceSheetViewProps) => {
  const tableElements: ElementItem[] = campaigns.map((campaign) => {
    const { name, linkedAt, status, platform, format } = campaign

    return {
      name: (
        <>
          <P style={tableNameStyle}>{name}</P> 
          {status === CampaignStatus.DRAFT && 
            <EditFileIcon style={tableEditIconStyle} handleClick={() => handleEditCampaign(campaign)}/>
          }
        </>
      ),
      linked: <P>{formatDateToFrench(new Date(linkedAt), true)}</P>,
      status: (
        <Tag variant={getTagVariantFromStatus(status)}>
          {getWordingFromCampaignStatus(status)}
        </Tag>
      ),
      platform: platform === CampaignPlatform.GOOGLE
        ? <GoogleIcon style={{ marginRight: '3px' }} color='#eb4236' />
        : platform === CampaignPlatform.META 
          ? <MetaIcon color='#1970e3' />
          : <div></div>,
      format: <P>{getWordingFromCampaignFormat(format)}</P>,
      delete: <TrashIcon handleClick={() => handleDelete(campaign.name)} style={trashIconStyle}/>
    }
  })

  return (
    <>
      <P style={{ marginBottom: Margin.m3 }}>Liste des campagnes du bilan :</P>
      <Table
        columns={tableColumns}
        elements={tableElements}
      />
    </>
  )
}

export default CampaignsTable