import { CSSProperties } from "react"

import { Colors, FontSize, Margin } from "../../../../constants"

export const tabContainerStyle: CSSProperties = {
  justifyContent: 'flex-start',
  gap: '12px',
  boxSizing: 'border-box'
}

export const tabStyle: CSSProperties = {
  border: `1px solid ${Colors.mediumGrey}`,
  borderRadius: '50px',
  fontSize: FontSize.sm,
  width: 'fit-content',
  padding: '12px 16px',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  transition: 'all 0.2s ease-in-out',
  flex: 0,
  marginTop: Margin.m10,
  marginBottom: Margin.m5
}