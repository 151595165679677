import React from 'react'

import { IconType } from './types'
import Colors from '../../constants/colors'

const ArrowFirst = ({
  height = '15px',
  width = '15px',
  style,
  color = Colors.black
}: IconType) => {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 5.5 7"
    >
      <g
        id="Groupe_13282"
        data-name="Groupe 13282"
        transform="translate(-528 -689)"
      >
        <path
          id="Icon_feather-chevron-down"
          data-name="Icon feather-chevron-down"
          d="M9,13.5l3,3,3-3Z"
          transform="translate(546.5 680.5) rotate(90)"
          fill={color}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Ligne_159"
          data-name="Ligne 159"
          y2="6"
          transform="translate(528.5 689.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export default ArrowFirst
