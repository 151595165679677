import React, { useContext, useState } from 'react'

import { AppContext } from '../../../contexts/AppContext'
import Dropdown from '../../../components/Dropdown'
import Button from '../../../components/Button'
import { CampaignPerBalanceSheet, CampaignsPerBalanceSheet } from '../../../services/api/campaigns/get/types'
import CampaignsTable from './CampaignsTable'
import { Margin } from '../../../constants'
import BalanceSheetStatusEdition from './BalanceSheetStatusEdition'
import { ButtonsContainer } from './styles'
import { UserPermissionsEnum } from '../../../types/permissions'

export interface BalanceSheetSelectionProps {
  company: {
    names: string[]
    selected: string | null
  },
  balanceSheet: {
    names: string[]
    selected: string | null
  },
  campaigns: CampaignsPerBalanceSheet | null,
  balanceSheetId: string | null,
  handleAddCampaign: () => void,
  handleEditCampaign: (value: CampaignPerBalanceSheet) => void,
  handleCompanyChange: (value: string | null) => void,
  handleBalanceSheetChange: (value: string | null) => void
  handleLinkCampaign: () => void
  handleDelete: (campaignName: string) => void
}

const BalanceSheetSelection = ({ company, balanceSheet, campaigns, balanceSheetId,
  handleAddCampaign, handleEditCampaign, 
  handleCompanyChange, handleBalanceSheetChange, handleLinkCampaign, handleDelete }: 
    BalanceSheetSelectionProps) => {

  const { userType } = useContext(AppContext)

  const displayBalanceSheets = company.selected != null
  const displayButton = balanceSheet.selected != null
  const isSelfProvisioningUser = userType === UserPermissionsEnum.SELF_PROVISIONING
  const isProvisioningUser = userType === UserPermissionsEnum.PROVISIONING

  return (
    <div>
      {isProvisioningUser && (
        <Dropdown
          label='Entreprise'
          options={company.names}
          selected={company.selected}
          onValueChange={handleCompanyChange}
          style={{ flex: 1 }}
        />
      )}
      {(isSelfProvisioningUser || displayBalanceSheets) && <Dropdown
        label='Bilan'
        options={balanceSheet.names}
        selected={balanceSheet.selected}
        onValueChange={handleBalanceSheetChange}
        style={{ flex: 1 }}
      />}
      {campaigns != null && <CampaignsTable campaigns={campaigns} handleEditCampaign={handleEditCampaign} handleDelete={handleDelete}/>}
      {displayButton && (
        <ButtonsContainer>
          <Button onClick={handleAddCampaign} small style={{ marginTop: Margin.m7 }}>Ajouter une campagne</Button>
          <Button onClick={handleLinkCampaign} small style={{ marginTop: Margin.m7 }} type='secondary'>Lier une campagne existante</Button>
        </ButtonsContainer>
      )}
      {balanceSheetId && <BalanceSheetStatusEdition balanceSheetId={balanceSheetId} />}
    </div>
  )
}

export default BalanceSheetSelection