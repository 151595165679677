import { CSSProperties } from "react"
import styled from "@emotion/styled"

import { Padding, Colors, Margin } from "../../../../constants"

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: ${Padding.p3};
  border-bottom: 1px solid ${Colors.darkBlue_015};
  margin-bottom: ${Margin.m6};
`

export const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const downloadIcon: CSSProperties = {
  marginLeft: Margin.m4
}
