import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  mapReportingApiTabSection,
  ReportingBalanceSheetType,
  ReportingHighlightsDataType,
  SectionDataType,
  ReportingTabKeyEnum,
  ReportingBalanceSheetDownloadDataType
} from '../types'
import { getReportingDataForSections, PageReportingTypeEnum, SectionsToFetch } from '../helpers/query'
import ReportingBody from '../ReportingBody'
import ReportingHeaderBalanceSheet from '../ReportingHeader/balanceSheet'

const tabs: ReportingTabKeyEnum[] = [
  ReportingTabKeyEnum.impact,
  ReportingTabKeyEnum.global,
  ReportingTabKeyEnum.platforms,
  ReportingTabKeyEnum.campaigns
]

export interface ReportingBalanceSheetProps {
  balanceSheets: ReportingBalanceSheetType[]
  setFetchError: (error: string | null) => void
  selectedBalanceSheetId?: string | null
}

const ReportingBalanceSheet = ({ balanceSheets, setFetchError, selectedBalanceSheetId }: ReportingBalanceSheetProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [isHighlightsDataLoading, setIsHighlightsDataLoading] = useState(true)
  const [highlightsData, setHighlightsData] = useState<ReportingHighlightsDataType | null>(null)
  const [isSectionDatatLoading, setIsSectionDataLoading] = useState(true)
  const [sectionData, setSectionData] = useState<SectionDataType | null>(null)

  const [_, setSearchParams] = useSearchParams()

  const selectedBalanceSheet = balanceSheets?.find((balanceSheet) => balanceSheet.id === selectedBalanceSheetId) || null

  useEffect(() => {
    if (selectedBalanceSheet) {
      const sectionsToFetch: SectionsToFetch = {
        highlights: isHighlightsDataLoading,
        sectionTabName: mapReportingApiTabSection(tabs[activeTabIndex])
      }
      ;(async () => {
        const { sectionData: newSectionData, highlightsData: newHighlightsData, error} = await
          getReportingDataForSections({
            id: selectedBalanceSheet.id,
            sections: sectionsToFetch,
            startDate: undefined,
            endDate: undefined,
            pageType: PageReportingTypeEnum.BALANCE_SHEET
          })

        if (error) {
          setFetchError('Une erreur est survenue lors du chargement des KPI')
        } else {
          if (newSectionData !== null) {
            setSectionData(newSectionData)
          }
          if (newHighlightsData !== null) {
            setHighlightsData(newHighlightsData)
          }
        }

        setIsHighlightsDataLoading(false)
        setIsSectionDataLoading(false)
      })()
    } else {
      setHighlightsData(null)
      setSectionData(null)
    }
  }, [activeTabIndex, selectedBalanceSheet])

  const handleChangeTabIndex = (index: number) => {
    if (index !== activeTabIndex) {
      setActiveTabIndex(index)
      setIsSectionDataLoading(true)
    }
  }

  const handleBalanceSheetChange = (balanceSheetName: string | null) => {
    let selectedBalanceSheet: ReportingBalanceSheetType | null = null

    if (balanceSheetName) {
      selectedBalanceSheet = balanceSheets?.find((balanceSheet) => balanceSheet.name === balanceSheetName) || null
    }
    
    if(selectedBalanceSheet) {
      setSearchParams({ 'balance-sheet-id': selectedBalanceSheet.id })
    }

    setIsHighlightsDataLoading(true)
  }

  const downloadData: ReportingBalanceSheetDownloadDataType | null = highlightsData !== null ? [
    {
      clicks: highlightsData.clicks,
      co2: highlightsData.co2,
      cost: highlightsData.cost,
      impressions: highlightsData.impressions,
      energy: highlightsData.energy
    }
  ] : null

  return (
    <>
      <ReportingHeaderBalanceSheet
        balanceSheets={balanceSheets}
        selectedBalanceSheet={selectedBalanceSheet}
        selectedStartDate={null}
        selectedEndDate={null}
        onBalanceSheetChange={handleBalanceSheetChange}
        onChangeStartDateInput={() => {}}
        onChangeEndDateInput={() => {}}
      />
      {selectedBalanceSheet &&
        <ReportingBody
          pageType={PageReportingTypeEnum.BALANCE_SHEET}
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          isHighlightsDataLoading={isHighlightsDataLoading}
          highlightsData={highlightsData}
          downloadData={downloadData}
          isSectionDatatLoading={isSectionDatatLoading}
          sectionData={sectionData}
          onChangeTabIndex={handleChangeTabIndex}
        />
      }
    </>
  )
}

export default ReportingBalanceSheet