import React from 'react'

import { InputFile } from '../../../../../components/InputFile'
import { P } from '../../../../../components/Text'
import { Margin } from '../../../../../constants'

export interface CampaignDetailsProps {
  label?: string
  csvFile: File | null
  submitFile: (value?: File) => void
  deleteFile: () => void
  error: string | null
  uniqueKey?: string
}


const InputFileSection = ({ 
  label,
  csvFile,
  submitFile,
  deleteFile,
  error,
  uniqueKey
}: CampaignDetailsProps) => {
  return (
    <div style={{ marginTop: Margin.m5 }}>
      {label && <P style={{ marginBottom: Margin.m3 }}>{label}</P>}
      <InputFile
        handleChange={(e) => {submitFile(e?.target?.files?.[0])}}
        selectedFile={csvFile}
        handleDelete={deleteFile}
        fileUrl={undefined}
        uniqueKey={uniqueKey ?? 'input-file'}
        error={error ?? undefined}
      />
    </div>
  )
}

export default InputFileSection