import React from 'react'
import { Margin } from '../../../../../constants'
import Input from '../../../../../components/Input'

interface CustomInputNumberProps {
  label: string
  value: number
  handleChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CustomInputNumber = ({ label, value, handleChangeValue }: CustomInputNumberProps) => {
  return <div style={{marginTop: Margin.m7}}>
    <Input 
      type='number' 
      value={value.toString()}
      onChange={handleChangeValue}
      containerWidth="100%"
      label={label}
    />
  </div>
}