import styled from "@emotion/styled"

import { Colors } from "../../../../constants"

export const Row = styled.li<{ isClickable?: boolean, index: number }>`
  ${({ index }) => index !== 0 && `border-top: 1px solid ${Colors.darkBlue_015};`}
  position: relative;
  overflow: hidden;
  height: 54px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  background-color: ${Colors.white};

  :hover {
    background-color: ${Colors.veryLightGrey};
  }
`

export const RowContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const IconContainer = styled.span`
  display: inline-block;
  margin-left: auto;
`

export const actionButtonSize = 30
