import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import { P } from '../../../../../components/Text'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import KpiCard from '../KpiCard'

type PropsType = {
  co2PerEuro?: number
  isLoading: boolean
}

export const CO2PerEuroCard = ({
  co2PerEuro,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.co2perEuro}
      title="CO₂ /€ dépensé"
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : co2PerEuro !== undefined ? Math.floor(co2PerEuro * 100) / 100 + ' g/€' : 'N/A'
      }
      tooltipContent={<P small>{`La quantité totale de CO₂ émise par euro dépensé pour diffuser la campagne publicitaire`}</P>}
    />
  )
}
