import styled from '@emotion/styled'

import Colors from '../../../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../../../constants/fontFamily'

export const StyledH3 = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  font-family: ${YETIC_FONT_FAMILY};
  color: ${Colors.darkBlue};
`
