import styled from "@emotion/styled"

import { Colors, FontWeight, Margin, Padding } from "../../constants"

export const StyledWrapper = styled.div<{ width: string }>`
  width: ${props => props.width};
  height: 100%;
  position: relative;
  padding: ${Padding.p10};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: end;
  background-color: ${Colors.green};
`

export const StyledPicture = styled.img`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  right: ${Margin.m10};
  height: 500px;
  z-index: 20;
`

export const StyledLettersContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`

export const StyledLetter = styled.span<{ fontSize: string }>`
  display: 'inline';
  color: ${Colors.yellow};
  font-weight: ${FontWeight.YETIC_BOLD};
  text-transform: 'uppercase';
  font-size: ${props => props.fontSize};
`

