import React, { CSSProperties, useEffect, useRef, useState } from 'react'

import { LineChartAggregatorEnum, TooltipComponentType, TooltipRenderer } from './types'
import Dropdown from '../../Dropdown'
import { LineChartContainer, DropDownContainer, dropDownStyle, LineChartTooltipContainer, LineChartRoot } from './style'
import { defaultFormatHeaderData } from './helpers/formatHeaderData'
import { drawChart } from './helpers/drawChart/drawChart'
import LineChartHeaderSelection from './HeaderSelection'
import { LineChartCaptions } from './Captions'
import { ChartTitle } from '../style'
import { H3 } from '../../Text'

export type Point = {
  time: Date
  value: number
  endTime?: Date
  percentageEvolution?: number
}

export type LineChartDataElement<PointType> = {
  name: string
  color: string
  values: PointType[]
  isAugmentationGood: boolean
  unit?: string
  caption: string
  aggregator?: LineChartAggregatorEnum
}

export type LineChartData<PointType> = LineChartDataElement<PointType>[]

export type FormattedPoint = {
  datasetIndex: number
  index: number
  time: string
  value: number
  endTime?: string
}

export type DatasetsSelection = {
  name: string
  color: string
  isSelected: boolean
  headerKpi?: string
  unit?: string
}[]

export type DropDownData = {
  options: string[]
  value: string
  onChange: (selectedOption: string | null) => void
}

type PropsType<PointType extends Point> = {
  title?: string
  initialSelectionNames?: string[]
  data: LineChartData<PointType>
  dropDownData: DropDownData | null
  chartId: string
  formatHeaderData?: (
    data: LineChartData<PointType>,
    initialSelectionNames?: string[],
    globalCTR?: number
  ) => DatasetsSelection
  renderTooltip: TooltipRenderer<PointType>
  tooltipComponent: TooltipComponentType<PointType>
  cardWidth: string
  chartWidth: number
  style?: CSSProperties
}

const LineChartDrawer = <PointType extends Point>({
  title,
  initialSelectionNames,
  data,
  formatHeaderData = defaultFormatHeaderData,
  cardWidth,
  chartWidth,
  style,
  dropDownData,
  tooltipComponent,
  renderTooltip,
  chartId,
  globalCTR
}: PropsType<PointType> & { globalCTR?: number }) => {
  const [selectedDatasets, setSelectedDatasets] = useState<DatasetsSelection>(
    formatHeaderData(data, initialSelectionNames, globalCTR)
  )

  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setSelectedDatasets(
      formatHeaderData(data, initialSelectionNames, globalCTR)
    )
  }, [data, globalCTR])

  useEffect(() => {
    filteredData &&
      drawChart({
        chartId,
        chartRef,
        data: filteredData,
        width: chartWidth,
        renderTooltip
      })
  }, [data, selectedDatasets])

  const handleDatasetClick = (datasetIndex: number) => {
    const newSelectedDatasets = [...selectedDatasets]
    newSelectedDatasets[datasetIndex].isSelected =
      !newSelectedDatasets[datasetIndex].isSelected

    setSelectedDatasets(newSelectedDatasets)
  }

  const filteredData = data.filter(
    (d, index) => !!selectedDatasets[index].isSelected
  )

  if (data.length === 0) {
    return null
  }

  const TooltipComponent = tooltipComponent

  return (
    <LineChartContainer width={cardWidth} style={style}>
      {title && <ChartTitle><H3>{title}</H3></ChartTitle>}
      {data.length > 1 && <LineChartHeaderSelection
        datasets={selectedDatasets}
        handleDatasetClick={handleDatasetClick}
        width={cardWidth}
        globalCTR={globalCTR}
      />}
      {dropDownData && (
        <DropDownContainer>
          <Dropdown
            options={dropDownData.options}
            onValueChange={dropDownData.onChange}
            selected={dropDownData.value}
            style={dropDownStyle}
          />
        </DropDownContainer>
      )}
      <LineChartTooltipContainer id={`${chartId}-tooltip`}>
        <TooltipComponent chartId={chartId} data={filteredData} />
      </LineChartTooltipContainer>
      <LineChartRoot ref={chartRef}></LineChartRoot>
      <LineChartCaptions
        data={filteredData}
        selectedDatasets={selectedDatasets}
      />
    </LineChartContainer>
  )
}

export default LineChartDrawer
