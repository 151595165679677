import { backendURL } from "../../../../constants"
import { axiosPost, checkIsAxiosPostOutputSuccess } from "../../../../helpers/axios/post"
import { ErrorType } from "../../../../types/error"
import { checkLinkCampaignToBalanceSheetOutput, LinkCampaignToBalanceSheetOutput } from "./types"

type LinkCampaignToBalanceSheetCampaignInput = {
  campaignId: string
  balanceSheetId: string
}

export const linkCampaignToBalanceSheetCampaign = async ({
  campaignId,
  balanceSheetId
}: LinkCampaignToBalanceSheetCampaignInput): Promise<LinkCampaignToBalanceSheetOutput | ErrorType> => {
  const response = await axiosPost({
    url: `${backendURL}/balance-sheets/${balanceSheetId}/campaigns`,
    payload: {
      campaignId
    }
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkLinkCampaignToBalanceSheetOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors de la mise à jour du bilan des campagnes.'
  }
}