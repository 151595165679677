import React from 'react'

import { IconType } from './types'
import { Colors } from '../../constants'

const ReportingIcon = ({
  height = '20px',
  width = '20px',
  color = Colors.black,
  style,
  handleClick
}: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={style}
      onClick={handleClick}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M21 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V3M15 10V17M7 13V17M19 5V17M11 7V17"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ReportingIcon
