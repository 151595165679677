import React, { useState } from 'react'

import Button from '../../../../../components/Button'
import {
  CampaignPlatform,
  GoogleCampaignFormat,
} from '../../../../../types/formatAndPlatform'
import { checkIfErrorType } from '../../../../../types/error'
import { Colors, Margin } from '../../../../../constants'
import { SubmitFileErrorEnum, submitFileErrorWording } from '../constants/error'
import { H3 } from '../../../../../components/Text'
import { checkGooglePerfMaxLog } from '../../../../../helpers/files/csv/checkGooglePerfMaxLog'
import { checkGooglePerfMaxAssets } from '../../../../../helpers/files/csv/checkGooglePerfMaxAsset'
import { updateLogsCampaignApi } from '../../../../../services/api/campaigns/update/googlePerfMax/logs'
import { updateAssetsCampaignApi } from '../../../../../services/api/campaigns/update/googlePerfMax/assets'
import { updateCampaignApi } from '../../../../../services/api/campaigns/update/googlePerfMax/details'
import InputFileSection from '../InputFileSection'
import { CustomInputNumber } from './CustomInputNumber'
import AnimatedLoader from '../../../../../components/AnimatedLoader'

export interface GoogleDisplayDetailsProps {
  name: string
  campaignId: string
  handleEndProvisioning: () => void
}

const campaignPlatform = CampaignPlatform.GOOGLE
const campaignFormat = GoogleCampaignFormat.PERF_MAX

const GooglePerfMaxDetails = ({ 
  name,
  campaignId,
  handleEndProvisioning 
}: GoogleDisplayDetailsProps) => {
  const [duration, setDuration] = useState<number>(0)
  const [ratioSmartphone, setRatioSmartphone] = useState<number>(0)
  const [ratioDesktop, setRatioDesktop] = useState<number>(0)
  const [ratioTablet, setRatioTablet] = useState<number>(0)
  const [ratioTv, setRatioTv] = useState<number>(0)
  const [csvLogFile, setCsvLogFile] = useState<File | null>(null)
  const [csvAssetFile, setCsvAssetFile] = useState<File | null>(null)
  const [fetchLogFileError, setFetchLogFileError] = useState<string | null>(null)
  const [fetchAssetFileError, setFetchAssetFileError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const areCsvFilesValid = csvLogFile != null && csvAssetFile != null

  const mapKeyAndSetNumberState = {
    'duration': setDuration,
    'ratioSmartphone': setRatioSmartphone,
    'ratioDesktop': setRatioDesktop,
    'ratioTablet': setRatioTablet,
    'ratioTv': setRatioTv
  }

  const handleChangeInputNumber = (e: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof mapKeyAndSetNumberState
  ) => {
    try {
      const value = parseInt(e.target.value)
      const setter = mapKeyAndSetNumberState[key]
      setter(value)
    } catch (_) {
      alert('La mise à jour de la durée de la vidéo a échoué')
    }
  }

  const submitLogFile = async (value?: File) => {
    if (value === undefined) {
      setFetchLogFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_NOT_FOUND])
    } else {
      const isFormatValid = await checkGooglePerfMaxLog({ csvFile: value })
      if (isFormatValid) {
        setCsvLogFile(value)
        setFetchLogFileError(null)
      } else {
        setFetchLogFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_INVALID])
      }
    }
  }

  const submitAssetFile = async (value?: File) => {
    if (value === undefined) {
      setFetchAssetFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_NOT_FOUND])
    } else {
      const isFormatValid = await checkGooglePerfMaxAssets({ csvFile: value })
      if (isFormatValid) {
        setCsvAssetFile(value)
        setFetchAssetFileError(null)
      } else {
        setFetchAssetFileError(submitFileErrorWording[SubmitFileErrorEnum.FILE_INVALID])
      }
    }
  }

  const deleteLogFile = () => {
    setCsvLogFile(null)
    setFetchLogFileError(null)
  }

  const deleteAssetFile = () => {
    setCsvAssetFile(null)
    setFetchAssetFileError(null)
  }

  const validateProvisioning = async () => {
    setLoading(true)

    if (csvLogFile == null) {
      alert('Log file not found')
      return
    } else if (csvAssetFile == null) {
      alert('Asset file not found')
      return
    }

    const resultUpdateDetails = await updateCampaignApi({
      campaignId, duration,
      ratioSmartphone, ratioDesktop, ratioTablet, ratioTv
    })

    if (checkIfErrorType(resultUpdateDetails)) {
      alert('La mise à jour des attributs durée et ratios a échoué')
      return
    } 
    
    const resultUpdateLogs = await updateLogsCampaignApi({ campaignId, platform: campaignPlatform, format: campaignFormat, csvFile: csvLogFile })

    if (checkIfErrorType(resultUpdateLogs)) {
      alert('L upload des logs de la campagne a échoué')
      return
    } 
    const resultUpdateAssets = await updateAssetsCampaignApi({ campaignId, platform: campaignPlatform, format: campaignFormat, csvFile: csvAssetFile })

    setLoading(false)
    if (!checkIfErrorType(resultUpdateAssets)) {
      handleEndProvisioning()
    } else {
      alert('L upload du fichier des assets a échoué')
    }
  }

  return (
    <div>
      <H3>{`Campagne : "${name}"`}</H3>
      <CustomInputNumber
        label={`Durée de la vidéo (en secondes). 0 si aucune vidéo`}
        value={duration}
        handleChangeValue={(e) => handleChangeInputNumber(e, 'duration')}
      />
      <CustomInputNumber
        label={`Impr. vidéo (smartphone)`}
        value={ratioSmartphone}
        handleChangeValue={(e) => handleChangeInputNumber(e, 'ratioSmartphone')}
      />
      <CustomInputNumber
        label={`Impr. vidéo (desktop)`}
        value={ratioDesktop}
        handleChangeValue={(e) => handleChangeInputNumber(e, 'ratioDesktop')}
      />
      <CustomInputNumber
        label={`Impr. vidéo (tablette)`}
        value={ratioTablet}
        handleChangeValue={(e) => handleChangeInputNumber(e, 'ratioTablet')}
      />
      <CustomInputNumber
        label={`Impr. vidéo (Tv)`}
        value={ratioTv}
        handleChangeValue={(e) => handleChangeInputNumber(e, 'ratioTv')}
      />
      <InputFileSection 
        label="Fichier de logs :"
        uniqueKey='input-csv-campaign-logs'
        csvFile={csvLogFile} 
        submitFile={submitLogFile} 
        deleteFile={deleteLogFile} 
        error={fetchLogFileError} 
      />
      <InputFileSection 
        label="Fichier avec les assets :"
        uniqueKey='input-assets'
        csvFile={csvAssetFile} 
        submitFile={submitAssetFile} 
        deleteFile={deleteAssetFile} 
        error={fetchAssetFileError} 
      />
      {areCsvFilesValid && 
        <Button onClick={validateProvisioning} small style={{marginTop: Margin.m7}}>
          {loading ? <AnimatedLoader color={Colors.white}/>: 'Confirmer le provisioning'}
        </Button>
      }
    </div>
  )
}

export default GooglePerfMaxDetails