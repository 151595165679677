import { ColumnItem } from "../../../components/Table/types"
import { AlignEnum } from "../../../types/common"

export const invoicesTableColumns: ColumnItem[] = [
  {
    name: 'invoiceId',
    label: 'ID',
    align: AlignEnum.LEFT,
    width: '180px',
  },
  {
    name: 'date',
    label: 'Date',
    align: AlignEnum.LEFT,
    width: '120px',
  },
  {
    name: 'status',
    label: 'Statut',
    align: AlignEnum.LEFT,
    width: '150px',
  },
  {
    name: 'price',
    label: 'Montant HT',
    unit: '€',
    align: AlignEnum.LEFT,
    width: '150px',
  },
  {
    name: 'description',
    label: 'Description',
    width: '450px',
    align: AlignEnum.LEFT,
  },
  {
    name: 'download',
    label: '',
    align: AlignEnum.RIGHT,
  },
] 