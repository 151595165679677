import { checkIfCreateBalanceSheetApiOutput, CreateBalanceSheetApiOutput } from "./types"
import { axiosPost, checkIsAxiosPostOutputSuccess } from "../../../../helpers/axios/post"
import { backendURL } from "../../../../constants"
import { ErrorType } from "../../../../types/error"
import { ApiPlatformEnum } from "../../../../constants/api"

type CreateBalanceSheetApiInput = {
  name: string
  startDate: string
  endDate: string
  platforms: ApiPlatformEnum[]
}

export const createBalanceSheetApi = async ({
  name,
  startDate,
  endDate,
  platforms
}: CreateBalanceSheetApiInput): Promise<CreateBalanceSheetApiOutput | ErrorType> => {
  const response = await axiosPost({
    url: `${backendURL}/balance-sheets`,
    payload: {
      name,
      startDate,
      endDate,
      platforms
    }
  })

  if (checkIsAxiosPostOutputSuccess(response)) {
    if (checkIfCreateBalanceSheetApiOutput(response.data)) {
      return response.data
    }
  }

  return {
    error: 'Une erreur est survenue lors de la création du bilan.'
  }
}