import React, { ReactNode } from 'react'

import PageTemplate from '../../templates/Page'
import Button from '../../components/Button'
import AddIcon from '../../components/Icons/add'
import LoadingPage from '../LoadingPage'
import ErrorPage from '../ErrorPage'
import { GetBalanceSheetApiOutput, GetBalanceSheetsApiOutput } from '../../services/api/balanceSheets/get/types'
import { convertApiStatusToStatus } from './types'
import Table from '../../components/Table'
import { ColumnItem, ElementItem } from '../../components/Table/types'
import { AlignEnum } from '../../types/common'
import { formatDatePeriodToFrench } from '../../helpers/date'
import Tag from '../../components/Tag'
import { tableEditIconStyle, tableNameStyle, tiktokIconStyle } from './style'
import { ApiPlatformEnum } from '../../constants/api'
import { GoogleIcon, MetaIcon, ReportingIcon, TiktokIcon } from '../../components/Icons'
import { P } from '../../components/Text'
import { getTagVariantFromStatus } from '../../components/Tag/style'


type BalanceSheetViewProps = {
  children?: ReactNode
  fetchError: string
  balanceSheets: GetBalanceSheetsApiOutput | null
  onReportingClick: (balanceSheet: GetBalanceSheetApiOutput) => void
  onCtaClick: () => void
}

const tableColumns: ColumnItem[] = [
  {
    name: 'name',
    label: 'Nom',
    align: AlignEnum.LEFT,
    width: '30%',
    isSortable: true
  },
  {
    name: 'period',
    label: 'Période',
    align: AlignEnum.LEFT,
    width: '30%'
  },
  {
    name: 'status',
    label: 'Statut',
    align: AlignEnum.LEFT
  },
  {
    name: 'platforms',
    label: 'Plateformes',
    align: AlignEnum.RIGHT
  }
]

const BalanceSheetView = ({
  children,
  fetchError,
  balanceSheets,
  onReportingClick,
  onCtaClick
}: BalanceSheetViewProps) => {
  if (fetchError) {
    return <ErrorPage message={fetchError} />
  }

  if (!balanceSheets) {
    return <LoadingPage />
  }

  const tableElements: ElementItem[] = balanceSheets.map((balanceSheet) => {
    const { name, startDate, endDate, status, platforms } = balanceSheet

    return {
      name: (
        <>
            <P style={tableNameStyle} title={name}>{name}</P> 
            <ReportingIcon style={tableEditIconStyle} handleClick={() => onReportingClick(balanceSheet)}/>
        </>
      ),
      period: <P>{formatDatePeriodToFrench(new Date(startDate), new Date(endDate))}</P>,
      status: (
        <Tag variant={getTagVariantFromStatus(convertApiStatusToStatus(status))}>
          {convertApiStatusToStatus(status)}
        </Tag>
      ),
      platforms:(
        <>
          {platforms.includes(ApiPlatformEnum.GOOGLE) && (
            <GoogleIcon style={{ marginRight: '3px' }} color='#eb4236' />
          )}
          {platforms.includes(ApiPlatformEnum.META) && (
            <MetaIcon color='#1970e3' />
          )}
          {platforms.includes(ApiPlatformEnum.TIKTOK) && (
            <TiktokIcon style={tiktokIconStyle}/>
          )}
        </>
      )
    }
  })

  return (
    <PageTemplate
      title='Mes bilans'
      cta={<Button small icon={<AddIcon color='white' />} onClick={onCtaClick}>Nouveau bilan</Button>}
    >
      <Table
        columns={tableColumns}
        elements={tableElements}
      />
      {children}
    </PageTemplate>
  )
}

export default BalanceSheetView