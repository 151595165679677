import React, { ChangeEvent, CSSProperties } from 'react'
import { CheckboxContainer, CheckboxInput } from './style'

interface CheckboxProps {
  selected?: boolean
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | (() => void)
  style?: CSSProperties
  inputStyle?: CSSProperties
  children?: React.ReactNode
  disabled?: boolean
}

const Checkbox = ({
  selected = false,
  onChange,
  children,
  style,
  inputStyle,
  disabled = false
}: CheckboxProps) => {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange && !disabled) {
      onChange(event)
    }
  }

  return (
    <CheckboxContainer style={style}>
      <CheckboxInput
        type="checkbox"
        checked={selected}
        onChange={handleCheckboxChange}
        style={inputStyle}
      />
      {children}
    </CheckboxContainer>
  )
}

export default Checkbox
