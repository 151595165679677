import styled from "@emotion/styled"

import { Colors, Margin, Padding } from "../../../constants"

export const ButtonContainer = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  width: fit-content;
  border: 2px solid
    ${(props) => (props.active ? Colors.greyHoverBorder : Colors.greyBorder)};
  background-color: ${(props) =>
    props.active ? Colors.greyBorder : Colors.lightGrey};
  min-width: 90px;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    border-color: ${Colors.greyHoverBorder};
  }
`

export const ChoiceButtonInput = styled.input`
  visibility: hidden;
  width: 1px;
  margin: 0;
`

export const ChoiceButtonLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${Margin.m3};
  cursor: pointer;
  padding: ${Padding.p3} ${Padding.p5};
`
