import styled from "@emotion/styled"

import { Colors, Margin, Padding } from "../../constants"

export const MainWrapper = styled.form`
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: ${Colors.grey};
  display: flex;
`

export const LogoWrapper = styled.div`
  text-align: left;
  padding: ${Padding.p8} 0 0 ${Padding.p8};
`

export const StyledWrapper = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  height: fit-content;
  min-height: 100vh;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Padding.p11};
  padding-top: ${Padding.p8};
  max-width: 520px;
  margin: auto;
  gap: ${Margin.m7};
`