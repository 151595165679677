export type LoginApiInput = {
  email: string
  password: string
  companyName: string | null
}

export type LoginOutputSuccess = { success: boolean }

export type LoginSeveralCompaniesSuccess = { companies: string[] }

export type LoginOutputError = {
  error: string
}

export type LoginApiOutput =
  | LoginOutputSuccess
  | LoginOutputError
  | LoginSeveralCompaniesSuccess

export const checkIfLoginOutputSuccess = (
  val: any
): val is LoginOutputSuccess => {
  return val
    && typeof val.success === 'boolean'
}

export const checkIfLoginSeveralCompaniesSuccess = (
  val: any
): val is LoginSeveralCompaniesSuccess => {
  return val && Array.isArray(val.companies) && val.companies.every((item: any) => typeof item === 'string')
}

export const checkIfLoginOutputError = (
  val: any
): val is LoginOutputError => {
  return val && typeof val.error === 'string'
}

export const checkIfLoginOutput = (
  val: any
): val is LoginApiOutput => {
  return (
    checkIfLoginOutputError(val) || checkIfLoginOutputSuccess(val) || checkIfLoginSeveralCompaniesSuccess(val)
  )
}
