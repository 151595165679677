export enum BalanceSheetApiStatusEnum {
  READY = 'ready',
  PROVISIONING_IN_PROGRESS = 'provisioning_in_progress',
  DELETED = 'deleted',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  WAITING_FOR_PROVISIONING = 'waiting_for_provisioning',
}

export const checkIsBalanceSheetApiStatus = (status: string): status is BalanceSheetApiStatusEnum => {
  return Object.values(BalanceSheetApiStatusEnum).includes(status as BalanceSheetApiStatusEnum)
}

export enum ApiPlatformEnum {
  GOOGLE = 'google',
  META = 'meta',
  TIKTOK = 'tiktok'
}

export const checkIsApiPlatform = (platform: string): platform is ApiPlatformEnum => {
  return Object.values(ApiPlatformEnum).includes(platform as ApiPlatformEnum)
}