import { CSSProperties } from "react"
import styled from "@emotion/styled"

import { Colors, Margin, Padding } from "../../../../constants"

export const TipContainer = styled.div`
  background-color: ${Colors.blue_01};
  border-radius: 4px;
  padding: ${Padding.p5};
  display: flex;
  align-items: flex-start;
  gap: ${Margin.m5};
  width: 100%;
  line-height: 1.5;
`

export const tipIconStyle: CSSProperties = {
  overflow: 'visible'
}