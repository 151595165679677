export enum GoogleCampaignFormat {
  SEARCH = "search",
  DISPLAY = "display",
  VIDEO = "video",
  PERF_MAX = "performance max"
}

export enum MetaCampaignFormat {
  TEXT = "text",
  INSTA = "insta"
}

export enum TiktokCampaignFormat {
  SHORT = "short"
}

export type CampaignFormat = GoogleCampaignFormat | MetaCampaignFormat | TiktokCampaignFormat

export enum CampaignPlatform {
  GOOGLE = "google",
  META = "meta",
  TIKTOK = "tiktok"
}

export const formatsByPlatform = {
  [CampaignPlatform.GOOGLE]: [
    GoogleCampaignFormat.SEARCH, 
    GoogleCampaignFormat.DISPLAY,
    GoogleCampaignFormat.VIDEO,
    GoogleCampaignFormat.PERF_MAX
  ],
  [CampaignPlatform.META]: [MetaCampaignFormat.TEXT, MetaCampaignFormat.INSTA],
  [CampaignPlatform.TIKTOK]: [TiktokCampaignFormat.SHORT],
}

export const availablePlatforms: CampaignPlatform[] = [CampaignPlatform.GOOGLE, CampaignPlatform.META, CampaignPlatform.TIKTOK]

export const checkIfGoogleCampaignFormat = (value: string): value is GoogleCampaignFormat => {
  return Object.values(GoogleCampaignFormat).includes(value as GoogleCampaignFormat)
}

export const checkIfMetaCampaignFormat = (value: string): value is MetaCampaignFormat => {
  return Object.values(MetaCampaignFormat).includes(value as MetaCampaignFormat)
}

export const checkIfTiktokCampaignFormat = (value: string): value is TiktokCampaignFormat => {
  return Object.values(TiktokCampaignFormat).includes(value as TiktokCampaignFormat)
}

export const checkIfCampaignFormat = (value: string): value is CampaignFormat => {
  return checkIfGoogleCampaignFormat(value) || checkIfMetaCampaignFormat(value) || checkIfTiktokCampaignFormat(value)
}

export const checkIfCampaignPlatform = (value: string): value is CampaignPlatform => {
  return Object.values(CampaignPlatform).includes(value as CampaignPlatform)
}
