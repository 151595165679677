import React from 'react'

import { ErrorWrapper, pErrorStyle, RegisterRedirectWrapper, StyledWrapper } from './style'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Margin } from '../../../constants'
import { P } from '../../../components/Text'
import { LoginFieldsEnum } from '../types'
import Dropdown from '../../../components/Dropdown'
import Link from '../../../components/Text/Link'

type LoginFormProps = {
  email: string | null
  password: string | null
  companyName: string | null
  companies: string[] | null
  showPassword: boolean
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    field: LoginFieldsEnum
  ) => void
  handleCompanyChange: (value: string | null) => void
  setShowPassword: (val: boolean) => void
  getFormError: (field: LoginFieldsEnum) => string
  handleSubmit: (e: React.FormEvent) => void
  goToRegister: () => void
  apiError: string
  loading: boolean
}

const LoginForm = ({
  email,
  password,
  companyName,
  companies,
  showPassword,
  handleFieldChange,
  handleCompanyChange,
  setShowPassword,
  getFormError,
  handleSubmit,
  goToRegister,
  apiError,
  loading
}: LoginFormProps) => {
  return (
    <StyledWrapper onSubmit={handleSubmit}>
      {email !== null && <Input
        value={email}
        onChange={(e) => handleFieldChange(e, LoginFieldsEnum.EMAIL)}
        containerWidth="100%"
        type="email"
        label={'E-mail'}
        error={getFormError(LoginFieldsEnum.EMAIL)}
      />}
      {password !== null && <Input
        value={password}
        onChange={(e) => handleFieldChange(e, LoginFieldsEnum.PASSWORD)}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        containerWidth="100%"
        type="password"
        label={`Mot de passe`}
        error={getFormError(LoginFieldsEnum.PASSWORD)}
      />}
      {companies && (
        <Dropdown
          options={companies}
          selected={companyName ?? null}
          onValueChange={handleCompanyChange}
          label={'Entreprise'}
          selectStyle={{ width: '100%' }}
          style={{ maxWidth: '100%', width: '100%', marginBottom: 0 }}
          optionsStyle={{ width: '100%' }}
        />
      )}
      <Button
        width="100%"
        marginTop={Margin.m4}
        loading={loading}
      >
        Se connecter
      </Button>
      <RegisterRedirectWrapper>
        <P>Vous n'avez pas de compte ?</P>
        <Link
          onClick={goToRegister}
        >
          Inscrivez-vous
        </Link>
      </RegisterRedirectWrapper>
      <ErrorWrapper>
        <P style={pErrorStyle}>{apiError}</P>
      </ErrorWrapper>
    </StyledWrapper>
  )
}

export default LoginForm
