export type GetInvoicesApiOutput = {
  id: string
  invoiceId: string
  date: string
  description: string | null
  companyId: string
  isPaid: boolean
  fileName: string
  price: number
  createdAt: string
}[]

export const checkIsGetInvoicesApiOutput = (
  val: any
): val is GetInvoicesApiOutput => {
  return (
    Array.isArray(val) &&
    val.every(
      (item) =>
        typeof item.id === 'string' &&
        typeof item.invoiceId === 'string' &&
        typeof item.date === 'string' &&
        (typeof item.description === 'string' || item.description === null) &&
        typeof item.companyId === 'string' &&
        typeof item.isPaid === 'boolean' &&
        typeof item.fileName === 'string' &&
        typeof item.price === 'number' &&
        typeof item.createdAt === 'string'
    )
  )
}
