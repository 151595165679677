import { checkEmailFormat, checkNameFormat } from "../../../helpers/checkers"
import { registerApi } from "../../../services/api/auth/register"
import { RegisterApiOutput } from "../../../services/api/auth/register/types"
import { checkUserExistsApi } from "../../../services/api/user/checkUserExists"
import { checkIfCheckUserExistsApiSuccessOutput, CheckUserExistsApiOutput } from "../../../services/api/user/types"
import { RegisterFormErrors, RegisterStepEnum } from "../types"

type HandleRegisterSubmissionsInput = {
  email: string
  password: string
  companyName: string
  firstName: string
  lastName: string
  phone: string
  checkUserResult: CheckUserExistsApiOutput | null
  formErrors: RegisterFormErrors
  step: RegisterStepEnum
  setCheckUserResult: (value: CheckUserExistsApiOutput | null) => void
  setHasBeenSubmitted: (value: boolean) => void
  setLoading: (value: boolean) => void
  setRegisterResult: (value: RegisterApiOutput | null) => void
  setHasCompanyNameBeenEntered: (value: boolean) => void
}

export const handleRegisterSubmissions = async ({
  email,
  password,
  companyName,
  firstName,
  lastName,
  phone,
  checkUserResult,
  formErrors,
  step,
  setCheckUserResult,
  setHasBeenSubmitted,
  setLoading,
  setRegisterResult,
  setHasCompanyNameBeenEntered
}: HandleRegisterSubmissionsInput) => {
  switch (step) {
    case RegisterStepEnum.CHECK_EMAIL:
      if (checkEmailFormat(email)) {
        setLoading(true)
        const result = await checkUserExistsApi({ email })
        setCheckUserResult(result)

        if (checkIfCheckUserExistsApiSuccessOutput(result)) {
          setHasBeenSubmitted(false)
          return
        }
      } else {
        setHasBeenSubmitted(true)
      }
      break

    case RegisterStepEnum.ENTER_COMPANY_NAME:
      if (checkNameFormat(companyName)) {
        setHasCompanyNameBeenEntered(true)
        return
      }
      setHasBeenSubmitted(true)
      break

    default:
      if (Object.values(formErrors).every((err) => err === '')) {
        setLoading(true)
        const userExists = checkIfCheckUserExistsApiSuccessOutput(checkUserResult) && checkUserResult.found

        const result = await registerApi({
          email,
          password,
          companyName,
          ...(!userExists && {
            firstName,
            lastName,
            phone
          })
        })

        setRegisterResult(result)
      }

      setHasBeenSubmitted(true)
  }
}