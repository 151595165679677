import styled from "@emotion/styled"

import { StyleButtonType } from "."
import Colors from "../../constants/colors"
import { YETIC_FONT_FAMILY } from "../../constants/fontFamily"
import FontSize from "../../constants/fontSize"
import Margin from "../../constants/margin"

const BasedStyledButton = styled.button<{ small?: boolean } & StyleButtonType>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Margin.m4};

  font-family: ${YETIC_FONT_FAMILY};
  font-weight: 500;
  font-size: ${(props) => (props.small ? FontSize.sm : FontSize.base)};
  line-height: 1;
  text-align: center;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'normal' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  padding: ${(props) =>
    props.padding ? props.padding : props.small ? '12px 18px' : '14px 24px'};
  margin-bottom: ${(props: StyleButtonType) =>
    props.marginBottom ? props.marginBottom : Margin.m0};
  ${(props: StyleButtonType) =>
    props.marginTop ? `margin-top: ${props.marginTop};` : ''};

  transition: background-color 0.3s ease;
`

export const StyledButton = styled(BasedStyledButton)`
  background-color: ${(props) => props.disabled ? Colors.darkBlueDisabled : Colors.darkBlue};
  color: white;
  border: none;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
          background-color: ${Colors.hoverDarkBlue};
        }`}
`

export const StyledButtonText = styled(BasedStyledButton)`
  background: none;
  color: ${Colors.darkBlue};
  border: none;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
          text-decoration: underline;
        }`}
`

export const StyledButtonSecondary = styled(BasedStyledButton)`
  background: none;
  color: ${Colors.darkBlue};
  border: 1px solid ${Colors.darkBlue_015};
  box-sizing: border-box;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
          background-color: rgba(0, 0, 0, 0.05);
          color: ${Colors.darkBlue};
      }`}
`

export const StyledButtonTertiary = styled(BasedStyledButton)`
  background-color: ${Colors.darkYellow};
  color: ${Colors.darkBlue};
  border: none;

  ${(props) =>
    props.disabled
      ? ''
      : `&:hover {
        background-color: ${Colors.yellow};
      }`}
`