import { 
  CampaignsReportingOutputType,
  DevicesReportingOutputType,
  DevicesReportingRowType,
  DownloadReportingOutputType,
  GlobalReportingAPIType,
  GlobalReportingOutputType,
  GlobalReportingRowType,
  HighlightsReportingOutputType,
  ImpactReportingOutputType,
  LocationsReportingOutputType,
  PlatformsReportingOutputType
} from "."
import { checkIfDate } from "../../../../types/nativeTypes"

export const checkIfDevicesReportingRowType = (
  value: any,
  withCo2 = true
): value is DevicesReportingRowType => {
  if (withCo2) {
    return (
      value &&
      (value.clicks === undefined ||
        (value.clicks !== undefined && typeof value.clicks === 'number')) &&
      (value.impressions === undefined ||
        (value.impressions !== undefined &&
          typeof value.impressions === 'number')) &&
      (value.cost === undefined ||
        (value.cost !== undefined && typeof value.cost === 'number')) &&
      (value.co2 === undefined ||
        (value.co2 !== undefined && typeof value.co2 === 'number'))
    )
  }

  return (
    value &&
    (value.clicks === undefined ||
      (value.clicks !== undefined && typeof value.clicks === 'number')) &&
    (value.impressions === undefined ||
      (value.impressions !== undefined &&
        typeof value.impressions === 'number')) &&
    (value.cost === undefined ||
      (value.cost !== undefined && typeof value.cost === 'number'))
  )
}

export const checkIfGlobalReportingRowType = (
  value: any
): value is GlobalReportingRowType => {
  return (
    value &&
    value.day &&
    checkIfDate(value.day) &&
    value.value !== undefined &&
    typeof value.value === 'number'
  )
}

export const checkIfImpactReporting = (
  val: any
): val is ImpactReportingOutputType => {
  return (
    val &&
    val.co2PerDay &&
    Array.isArray(val.co2PerDay) &&
    val.co2PerDay.every((el) =>
      el &&
      el.date &&
      checkIfDate(el.date) &&
      el.co2 !== undefined &&
      typeof el.co2 === 'number'
    ) &&
    val.co2PerDevice &&
    val.co2PerDevice.smartphone !== undefined &&
    typeof val.co2PerDevice.smartphone === 'number' &&
    val.co2PerDevice.tablet !== undefined &&
    typeof val.co2PerDevice.tablet === 'number' &&
    val.co2PerDevice.desktop !== undefined &&
    typeof val.co2PerDevice.desktop === 'number' &&
    val.co2PerDevice.tv !== undefined &&
    typeof val.co2PerDevice.tv === 'number' &&
    val.co2 !== undefined &&
    typeof val.co2 === 'number'
  )
}

export const checkIfLocationsReporting = (data: any): data is LocationsReportingOutputType => {
  return (
    data &&
    Array.isArray(data) &&
    data.every((el) =>
      el &&
      el.location &&
      typeof el.location === 'string' &&
      el.clicks !== undefined &&
      typeof el.clicks === 'number' &&
      el.impressions !== undefined &&
      typeof el.impressions === 'number'
    )
  )
}

export const checkIfDevicesReporting = (
  value: any,
  withCo2 = true
): value is DevicesReportingOutputType => {
  if (withCo2) {
    return (
      value &&
      value.smartphone &&
      checkIfDevicesReportingRowType(value.smartphone) &&
      value.tablet &&
      checkIfDevicesReportingRowType(value.tablet) &&
      value.desktop &&
      checkIfDevicesReportingRowType(value.desktop) &&
      value.tv &&
      checkIfDevicesReportingRowType(value.tv)
    )
  } else {
    return (
      value &&
      value.smartphone &&
      checkIfDevicesReportingRowType(value.smartphone, false) &&
      value.tablet &&
      checkIfDevicesReportingRowType(value.tablet, false) &&
      value.desktop &&
      checkIfDevicesReportingRowType(value.desktop, false) &&
      value.tv &&
      checkIfDevicesReportingRowType(value.tv, false)
    )
  }
}

export const checkIfGlobalReporting = (
  value: any
): value is GlobalReportingOutputType => {
  return (
    value &&
    value.clicks &&
    value.clicks.every((value: any) =>
      checkIfGlobalReportingRowType(value)
    ) &&
    value.impressions &&
    Array.isArray(value.impressions) &&
    value.impressions.every((value: any) =>
      checkIfGlobalReportingRowType(value)
    ) &&
    value.cost &&
    Array.isArray(value.cost) &&
    value.cost.every((value: any) =>
      checkIfGlobalReportingRowType(value)
    ) &&
    value.co2 &&
    Array.isArray(value.co2) &&
    value.co2.every((value: any) =>
      checkIfGlobalReportingRowType(value)
    )
  )
}

export const checkIfHighlightsReporting = (
  val: any
): val is HighlightsReportingOutputType => {
  return (
    val &&
    val.clicks !== undefined &&
    typeof val.clicks === 'number' &&
    val.impressions !== undefined &&
    typeof val.impressions === 'number' &&
    val.co2 !== undefined &&
    typeof val.co2 === 'number' &&
    val.energy !== undefined &&
    typeof val.energy === 'number' &&
    val.cost !== undefined &&
    typeof val.cost === 'number' &&
    val.impact !== undefined &&
    typeof val.impact === 'number' &&
    val.co2PerEuro !== undefined &&
    typeof val.co2PerEuro === 'number' &&
    val.ctr !== undefined &&
    typeof val.ctr === 'number' &&
    val.cpc !== undefined &&
    typeof val.cpc === 'number'
  )
}

export const checkIfDownloadReporting = (
  value: any
): value is DownloadReportingOutputType => {
  return (
    Array.isArray(value) &&
    value.every(
      (row) =>
        row &&
        row.date &&
        typeof row.date === 'string' &&
        row.clicks !== undefined &&
        typeof row.clicks === 'number' &&
        row.impressions !== undefined &&
        typeof row.impressions === 'number' &&
        row.cost !== undefined &&
        typeof row.cost === 'number' &&
        row.co2 !== undefined &&
        typeof row.co2 === 'number' &&
        row.impact !== undefined &&
        typeof row.impact === 'number'
    )
  )
}

export const checkIfGlobalReportingAPI = (
  value: any
): value is GlobalReportingAPIType => {
  return (
    value &&
    Array.isArray(value) &&
    value.every((value: any) =>
      typeof value?.clicks === "number" &&
      typeof value?.impressions === "number" &&
      typeof value?.co2 === "number" &&
      typeof value?.cost === "number" &&
      checkIfDate(value?.date)
    )
  )
}

export const checkIfCampaignsReporting = (val: any): val is CampaignsReportingOutputType => {
  return (
    val &&
    Array.isArray(val) &&
    val.every((element) => 
      typeof element.campaignId === "string" &&
      typeof element.campaignName === "string" &&
      typeof element.co2 === "number" &&
      typeof element.impressions === "number" &&
      typeof element.cost === "number" &&
      typeof element.clicks === "number"
    )
  )
}

export const checkIfPlatformsReporting = (val: any): val is PlatformsReportingOutputType => {
  return (
    val &&
    (
      val.google == null ||
      (
        typeof val.google.impressions === "number" &&
        typeof val.google.clicks === "number" &&
        typeof val.google.cost === "number" &&
        typeof val.google.co2 === "number"
      )
    ) && (
      val.meta == null ||
      (
        typeof val.meta.impressions === "number" &&
        typeof val.meta.clicks === "number" &&
        typeof val.meta.cost === "number" && 
        typeof val.meta.co2 === "number"
      )
    )
  ) 
}
