const googleDisplayAssetsColumnNames = ["Asset status", "Asset", "Status", "Asset type", "Performance"]

interface CheckGoogleDisplayAssetsProps {
  csvFile: File, 
}

export const checkGoogleDisplayAssets = async ({ csvFile }: CheckGoogleDisplayAssetsProps): Promise<boolean> => {
  const fileContent = await new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      if (event.target?.result) {
        resolve(event.target.result as string)
      } else {
        reject(null)
      }
    }

    reader.onerror = () => {
      reject(null)
    }

    reader.readAsText(csvFile)
  })

  if (fileContent == null) {
    return false
  }

  const rows = fileContent.split('\n')
  if (rows.length === 0) {
    return false
  }

  const headers = rows[2].trim().split(',')
  const headersMatch = googleDisplayAssetsColumnNames.every((column, index) => headers[index] === column)

  return headersMatch
}
