import React, { useState } from 'react'

import Input from '../../../../components/Input'

type RegisterPasswordInputProps = {
  password: string
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  formError: string
}

const RegisterPasswordInput = ({
  password,
  handleFieldChange,
  formError,
}: RegisterPasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Input
      value={password}
      onChange={(e) => handleFieldChange(e)}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      containerWidth="100%"
      type="password"
      label={`Mot de passe`}
      error={formError}
    />
  )
}

export default RegisterPasswordInput
