export enum GetCompanyReportingErrorEnum {
  UNKNOWN = 'UNKNOWN',
  AUTH_FAILED = 'AUTH_FAILED',
  INVALID_DATE = 'INVALID_DATE',
  MISSING_SECTION = 'MISSING_SECTION',
  WRONG_SECTION = 'WRONG_SECTION'
}

export const GetCompanyReportingErrorEnumTranslation: Record<
GetCompanyReportingErrorEnum,
  string
> = {
  [GetCompanyReportingErrorEnum.UNKNOWN]: 'Une erreur inconnue est survenue',
  [GetCompanyReportingErrorEnum.AUTH_FAILED]: "L'authentification a échoué",
  [GetCompanyReportingErrorEnum.INVALID_DATE]:
    'Les dates doivent être au format YYYY-MM-DD',
  [GetCompanyReportingErrorEnum.MISSING_SECTION]: 'La section est requise',
  [GetCompanyReportingErrorEnum.WRONG_SECTION]:
    'La section est requise et doit être correctement formatée'
}