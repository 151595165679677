import React from 'react'

import { StyledDateFilters } from './style'
import Input from '../../../../components/Input'

type PropsType = {
  startDate: string | null
  endDate: string | null
  handleChangeStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeEndDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  defaultStartDate: string
  defaultEndDate: string
  today: string
  disabled?: boolean
}

const DateFilters = ({
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  defaultStartDate,
  defaultEndDate,
  disabled,
  today
}: PropsType) => {
  return (
    <StyledDateFilters>
      <Input
        label="Date de début"
        type="date"
        value={
          new Date(startDate ?? defaultStartDate).toISOString().split('T')[0]
        }
        onChange={handleChangeStartDate}
        width="140px"
        isRequired={false}
        min={new Date(defaultStartDate).toISOString().split('T')[0]}
        max={new Date(endDate ?? defaultEndDate).toISOString().split('T')[0]}
        disabled={disabled !== undefined ? disabled : false}
      />
      <Input
        label="Date de fin"
        type="date"
        value={new Date(endDate ?? defaultEndDate).toISOString().split('T')[0]}
        onChange={handleChangeEndDate}
        width="140px"
        isRequired={false}
        min={new Date(startDate ?? defaultStartDate).toISOString().split('T')[0]}
        max={new Date(defaultEndDate).toISOString().split('T')[0]}
        disabled={disabled !== undefined ? disabled : false}
      />
    </StyledDateFilters>
  )
}

export default DateFilters