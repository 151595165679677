import { checkNameFormat } from "../../../helpers/checkers"
import { BalanceSheetCreationFormData } from "../types"

export const defaultCreationForm: BalanceSheetCreationFormData = {
  name: '',
  startDate: '',
  endDate: '',
  platforms: []
}

export const getFormErrors = ({
  name,
  startDate,
  endDate,
  platforms
}: BalanceSheetCreationFormData): Record<keyof BalanceSheetCreationFormData, string> => ({
  name: checkNameFormat(name)
    ? ''
    : 'Le nom du bilan doit contenir au moins deux caractères.',
  startDate: startDate
    ? ''
    : 'Veuillez renseigner une date de début pour votre bilan.',
  endDate: endDate
    ? ''
    : 'Veuillez renseigner une date de fin de bilan.',
  platforms: platforms.length > 0
    ? ''
    : 'Veuillez sélectionner au moins une plateforme.'
})