import React, { ReactNode } from 'react'

import LeftMenu from '../../pages/parts/LeftMenu'
import { StyledContent, StyledContentScroll, StyledPageHeader } from './style'
import { H3 } from '../../components/Text'
import { pageByEndpoint } from '../../types/page'

type PageTemplateProps = {
  children: ReactNode
  cta?: ReactNode
  title?: string
}

const PageTemplate = ({ children, cta, title }: PageTemplateProps) => {
  const endpoint = window.location.pathname
  const page = pageByEndpoint[endpoint]

  return (
    <>
      <LeftMenu page={page} />
      <StyledContentScroll>
        <StyledContent>
          {title || cta ? (
            <StyledPageHeader>
              {title && <H3>{title}</H3>}
              {cta && <div>{cta}</div>}
            </StyledPageHeader>
          ) : null}
          {children}
        </StyledContent>
      </StyledContentScroll>
    </>
  )
}

export default PageTemplate