import styled from '@emotion/styled'

import { Margin } from '../../../../../../constants'

export const ClickImpressionCostTabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: ${Margin.m5};
`
