import React from 'react'

import AnimatedLoader from '../../../../../components/AnimatedLoader'
import Colors, { HighlightColors } from '../../../../../constants/colors'
import { formatNumber } from '../../../../../helpers/rounds'
import ImpactTooltip from '../Tooltips/ImpactTooltip'
import KpiCard from '../KpiCard'

type PropsType = {
  impact?: number
  isLoading: boolean
}

export const ImpactCard = ({
  impact,
  isLoading
}: PropsType) => {
  return (
    <KpiCard
      backgroundColor={HighlightColors.impact}
      title="Impact"
      value={
        isLoading ?
          <AnimatedLoader
            color={Colors.white}
          />
        : impact !== undefined ? formatNumber(impact * 100) + ' %' : 'N/A'
      }
      tooltipContent={<ImpactTooltip />}
    />
  )
}
