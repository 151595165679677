import styled from "@emotion/styled"
import { CSSProperties } from "react"

import { Margin } from "../../../constants"

export const StyledWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${Margin.m7};
  gap: ${Margin.m7};
  align-items: center;
`

export const StyledFormRow = styled.div`
  width: 100%;
  display: flex;
  gap: ${Margin.m7};
`

export const RegisterRedirectWrapper = styled.div`
  display: flex;
  gap: ${Margin.m3};
  margin-top: ${Margin.m4};
`

export const ErrorWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const pErrorStyle: CSSProperties = {
  color: 'red',
  textAlign: 'center',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  top: '16px',
}