import React from 'react'

import Colors from '../../constants/colors'

export type DotsProps = {
  width?: string
  height?: string
  color?: string
  onClick?: (event: React.MouseEvent) => void
  ref?: React.Ref<SVGSVGElement>
}

const Dots = ({
  width = '25px',
  height = '25px',
  color = Colors.black,
  onClick,
  ref
}: DotsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      ref={ref}
    >
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477
        12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11
        12.5523 11.4477 13 12 13Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772
         12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11
         5.55228 11.4477 6 12 6Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20C12.5523 20 13 19.5523 13 19C13
        18.4477 12.5523 18 12 18C11.4477 18 11 18.4477
         11 19C11 19.5523 11.4477 20 12 20Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Dots
