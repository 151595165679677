import styled from '@emotion/styled'
import { CSSProperties } from 'react'

import Padding from '../../constants/padding'
import Margin from '../../constants/margin'
import { Colors } from '../../constants'

export const ModalWrapper = styled.div<{ width?: string, height?: string, maxWidth?: string, maxHeight?: string }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'auto')};
  overflow-y: auto;
  padding: ${Padding.p7} ${Padding.p8};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ModalInsideContent = styled.div`
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(190deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.55));
  z-index: 9998;
`

export const ModalContent = styled.div`
  text-align: left;
  margin-top: ${Margin.m7};
`

export const descriptionStyle: CSSProperties = {
  marginTop: Margin.m3,
  marginBottom: Margin.m7,
  color: Colors.veryDarkGrey,
}

export const FooterButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Margin.m4};
  margin-top: ${Margin.m7};
  width: 100%;
`
