import React, { useState } from 'react'

import { RegisterFieldsEnum } from '../../types'
import Input from '../../../../components/Input'
import { StyledFormRow } from '../style'

type NewUserRegisterFormInputsProps = {
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  passwordConfirmation: string
  companyName: string
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    field: RegisterFieldsEnum
  ) => void
  getFormError: (field: RegisterFieldsEnum) => string
}

const NewUserRegisterFormInputs = ({
  firstName,
  lastName,
  email,
  phone,
  password,
  passwordConfirmation,
  companyName,
  handleFieldChange,
  getFormError,
}: NewUserRegisterFormInputsProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  return (
    <>
      <Input
        value={email}
        onChange={(e) => handleFieldChange(e, RegisterFieldsEnum.EMAIL)}
        containerWidth="100%"
        type="email"
        label={'E-mail'}
        error={getFormError(RegisterFieldsEnum.EMAIL)}
        disabled
      />
      <Input
        value={companyName}
        onChange={(e) => handleFieldChange(e, RegisterFieldsEnum.COMPANY_NAME)}
        containerWidth="100%"
        type="text"
        label={`Nom de l'entreprise`}
        error={getFormError(RegisterFieldsEnum.COMPANY_NAME)}
      />
      <Input
        value={password}
        onChange={(e) => handleFieldChange(e, RegisterFieldsEnum.PASSWORD)}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        containerWidth="100%"
        type="password"
        label={`Mot de passe`}
        error={getFormError(RegisterFieldsEnum.PASSWORD)}
      />
      <Input
        value={passwordConfirmation}
        onChange={(e) =>
          handleFieldChange(e, RegisterFieldsEnum.PASSWORD_CONFIRMATION)
        }
        showPassword={showPasswordConfirmation}
        setShowPassword={setShowPasswordConfirmation}
        containerWidth="100%"
        type="password"
        label={`Confirmation du mot de passe`}
        error={getFormError(RegisterFieldsEnum.PASSWORD_CONFIRMATION)}
      />
      <StyledFormRow>
        <Input
          value={lastName}
          onChange={(e) => handleFieldChange(e, RegisterFieldsEnum.LAST_NAME)}
          containerWidth="100%"
          type="text"
          label={'Nom'}
          error={getFormError(RegisterFieldsEnum.LAST_NAME)}
        />
        <Input
          value={firstName}
          onChange={(e) => handleFieldChange(e, RegisterFieldsEnum.FIRST_NAME)}
          containerWidth="100%"
          type="text"
          label={'Prénom'}
          error={getFormError(RegisterFieldsEnum.FIRST_NAME)}
        />
      </StyledFormRow>
      <Input
        value={phone}
        onChange={(e) => handleFieldChange(e, RegisterFieldsEnum.PHONE)}
        containerWidth="100%"
        type="tel"
        label={`Téléphone`}
        error={getFormError(RegisterFieldsEnum.PHONE)}
      />
    </>
  )
}

export default NewUserRegisterFormInputs
