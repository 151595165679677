import React from 'react'

import { DevicesReportingOutputType } from '../../../../../services/api/reporting/types'
import { EmptyTabWrapper } from '../style'

type PlatformsTabProps = {
  reportingData: DevicesReportingOutputType
}

const PlatformsTab = ({ reportingData }: PlatformsTabProps) => {
  return <EmptyTabWrapper>À venir!</EmptyTabWrapper>
}

export default PlatformsTab
