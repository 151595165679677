import React from 'react'

import Colors from '../../constants/colors'
import { IconType } from './types'

const TipIcon = ({
  height = '20px',
  width = '20px',
  style,
  color = Colors.blue
}: IconType) => {
  return (
    <svg 
    width={width}
    height={height}
    style={style}
    viewBox="2 1 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 16.584V18.9996C10 20.1042 10.8954 20.9996 12 20.9996C13.1046 20.9996 14 20.1042 14 18.9996L14 16.584M12 3V4M18.3643 5.63574L17.6572 6.34285M5.63574 5.63574L6.34285 6.34285M4 12H3M21 12H20M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z" stroke={color} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default TipIcon
