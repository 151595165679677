export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum AlignEnum {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export const checkIfEventTargetIsSVGElement = (
  eventTarget: EventTarget | null
): eventTarget is SVGElement => {
  return !!eventTarget && (eventTarget as SVGElement)?.ownerSVGElement != null
}

export type ListOrder = 'asc' | 'desc'