import { checkIfErrorType, ErrorType } from "../../../../types/error"

export type RegisterApiInput = {
  email: string
  firstName?: string
  lastName?: string
  phone?: string
  password: string
  companyName: string
}

export type RegisterOutputSuccess = { companyId: string, userId: string }

export type RegisterApiOutput =
  | RegisterOutputSuccess
  | ErrorType

export const checkIfRegisterOutputSuccess = (
  val: any
): val is RegisterOutputSuccess => {
  return val && typeof val.userId === 'string' && typeof val.companyId === 'string'
}

export const checkIfRegisterOutput = (
  val: any
): val is RegisterApiOutput => {
  return (
    checkIfErrorType(val) || checkIfRegisterOutputSuccess(val)
  )
}
