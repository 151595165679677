import styled from "@emotion/styled"

import { Margin, Padding } from "../../../../constants"

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Margin.m4};
  width: 100%;
  box-sizing: border-box;
  padding: ${Padding.p8} ${Padding.p8} 0 ${Padding.p8};
`

export const PieChartCaptionItem = styled.div`
  display: flex;
  gap: ${Margin.m2};
  align-items: center;
`

export const PieChartCaptionSquare = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${props => props.color}
`
