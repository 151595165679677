import React, { CSSProperties } from 'react'

import {
  InputFileContainer,
  InputFileInfo,
  InputFileLabel,
  InputImagePreviewContainer,
  StyledInputFile,
  fileNameTextStyle,
} from './styles'
import { P } from '../Text'
import FileIcon from '../Icons/file'
import { CrossClose } from '../Icons'

type InputFileProps = {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDelete: () => void
  selectedFile: File | null
  fileUrl?: string
  uniqueKey: string
  error?: string
  style?: CSSProperties
}

export const InputFile = ({
  handleChange,
  handleDelete,
  selectedFile,
  uniqueKey,
  error,
  style
}: InputFileProps) => {
  return (
    <>
      <InputFileContainer style={style}>
        <InputImagePreviewContainer>
          <FileIcon height="24px" width="24px" style={{marginLeft: '4px', marginRight: '4px'}} />
        </InputImagePreviewContainer>
        {!selectedFile && (
          <InputFileLabel htmlFor={`inputTag${uniqueKey}`}>
            Parcourir
          </InputFileLabel>
        )}
        <StyledInputFile
          onChange={handleChange}
          id={`inputTag${uniqueKey}`}
          type="file"
          accept=".csv"
        />
        <InputFileInfo>
          {
            <P style={fileNameTextStyle} small>
              {selectedFile?.name ?? 'Aucun fichier sélectionné. Seuls les CSV sont acceptés'}
            </P>
          }
          {selectedFile && <span onClick={handleDelete}>
            <CrossClose height="24px" width="24px" style={{paddingLeft: '4px', display: 'inline-block', cursor: 'pointer'}} />
          </span>}
        </InputFileInfo>
      </InputFileContainer>
      {error && <P small style={{color: 'red'}}>{error}</P>}
    </>
  )
}
