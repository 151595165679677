import { formatNumber } from '../../../../helpers/rounds'
import { LineChartData, Point, DatasetsSelection } from '../lineChartDrawer'

export const defaultFormatHeaderData = (
  data: LineChartData<Point>,
  initialSelectionNames?: string[]
): DatasetsSelection => {
  return data.map((dataset) => ({
    name: dataset.name,
    color: dataset.color,
    isSelected: initialSelectionNames
      ? initialSelectionNames.includes(dataset.name) ?? false
      : true,
    headerKpi:
      dataset.values.length > 0
        ? formatNumber(
            dataset.values.reduce((current, point) => current + point.value, 0)
          )
        : undefined,
    unit: dataset.unit
  }))
}
