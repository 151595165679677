import styled from '@emotion/styled'

import Colors from '../../../constants/colors'
import { YETIC_FONT_FAMILY } from '../../../constants/fontFamily'

export const StyledP = styled.div<{ big?: boolean; small?: boolean, bold?: boolean, onClick?: () => void }>`
  font-size: ${(props) => (props.big ? '24px' : props.small ? '14px' : '16px')};
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  line-height: 20px;
  margin: 0;
  font-family: ${YETIC_FONT_FAMILY};
  color: ${Colors.darkBlue};
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`
