import React from 'react'

import { Separator, Container } from './style'

type PropsType = {
  color?: string
  marginTop?: string
  width?: string
  styleSeparator?: React.CSSProperties
  styleContainer?: React.CSSProperties
  padding?: string
}

const HorizontalSeparator = ({
  color,
  marginTop,
  width,
  styleSeparator,
  styleContainer,
  padding
}: PropsType) => {
  return (
    <Container style={styleContainer} padding={padding}>
      <Separator
        style={styleSeparator}
        marginTop={marginTop}
        width={width}
        color={color}
      ></Separator>
    </Container>
  )
}

export default HorizontalSeparator
