import React from 'react'

import InputName from './InputName'
import Button from '../../../../components/Button'
import { GoogleIcon, MetaIcon, TiktokIcon } from '../../../../components/Icons'
import {
  availablePlatforms,
  CampaignPlatform,
  formatsByPlatform,
} from '../../../../types/formatAndPlatform'
import { CampaignFormatAndPlatform } from '../types'
import { Margin } from '../../../../constants'
import {
  getWordingFromCampaignFormat,
  getWordingFromCampaignPlatform,
} from '../helpers/wording'
import { formatOptionsToObject } from './helpers/formatOptionsToObject'
import { ButtonContainer, googleIconStyle, metaIconStyle, tiktokIconStyle } from './styles'
import ChoiceItems from '../../../../components/ChoiceItems'

export type CampaignCreationProps = {
  name: string
  campaignType: CampaignFormatAndPlatform
  handleChangeName: (value: string) => void
  handleChangePlatform: (value: string | null) => void
  handleChangeFormat: (value: string | null) => void
  handleCreateCampaign: (value: string) => void
  handleCancel: () => void
}

const CampaignCreation = ({
  name,
  campaignType,
  handleChangeName,
  handleChangePlatform,
  handleChangeFormat,
  handleCreateCampaign,
  handleCancel
}: CampaignCreationProps) => {
  const { platform, format } = campaignType
  const formats = platform ? formatsByPlatform[platform] : null

  const formatOptions =
    formats != null
      ? formats.map((format) => getWordingFromCampaignFormat(format))
      : null

  const platformOptions = availablePlatforms.map((pf) =>
    getWordingFromCampaignPlatform(pf)
  )

  const platformOptionsWithIcon = {
    Google: {
      icon: <GoogleIcon style={googleIconStyle} />,
      label: getWordingFromCampaignPlatform(CampaignPlatform.GOOGLE),
    },
    Meta: {
      icon: <MetaIcon style={metaIconStyle} />,
      label: getWordingFromCampaignPlatform(CampaignPlatform.META),
    },
    TikTok: {
      icon: <TiktokIcon style={tiktokIconStyle} />,
      label: getWordingFromCampaignPlatform(CampaignPlatform.TIKTOK),
    },
  }

  const formattedPlatformOptions = formatOptionsToObject({
    platformOptionsWithIcon,
    options: platformOptions,
  })

  const formattedFormatOptions = formatOptions
    ? formatOptionsToObject({ platformOptionsWithIcon, options: formatOptions })
    : []

  const selectedPlatform =
    platform != null ? getWordingFromCampaignPlatform(platform) : null
  const selectedFormat =
    format != null ? getWordingFromCampaignFormat(format) : null

  const isNameValid = name.length >= 3

  return (
    <div>
      <ChoiceItems
        title={'Choix de la plateforme'}
        options={formattedPlatformOptions}
        handleSelect={handleChangePlatform}
        selectedOptions={selectedPlatform ? [selectedPlatform] : []}
        optionsGroupName={'plateforme'}
        type='radio'
        error=''
      />
      {formats && (
        <ChoiceItems
          title={'Choix du format'}
          options={formattedFormatOptions}
          handleSelect={handleChangeFormat}
          selectedOptions={selectedFormat ? [selectedFormat] : []}
          optionsGroupName={'format'}
          type='radio'
          error=''
        />
      )}
      {platform && format && (
        <InputName name={name} handleChangeName={handleChangeName} />
      )}
      <ButtonContainer>
        {isNameValid && (
          <Button
            onClick={() => handleCreateCampaign(name)}
            small
            style={{ marginTop: Margin.m7 }}
          >
            Ajouter une campagne
          </Button>
        )}
        <Button
          onClick={handleCancel}
          small
          style={{ marginTop: Margin.m7 }}
          type='secondary'
        >
          Annuler
        </Button>
      </ButtonContainer>
    </div>
  )
}

export default CampaignCreation
