import styled from '@emotion/styled'

import { YETIC_FONT_FAMILY } from '../../../constants/fontFamily'

export const StyledSubheadline = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  font-family: ${YETIC_FONT_FAMILY};
  color: #9ca3af;
`
