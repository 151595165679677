import React from 'react'

import { LocationsReportingOutputType } from '../../../../../services/api/reporting/types'
import { EmptyTabWrapper } from '../style'

type LocationsTabProps = {
  reportingData: LocationsReportingOutputType
}

const LocationsTab = ({ reportingData }: LocationsTabProps) => {
  return <EmptyTabWrapper>À venir!</EmptyTabWrapper>
}

export default LocationsTab
