import React, { useState } from 'react'

import { generateCsv } from '../../helpers/generateCsv'
import { SortEnum } from '../../types/common'
import { DownloadIcon, SortIcon } from '../Icons'
import { formatTableValue } from './helpers/formatValue'
import { Container, DownloadContainer, downloadIcon, TableContainer, TableHeader, TableHeaderItem, sortIconStyle, TableRowsContainer, NoResult, TableRow, RowContent, RowItem, IconContainer, actionButtonSize, TitleWrapper } from './style'
import ActionsButton, { checkIfActionItemArray } from '../ActionsButton'
import Pagination from '../Pagination'
import { H3, P } from '../Text'
import { ColumnItem, ElementItem, SortStatus } from './types'
import { checkIfFrenchShortDate, compareFrenchShortDates } from '../../helpers/date'

type TableProps = {
  title?: string
  columns: ColumnItem[]
  elements: ElementItem[]
  sort?: SortStatus
  download?: boolean
  pagination?: {
    totalItems: number
    itemsByPage: number
    currentPage: number
    handleChangePage: (page: number) => void
  }
  maxHeight?: number | undefined
}

const Table = ({
  title,
  columns,
  elements,
  sort,
  pagination,
  download,
  maxHeight
}: TableProps) => {
  const [sortStatus, setSortStatus] = useState<SortStatus | null>(sort ?? null)

  let sortedElements = elements

  if (sortStatus) {
    sortedElements = sortedElements.sort((a, b) => {
      const valueA = a[sortStatus.name]
      const valueB = b[sortStatus.name]

      if (valueA === undefined || valueB === undefined) {
        return 0
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        if (checkIfFrenchShortDate(valueA) && checkIfFrenchShortDate(valueB)) {
          return sortStatus.order === SortEnum.ASC 
            ? compareFrenchShortDates(valueA, valueB)
            : compareFrenchShortDates(valueB, valueA)
        }

        return sortStatus.order === SortEnum.ASC
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA)
      }

      if (valueA < valueB) {
        return sortStatus.order === SortEnum.ASC ? -1 : 1
      }

      if (valueA > valueB) {
        return sortStatus.order === SortEnum.ASC ? 1 : -1
      }

      return 0
    })
  }

  const paginatedSortedData = pagination
    ? sortedElements.slice(
        (pagination.currentPage - 1) * pagination.itemsByPage,
        pagination.currentPage * pagination.itemsByPage
      )
    : sortedElements

  const defaultWidth = `${100 / columns.length}%`

  const handleSortData = (name: string) => {
    const order = sortStatus?.name === name ? sortStatus.order : undefined

    const updatedSortStatus = {
      name,
      order: order === SortEnum.ASC ? SortEnum.DESC : SortEnum.ASC
    }

    setSortStatus(updatedSortStatus)
  }

  return (
    <Container>
      {title && (
        <TitleWrapper>
          <H3>
            {title}
          </H3>
          {download && (
            <DownloadContainer
              onClick={() => {
                generateCsv(
                  elements,
                  columns.map((column) => column.name),
                  'table_data'
                )
              }}
            >
              <DownloadIcon style={downloadIcon} />
            </DownloadContainer>
          )}
        </TitleWrapper>
      )}
      <TableContainer>
        <TableHeader>
          {columns.map((column, index) => {
            return (
              <TableHeaderItem
                key={index}
                width={column.width ?? defaultWidth}
                align={column.align}
              >
                <P
                  bold
                  onClick={column.isSortable ? () => {
                    handleSortData(column.name)
                  }: undefined}
                >
                  {column.label}
                </P>
                {column.isSortable &&
                  (sortStatus?.name === column.name &&
                  sortStatus.order === SortEnum.ASC ? (
                    <SortIcon
                      style={sortIconStyle}
                      handleClick={() => {
                        handleSortData(column.name)
                      }}
                    />
                  ) : sortStatus?.name === column.name &&
                    sortStatus.order === SortEnum.DESC ? (
                    <SortIcon
                      style={{ ...sortIconStyle, transform: 'rotateX(180deg)' }}
                      handleClick={() => {
                        handleSortData(column.name)
                      }}
                    />
                  ) : null)}
              </TableHeaderItem>
            )
          })}
        </TableHeader>
        <TableRowsContainer maxHeight={maxHeight}>
          {paginatedSortedData.length === 0 ? (
            <NoResult>Aucun résultat</NoResult>
          ) : (
            paginatedSortedData.map((row, rowIndex) => {
              return (
                <TableRow index={rowIndex} key={rowIndex} isClickable={!!row.onClick}>
                  <RowContent>
                    {Object.keys(row).map((key, index) => {
                      const currentValue = row[key]
                      if (
                        key === 'id' ||
                        checkIfActionItemArray(currentValue) ||
                        typeof currentValue === 'function'
                      ) {
                        return null
                      }

                      return (
                        <RowItem
                          key={index}
                          bold={
                            columns.find((column) => column.name === key)
                              ?.bold ?? false
                          }
                          width={
                            columns.find((column) => column.name === key)
                              ?.width ?? defaultWidth
                          }
                          onClick={() => {
                            if (row.id && row.onClick) {
                              row.onClick(row.id)
                            }
                          }}
                          align={
                            columns.find((column) => column.name === key)?.align
                          }
                        >
                          {formatTableValue(
                            currentValue,
                            columns.find((column) => column.name === key)?.unit
                          )}
                        </RowItem>
                      )
                    })}
                    {row.actions && row.actions.length > 0 ? (
                      <IconContainer>
                        <ActionsButton
                          actions={row.actions}
                          size={actionButtonSize}
                        />
                      </IconContainer>
                    ) : null}
                  </RowContent>
                </TableRow>
              )
            })
          )}
        </TableRowsContainer>
      </TableContainer>
      {pagination && (
        <Pagination
          totalItems={pagination.totalItems}
          itemsPerPage={pagination.itemsByPage}
          onChangePage={pagination.handleChangePage}
        />
      )}
    </Container>
  )
}

export default Table
