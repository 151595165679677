import * as d3 from 'd3'

import { PieChartDataItem } from '../../types'
import { FontSize, FontWeight, Margin, Padding } from '../../../../../constants'

type DrawChartProps = {
  data: PieChartDataItem[]
  total: number
  width: number
  height: number
  innerRadius: number
  outerRadius: number
  unit?: string
  chartId: string
}

export const drawChart = ({
  data,
  total,
  width,
  height,
  innerRadius,
  outerRadius,
  unit,
  chartId
}: DrawChartProps) => {
  // Remove the old svg
  d3.select(`#${chartId}`).select('svg').remove()

  // Create a div for the tooltip
  const tooltip = d3
    .select(`#${chartId}`)
    .append('div')
    .style('position', 'absolute')
    .style('background', '#fff')
    .style('border', '1px solid #ccc')
    .style('border-radius', '5px')
    .style('padding', '5px')
    .style('pointer-events', 'none')
    .style('display', 'flex')
    .style('flex-direction', 'column')
    .style('padding', `${Padding.p6} ${Padding.p5}`)
    .style('box-shadow', 'rgba(20, 42, 76, 0.5) 0px 0px 5px')
    .style('gap', Margin.m2)
    .style('transition', "opacity 0.1s ease-in-out")
    .style('opacity', 0) // Start hidden

  // Create new svg
  const svg = d3
    .select(`#${chartId}`)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .style('overflow', 'visible') // Avoid clipping
    .append('g')
    .attr('transform', `translate(${width / 2}, ${height / 2})`)

  const arcGenerator = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)

  const pieGenerator = d3
    .pie()
    .padAngle(0)
    .value((d) => d.value)

  const arc = svg.selectAll().data(pieGenerator(data)).enter()

  // Append sectors
  arc
    .append('path')
    .attr('d', arcGenerator)
    .style('fill', (d) => d.data.color) // Use predefined color
    .style('stroke', '#ffffff')
    .style('stroke-width', 0)
    .on('mouseover', function (event, d) {
      tooltip
        .style('opacity', 1)
        .html(`
          <span>${d.data.label}</span>
          <div
            style="margin-top: ${Margin.m2}; display: flex; gap: 4px; align-items: end;"
          >
            <span style="font-weight: ${FontWeight.YETIC_BOLD}; font-size: ${FontSize.lg};">
              ${Math.round(d.data.value * 100) / 100}${unit ?? ''}
            </span>
            <span style="font-weight: ${FontWeight.YETIC_NORMAL}; font-size: ${FontSize.sm};">
             (${Math.round((d.data.value / total) * 10000) / 100}%)
            </span>
          </span>`
        )
    })
    .on('mousemove', function (event) {
      tooltip
        .style('left', `${event.pageX + 10}px`)
        .style('top', `${event.pageY + 10}px`)
    })
    .on('mouseout', function () {
      tooltip.style('opacity', 0)
    })

  // Append text labels outside the circle
  // arc
  //   .append('text')
  //   .attr('text-anchor', (d) => {
  //     const angle = (d.startAngle + d.endAngle) / 2
  //     return angle > Math.PI ? 'end' : 'start'
  //   })
  //   .attr('alignment-baseline', 'middle')
  //   .text((d) => d.data.label)
  //   .style('fill', '#000000')
  //   .style('font-size', '12px')
  //   .attr('transform', (d) => {
  //     const [x, y] = d3
  //       .arc()
  //       .innerRadius(outerRadius + 20)
  //       .outerRadius(outerRadius + 20)
  //       .centroid(d)
  //     return `translate(${x}, ${y})`
  //   })
}
