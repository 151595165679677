import React from 'react'

import {
  HeaderSelectionContainer,
  SelectionItem,
  SelectionItemName,
  SelectionItemTotalValue
} from './style'
import Colors from '../../../../constants/colors'
import { DatasetsSelection } from '../lineChartDrawer'
import { P } from '../../../Text'

type LineChartHeaderSelectionProps = {
  handleDatasetClick: (datasetIndex: number) => void
  datasets: DatasetsSelection
  width: string
  globalCTR?: number
}

const LineChartHeaderSelection = ({
  handleDatasetClick,
  datasets,
  width
}: LineChartHeaderSelectionProps) => {
  const getTextStyle = (isSelected: boolean) => {
    return isSelected ? { color: Colors.white } : undefined
  }

  return (
    <HeaderSelectionContainer width={width}>
      {datasets.map((dataset, index) => {
        return (
          <SelectionItem
            key={index}
            isSelected={dataset.isSelected}
            color={dataset.color}
            onClick={() => {
              handleDatasetClick(index)
            }}
          >
            <SelectionItemName>
              <P style={getTextStyle(dataset.isSelected)}>
                {dataset.name}
              </P>
            </SelectionItemName>
            <SelectionItemTotalValue>
              <P style={getTextStyle(dataset.isSelected)} big bold>
                {dataset.headerKpi !== undefined ? dataset.headerKpi : '-'}{' '}
                {dataset.unit && dataset.unit}
              </P>
            </SelectionItemTotalValue>
          </SelectionItem>
        )
      })}
    </HeaderSelectionContainer>
  )
}

export default LineChartHeaderSelection
