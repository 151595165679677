export type LogoutOutputSuccess = { success: boolean }

export type LogoutOutputError = {
  error: string
}

export type LogoutApiOutput =
  | LogoutOutputSuccess
  | LogoutOutputError

export const checkIfLogoutOutputSuccess = (
  val: any
): val is LogoutOutputSuccess => {
  return val && typeof val.success === 'boolean'
}

export const checkIfLogoutOutputError = (
  val: any
): val is LogoutOutputError => {
  return val && typeof val.error === 'string'
}

export const checkIfLogoutOutput = (
  val: any
): val is LogoutApiOutput => {
  return (
    checkIfLogoutOutputError(val) || checkIfLogoutOutputSuccess(val)
  )
}
