import React, { ReactNode } from 'react'

import { getBackgroundColorFromVariant, getFontColorFromVariant, StyledTag } from './style'
import { P } from '../Text'
import { FontSize } from '../../constants'

export type TagVariant = 'grey' | 'red'  | 'orange' | 'green' | 'blue'

type TagProps = {
  children: ReactNode
  variant?: TagVariant
  backgroundColor?: string
  color?: string
}

const Tag = ({ children, variant, backgroundColor, color }: TagProps) => {
  const fontColor = color ? color : variant ? getFontColorFromVariant(variant) : getFontColorFromVariant('grey')
  const tagBackgroundColor = backgroundColor ? backgroundColor : variant ? getBackgroundColorFromVariant(variant) : getBackgroundColorFromVariant('grey')

  return (
    <StyledTag backgroundColor={tagBackgroundColor}>
      <P style={{ color: fontColor, fontSize: FontSize.sm }}>{children}</P>
    </StyledTag>
  )
}

export default Tag