import React from 'react'

import { LoginFieldsEnum } from './types'
import {
  FormHeader,
  FormWrapper,
  LogoWrapper,
  StyledContainer,
  StyledWrapper
} from './style'
import YeticLogo from '../../components/YeticLogo'
import { H2, P } from '../../components/Text'
import LoginForm from './Form'
import YetiPanel from '../../components/YetiPanel'

type LoginViewProps = {
  email: string | null
  password: string | null
  companyName: string | null
  companies: string[] | null
  showPassword: boolean
  handleFieldChange: (e: React.ChangeEvent<HTMLInputElement>, field: LoginFieldsEnum) => void
  handleCompanyChange: (value: string | null) => void
  setShowPassword: (val: boolean) => void
  description?: string
  handleSubmit: (e: React.FormEvent) => void
  getFormError: (field: LoginFieldsEnum) => string
  apiError: string
  handleGoToRegister: () => void
  loading: boolean
}

const LoginView = ({
  email,
  password,
  companyName,
  companies,
  handleFieldChange,
  handleCompanyChange,
  setShowPassword,
  showPassword,
  getFormError,
  description,
  handleSubmit,
  apiError,
  handleGoToRegister,
  loading
}: LoginViewProps) => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <LogoWrapper>
          <YeticLogo width='120px' />
        </LogoWrapper>
        <FormWrapper>
          <FormHeader>
            <H2 style={{ marginBottom: 0 }}>Connexion</H2>
            {description && <P>{description}</P>}
          </FormHeader>
          <LoginForm
            email={email}
            password={password}
            companyName={companyName}
            companies={companies}
            handleFieldChange={handleFieldChange}
            handleCompanyChange={handleCompanyChange}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
            getFormError={getFormError}
            handleSubmit={handleSubmit}
            goToRegister={handleGoToRegister}
            apiError={apiError}
            loading={loading}
          />
        </FormWrapper>
      </StyledContainer>
      <YetiPanel width='50vw' />
    </StyledWrapper>
  )
}

export default LoginView
