export enum LogoutApiErrorCodes {
  UNKNOWN = 'UNKNOWN'
}

export const checkIfLogoutApiErrorCode = (val: any): val is LogoutApiErrorCodes => {
  return Object.values(LogoutApiErrorCodes).includes(val)
}

const postCompanyApiErrorMessages: Record<LogoutApiErrorCodes, string> = {
  'UNKNOWN': 'Une erreur inconnue est survenue'
}

export const getLogoutErrorMessageFromErrorCode = (errorCode: LogoutApiErrorCodes) => {
  return postCompanyApiErrorMessages[errorCode]
}
