import React, { useEffect, useState } from 'react'

import PageTemplate from '../../templates/Page'
import { Invoice } from './types'
import LoadingPage from '../LoadingPage'
import { getInvoicesApi } from '../../services/api/invoices/get'
import { checkIfErrorType } from '../../types/error'
import ErrorPage from '../ErrorPage'
import Table from '../../components/Table'
import { ElementItem } from '../../components/Table/types'
import { P } from '../../components/Text'
import Button from '../../components/Button'
import { downloadInvoiceApi } from '../../services/api/invoices/download'
import { openFileFromBase64 } from './helpers/openFileFromBase64'
import { Colors, Margin } from '../../constants'
import { invoicesTableColumns } from './constants/tableColumns'

const InvoicesPage = () => {
  const [invoicesData, setInvoicesData] = useState<{
    invoices: Invoice[] | null
    error: string
  }>({ invoices: null, error: '' })
  const [downloadFileData, setDownloadFileData] = useState<{
    invoiceId: string
    error: string
  }>({ invoiceId: '', error: '' })

  useEffect(() => {
    getInvoices()
  }, [])

  useEffect(() => {
    if (downloadFileData.invoiceId) {
      downloadInvoiceFileFromApi()
    }
  }, [downloadFileData])

  const getInvoices = async () => {
    const result = await getInvoicesApi()

    if (checkIfErrorType(result)) {
      setInvoicesData({ invoices: null, error: result.error })
    } else {
      setInvoicesData({ invoices: result, error: '' })
    }
  }

  const downloadInvoiceFileFromApi = async () => {
    const invoiceFileResult = await downloadInvoiceApi(
      downloadFileData.invoiceId
    )

    if (checkIfErrorType(invoiceFileResult)) {
      setDownloadFileData({ invoiceId: '', error: invoiceFileResult.error })
    } else {
      openFileFromBase64(invoiceFileResult)

      setDownloadFileData({ invoiceId: '', error: '' })
    }
  }

  if (invoicesData.error) {
    return <ErrorPage message={invoicesData.error} />
  }

  if (invoicesData.invoices === null) {
    return <LoadingPage />
  }

  const tableElements: ElementItem[] = invoicesData.invoices.map((invoice) => ({
    invoiceId: (
      <P
        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {invoice.invoiceId}
      </P>
    ),
    date: <P>{invoice.date}</P>,
    status: <P>{invoice.isPaid ? 'Payée' : 'Non payée'}</P>,
    price: <P>{invoice.price}€</P>,
    description: (
      <P
        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {invoice.description}
      </P>
    ),
    download: (
      <Button
        onClick={() =>
          setDownloadFileData({ invoiceId: invoice.id, error: '' })
        }
        small
        loading={downloadFileData.invoiceId === invoice.id}
      >
        Télécharger
      </Button>
    ),
  }))

  return (
    <PageTemplate title="Factures">
      {downloadFileData.error && (
        <P style={{ color: Colors.red, marginBottom: Margin.m4 }}>
          {downloadFileData.error}
        </P>
      )}
      <Table columns={invoicesTableColumns} elements={tableElements} />
    </PageTemplate>
  )
}

export default InvoicesPage
